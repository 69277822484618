import { DataHandle } from 'features/Flow/Handles/handle.store';
import { DataType } from '../../Node/DataType';
import { NodeInputFormData } from './ObjectBuilder.types';
import { DataSchema, isArrayDataSchema } from '@pathways/pipeline-schema/web';
import { getSchemaByDataType } from 'utils/dataSchema';

const isValidConnectableType = (type: DataType | null, isArray?: boolean) => {
  if (!type) return false;

  const validTypes = ['text', 'integer', 'number', 'object'];
  if (!isArray) {
    validTypes.push('boolean');
  }

  return validTypes.includes(type);
};

export const canBuilderCustomHandleConnect = ({ schema }: DataHandle) => {
  if (isArrayDataSchema(schema)) {
    const itemType = schema.items.type;
    return isValidConnectableType(itemType, true);
  }

  return isValidConnectableType(schema.type, false);
};

export const createBuilderDataSchema = (data: NodeInputFormData): DataSchema => {
  return getSchemaByDataType(data.dataType, data.arrayDataType);
};
