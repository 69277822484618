import { Close, InfoOutlined } from '@mui/icons-material';
import { Box, Collapse, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useMatchesViewExecutionRoute } from 'hooks/useMatchesViewExecutionRoute';
import { useExecutionContext } from 'pages/ExecutionsPage/contexts/execution/useExecutionContext';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ViewExecutionLogs } from './ViewExecutionLogs';

export function ExecutionLogPanel() {
  const matchesViewExecutionRoute = useMatchesViewExecutionRoute();
  const { pipelineExecutionId } = useParams();
  const context = useExecutionContext();

  useEffect(() => {
    if (!matchesViewExecutionRoute) {
      context.setOrToggleDisplayLogPanel(false);
    }
  }, [context, matchesViewExecutionRoute]);

  if (!matchesViewExecutionRoute) return null;
  if (!pipelineExecutionId) return null;

  return (
    <Collapse
      mountOnEnter
      unmountOnExit
      in={context.displayLogPanel}
      orientation="horizontal"
      sx={{
        flexShrink: 0,
      }}
    >
      <Box
        component="aside"
        sx={(theme) => ({
          width: '50vw',
          height: '100%',
          borderLeft: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <Paper
          square
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(1.5),
            paddingLeft: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.divider}`,
          })}
        >
          <Stack
            flexDirection="row"
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ width: '100%' }}
          >
            <Stack flexDirection="row" alignItems={'center'} gap={1}>
              <Typography variant="titleMedium">Run Log</Typography>
              <Tooltip title="Execution logs expire after 7 days">
                <InfoOutlined color="info" fontSize={'small'} />
              </Tooltip>
            </Stack>
            <IconButton
              onClick={() => {
                context.setOrToggleDisplayLogPanel();
              }}
            >
              <Close />
            </IconButton>
          </Stack>
        </Paper>

        <ViewExecutionLogs executionId={pipelineExecutionId} />
      </Box>
    </Collapse>
  );
}
