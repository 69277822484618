import { NavigateBefore } from '@mui/icons-material';
import { Box, Button, ButtonProps, Stack, css, styled } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

const BeforeIconBox = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    background-color: ${theme.palette.surface.level2};
    border-radius: ${theme.spacing(1)};
    padding: ${theme.spacing(0.5)};
    color: ${theme.palette.action.active};

    &:hover {
      color: initial;
    }
  `,
);

const PipelineVersionsLink = styled(Link)`
  padding: 0;
  background-color: transparent;
  min-width: auto;
`;

const BackButton = (props: LinkProps & ButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button
      data-testid="BackButton"
      LinkComponent={PipelineVersionsLink}
      href="" // used to render button as a link
      disableRipple
      color="white"
      {...rest}
    >
      <Stack
        direction="row"
        gap={2}
        sx={{
          alignItems: 'center',
        }}
      >
        <BeforeIconBox>
          <NavigateBefore />
        </BeforeIconBox>
        {children}
      </Stack>
    </Button>
  );
};

export default BackButton;
