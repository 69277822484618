import PlatformApiSdk from '@monksflow/platform-api-sdk';
import { PLUGIN_PROVIDERS } from '@monksflow/plugin-providers/web';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';
import { CredentialProviderData } from './useWorkspaceConnectedCredentials.types';

export default function useWorkspaceConnectedCredentials(workspaceId: string) {
  const response = useSWRImmutable(
    'workspace-connected-credentials',
    () => PlatformApiSdk.getWorkspaceCredentials(workspaceId),
    {
      errorRetryCount: 3,
      errorRetryInterval: 1000,
    },
  );
  const responseData = response.data?.data.data;

  const connectedCredentialProviders = useMemo(() => {
    const allConnectedProviders = responseData ?? [];

    return PLUGIN_PROVIDERS.map((provider) => {
      const foundItem = allConnectedProviders.find((c) => c.provider === provider.provider);

      return {
        id: foundItem?.id ?? '',
        name: provider.name,
        providerType: provider.provider,
        description: foundItem?.description ?? '',
        status: foundItem ? 'connected' : 'disconnected',
      } satisfies CredentialProviderData;
    });
  }, [responseData]);

  return {
    areConnectedCredentialsLoading: response.isLoading,
    connectedCredentialProviders: connectedCredentialProviders,
    connectedCredentialsError: !!response.error,
    mutateConnectedCredentials: response.mutate,
  };
}
