import { PipelineState } from '@pathways/pipeline-schema/web';
import usePipelineVersions from 'api/services/usePipelineVersions/usePipelineVersions';
import useToast from 'contexts/toast/useToast';
import { useEffect, useMemo } from 'react';

export function usePublishedPipelineVersions(pipelineId: string | undefined) {
  const toast = useToast();
  const { pipelineVersionsData, arePipelineVersionsLoading, pipelineVersionsError } =
    usePipelineVersions(pipelineId, {
      pageSize: 100,
    });

  useEffect(() => {
    if (pipelineVersionsError) {
      toast.error({
        message: 'Failed to load pipeline versions',
      });
    }
  }, [pipelineVersionsError, toast]);

  const publishedVersions = useMemo(() => {
    if (!pipelineVersionsData) return undefined;

    return pipelineVersionsData.items.filter(
      (version) => version.state === PipelineState.PUBLISHED,
    );
  }, [pipelineVersionsData]);

  return { arePipelineVersionsLoading, publishedVersions };
}
