import { SvgIcon, SvgIconProps } from '@mui/material';

const PipelineIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M15.1348 19.5V17.5673H11.25V12.75H8.85575V14.673H2.5V9.31726H8.85575V11.25H11.25V6.42301H15.1348V4.49051H21.5V9.85576H15.1348V7.92301H12.75V16.0673H15.1348V14.1443H21.5V19.5H15.1348ZM16.6345 18H20V15.6443H16.6345V18ZM4 13.173H7.35575V10.8173H4V13.173ZM16.6345 8.35576H20V5.99051H16.6345V8.35576Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};

export default PipelineIcon;
