import { Alert } from '@mui/material';
import useApproveCheckpoint from 'api/services/useApproveCheckpoint/useApproveCheckpoint';
import { Checkpoint } from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints.types';
import useRejectCheckpoint from 'api/services/useRejectCheckpoint/useRejectCheckpoint';
import useToast from 'contexts/toast/useToast';
import { useState } from 'react';
import { FieldErrors, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Styled from './ExecutionCheckpoint.styles';
import { isVisibleInput } from './ExecutionCheckpoint.utils';
import ExecutionCheckpointActionFooter from './ExecutionCheckpointActionFooter';
import ExecutionCheckpointFooterContent from './ExecutionCheckpointFooterContent';
import ExecutionCheckpointFormMap from './ExecutionCheckpointFormMap';
import { ExecutionCheckpointRejectFormValues } from './modals/ExecutionCheckpointRejectModal/ExecutionCheckpointRejectModal.types';

export const EXECUTION_CHECKPOINT_FORM_ID = 'execution-checkpoint-form';

type FormValues = Checkpoint['data']['inputs'];

interface ExecutionCheckpointFormProps {
  checkpoint: Checkpoint;
  completedCheckpoints: number;
  totalCheckpoints: number;
}

const ExecutionCheckpointForm = ({
  checkpoint,
  completedCheckpoints,
  totalCheckpoints,
}: ExecutionCheckpointFormProps) => {
  const [isWaitingExecutionRefresh, setIsWaitingExecutionRefresh] = useState(false);
  const toast = useToast();
  const { approveCheckpoint } = useApproveCheckpoint(checkpoint.id);
  const { rejectCheckpoint, isRejectCheckpointPending } = useRejectCheckpoint(checkpoint.id);

  const onReject: SubmitHandler<ExecutionCheckpointRejectFormValues> = async (data) => {
    const payload = {
      message: `Checkpoint rejected by user with comment: ${data.comment}`,
      comment: data.comment,
    };

    try {
      await rejectCheckpoint(payload);
      setIsWaitingExecutionRefresh(true);
    } catch {
      toast.error({
        message: `An error occurred while rejecting the checkpoint.`,
      });
    }
  };

  const formMethods = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const payload = {
      inputs: data,
    };

    try {
      await approveCheckpoint(payload);
      setIsWaitingExecutionRefresh(true);
    } catch {
      toast.error({
        message: `An error occurred while approving the checkpoint.`,
      });
    }
  };

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;
  const submitForm = handleSubmit(onSubmit);

  const checkpointHeaderText = `Checkpoint No. ${completedCheckpoints + 1} of ${totalCheckpoints}`;

  return (
    <FormProvider {...formMethods}>
      {checkpoint.inputs.filter(isVisibleInput).map((input) => (
        <Styled.ExecutionCheckpointForm
          key={input.id ?? input.name}
          noValidate
          id={EXECUTION_CHECKPOINT_FORM_ID}
          onSubmit={submitForm}
        >
          <Styled.Main>
            <ExecutionCheckpointFormMap
              input={input}
              checkpoint={checkpoint}
              checkpointHeaderText={checkpointHeaderText}
              isWaitingExecutionRefresh={isWaitingExecutionRefresh}
            />
          </Styled.Main>

          <RenderCheckpointWarnings errors={errors} />

          <ExecutionCheckpointActionFooter
            isWaitingExecutionRefresh={isWaitingExecutionRefresh}
            isRejectCheckpointPending={isRejectCheckpointPending}
            onSubmit={submitForm}
            onReject={onReject}
          >
            <ExecutionCheckpointFooterContent input={input} />
          </ExecutionCheckpointActionFooter>
        </Styled.ExecutionCheckpointForm>
      ))}
    </FormProvider>
  );
};

export default ExecutionCheckpointForm;

function RenderCheckpointWarnings({ errors }: { errors: FieldErrors<FormValues> }) {
  return (
    <>
      {errors.textCheckpointInputs && (
        <Alert severity="warning">{errors.textCheckpointInputs.message}</Alert>
      )}
      {errors.imageCheckpointInputs && (
        <Alert severity="warning">{errors.imageCheckpointInputs.message}</Alert>
      )}
    </>
  );
}
