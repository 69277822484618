export const VERSION_SEPARATOR = `@v`;
export const createVersionedKey = (identifiers) => {
    return `${identifiers.key}${VERSION_SEPARATOR}${identifiers.major}.${identifiers.minor ?? 0}.${identifiers.patch ?? 0}`;
};
export const parseVersionedKey = (jobKey, version = 'patch') => {
    try {
        const { key, versionString } = splitKeyAndVersion(jobKey);
        const versionIds = parseVersion(versionString);
        return {
            key,
            major: versionIds.major,
            minor: version === 'major' ? undefined : versionIds.minor,
            patch: version === 'patch' ? versionIds.patch : undefined,
        };
    }
    catch {
        throw new Error(`Expected to find a string {key}@v{versionString} but received: ${jobKey} instead.`);
    }
};
/**
 * Transforms a versioned key with its major version only or as-is if there is no version.
 * @example
 * 'job-key@v1.2.3' -> 'job-key@v1'
 * 'job-key' -> 'job-key'
 */
export function resolveVersionedKeyWithMajorVersion(jobKey) {
    if (!jobKey.includes(VERSION_SEPARATOR)) {
        return jobKey;
    }
    const identifiers = parseVersionedKey(jobKey);
    return `${identifiers.key}${VERSION_SEPARATOR}${identifiers.major}`;
}
const splitKeyAndVersion = (jobKey) => {
    const result = jobKey.split('@v');
    if (result.length !== 2)
        throw new Error(`Expected two elements but found ${result.length}`);
    const [key, versionString] = result;
    if (!key)
        throw new Error(`Empty key found for ${jobKey}`);
    if (!versionString)
        throw new Error(`Empty version found for ${jobKey}`);
    return { key, versionString };
};
const parseVersion = (versionString) => {
    const [major, minor, patch] = versionString.split('.').map(safeParseNumber);
    return {
        major,
        minor,
        patch,
    };
};
const safeParseNumber = (s) => {
    if (s === '')
        throw new Error('Empty string is not a number string');
    const num = Number(s);
    if (Number.isNaN(num))
        throw new Error(`${s} is not a number string`);
    return num;
};
