import { Box, SxProps, Theme } from '@mui/material';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import WorkspaceScopedIndicator from 'features/Flow/components/WorkspaceScopedIndicator/WorkspaceScopedIndicator';
import useIsNodeWorkspaceScoped from 'features/Flow/hooks/useIsNodeWorkspaceScoped';
import { FlowExecutionJob } from 'types/reactflow';
import { getJobTitle } from 'utils/neurons';
import * as Styled from '../Node.styles';
import { PathwayNodeMetadata } from '../Node.types';
import useGetConnectedProvider from 'features/Flow/hooks/useGetConnectedProvider';
import DisconnectedProviderIndicator from 'features/Flow/components/DisconnectedProviderIndicator/DisconnectedProviderIndicator';
import { useGetNodeExecutionErrors } from '../../../hooks/useGetNodeExecutionErrors';
import { useNodeId } from 'reactflow';
import { getNodeErrorMessage } from '../../../../../utils/message';

const headerIconErrorStyles: SxProps<Theme> = (theme) => ({
  bgcolor: theme.palette.common.white,
  borderRadius: '100%',
});

interface NodeHeaderProps {
  metadata: PathwayNodeMetadata;
  error?: string;
  execution?: FlowExecutionJob;
  icon?: React.ReactNode;
}

export const NodeHeader: React.FC<NodeHeaderProps> = ({ error, execution, icon, metadata }) => {
  const nodeId = useNodeId() as unknown as string;
  const { errors } = useGetNodeExecutionErrors({ id: nodeId });
  const status = error ?? errors ? ExecutionStatus.FAILED : execution?.status;
  const isNodeWorkspaceScoped = useIsNodeWorkspaceScoped(metadata.uses);
  const connectedProvider = useGetConnectedProvider(metadata.provider?.type);
  const hasDisconnectedProvider = connectedProvider?.status === 'disconnected';
  const tooltip = getNodeErrorMessage(error, errors);

  return (
    <Styled.NodeHeader $nodeType={metadata.type}>
      <TextEllipsis variant="labelLarge">{getJobTitle(execution ?? metadata)}</TextEllipsis>

      {icon ?? (
        <Box
          sx={{
            lineHeight: 0,
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
          }}
        >
          {isNodeWorkspaceScoped && <WorkspaceScopedIndicator />}
          {hasDisconnectedProvider && <DisconnectedProviderIndicator />}
          <StatusIcon
            status={status}
            sx={status === ExecutionStatus.FAILED ? headerIconErrorStyles : undefined}
            tooltipTitle={tooltip}
          />
        </Box>
      )}
    </Styled.NodeHeader>
  );
};
