import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Grid2, IconButton, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Edit as EditIcon } from '@mui/icons-material';
import DataSchemaIcon from 'components/DataSchemaIcon/DataSchemaIcon';
import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import DragIndicatorHandle from 'components/DragIndicatorHandle/DragIndicatorHandle';

interface SortableNodeDataItemProps {
  item: NodeInput | NodeOutput;
  index: number;
  isDragging: boolean;
  onEditItem: (item: NodeInput | NodeOutput, position: number) => void;
  onRemoveItem: (index: number) => void;
}

const SortableNodeDataItem = ({
  index,
  item,
  isDragging,
  onEditItem,
  onRemoveItem,
}: SortableNodeDataItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.name,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Grid2
        container
        sx={{
          alignItems: 'center',
        }}
      >
        <Grid2
          size={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DragIndicatorHandle {...listeners} $isDragging={isDragging} />
        </Grid2>
        <Grid2 size={1}>
          <DataSchemaIcon dataSchema={item.dataSchema} size={'24px'} />
        </Grid2>
        <Grid2 size={8}>
          <Typography>{item.title || item.name}</Typography>
        </Grid2>
        <Grid2 size={1}>
          <IconButton
            aria-label={`Edit ${item.title}`}
            onClick={() => {
              onEditItem(item, index);
            }}
          >
            <EditIcon />
          </IconButton>
        </Grid2>
        <Grid2 size={1}>
          <IconButton
            aria-label={`Remove ${item.title}`}
            onClick={() => {
              onRemoveItem(index);
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid2>
      </Grid2>
    </div>
  );
};

export default SortableNodeDataItem;
