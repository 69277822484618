import { NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { DataType } from '../../Node/DataType';
import { ArrayDataSchema } from '@pathways/pipeline-schema';
import { DataMenuItem } from 'utils/dataSchema';

export interface NodeOutputFormData {
  name: string;
  dataType: DataType;
  arrayDataType?: ArrayDataSchema['items']['type'];
  title?: string;
}

export type OutputConfigCommitFn = (updated: NodeOutput[], handleIdsToRemove: string[]) => void;

export const DEFAULT_VIEW = 'list';

export type ViewStates =
  | {
      view: 'list';
    }
  | {
      view: 'edit';
      outputToEdit: NodeOutput;
      updateIndex: number;
    }
  | {
      view: 'create';
    };

export const PIPELINE_START_DATA_TYPE_ITEMS: DataMenuItem[] = [
  { schema: { type: 'integer' }, title: 'Integer' },
  { schema: { type: 'number' }, title: 'Number' },
  { schema: { type: 'text' }, title: 'Text' },
  { schema: { type: 'boolean' }, title: 'Boolean' },
  { schema: { type: 'object' }, title: 'Object' },
  { schema: { type: 'asset', mediaType: [] }, title: 'Asset' },
  { schema: { type: 'enum', values: [] }, title: 'Enum' },
  {
    schema: {
      type: 'array',
      items: {
        type: null,
      },
    },
    title: 'Array',
  },
];

export const PIPELINE_START_ARRAY_TYPE_ITEMS: DataMenuItem[] = [
  {
    schema: {
      type: 'array',
      items: { type: 'integer' },
    },
    title: 'Integer',
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'number',
      },
    },
    title: 'Number',
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'text',
      },
    },
    title: 'Text',
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'object',
      },
    },
    title: 'Object',
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'image-uri',
      },
    },
    title: 'Image URI',
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'asset',
        mediaType: [],
      },
    },
    title: 'Asset',
  },
];
