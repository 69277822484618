import { NodeManifest, isSubpipelineManifest } from 'features/Flow/Flow.types';
import * as Styled from './SidebarElementCard.styles';
import { Box, CardActionArea, CardContent, Typography } from '@mui/material';
import SidebarElementCardTooltip from 'features/Flow/components/AddElementSidebar/components/SidebarElementCard/SidebarElementCardTooltip';
import SidebarElementCardTaglist from 'features/Flow/components/AddElementSidebar/components/SidebarElementCard/SidebarElementCardTaglist';
import { isJobNode } from 'utils/neurons';
import { removeDragHoverClassnames } from 'features/Flow/hooks/useGroupDragEvents';
import { getValueOrDefault } from 'utils/string';
import WorkspaceScopedIndicator from 'features/Flow/components/WorkspaceScopedIndicator/WorkspaceScopedIndicator';

interface SidebarElementCardProps {
  manifest: NodeManifest;
  onDragStart: (e: React.DragEvent) => void;
}

const SidebarElementCard = ({ manifest, onDragStart }: SidebarElementCardProps) => {
  const isSubpipeline = isSubpipelineManifest(manifest);
  const key = `${manifest.type}:${isSubpipeline ? manifest.pipeline.id : manifest.key}`;
  const title = manifest.name;

  if (!isJobNode(manifest)) return null;

  return (
    <Styled.ElementCard
      data-element-card-type={manifest.type}
      data-testid={key}
      data-title={title}
      key={key}
      variant="outlined"
      onDragStart={onDragStart}
      onDragEnd={() => {
        removeDragHoverClassnames();
      }}
      draggable
    >
      <CardActionArea disableRipple>
        <CardContent
          style={{
            padding: 0,
          }}
        >
          <Styled.ElementHeader
            $jobType={manifest.type}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="labelLarge">{title}</Typography>
            <Box
              sx={{
                flexShrink: 0,
              }}
            >
              {manifest.scope?.workspaceId && <WorkspaceScopedIndicator component="span" />}
              <SidebarElementCardTooltip manifest={manifest} />
            </Box>
          </Styled.ElementHeader>
          <Styled.ElementContent>
            <Typography variant="bodySmall">
              {getValueOrDefault(manifest.description.trim(), 'No description')}
            </Typography>
            <SidebarElementCardTaglist tags={manifest.tags} />
          </Styled.ElementContent>
        </CardContent>
      </CardActionArea>
    </Styled.ElementCard>
  );
};

export default SidebarElementCard;
