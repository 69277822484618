import { FormControl, InputLabel, useTheme } from '@mui/material';
import CopyButton from 'components/CopyButton/CopyButton';
import RoundedPaper from 'components/RoundedPaper/RoundedPaper';
import { useId } from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import javascript from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism';

SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('javascript', javascript);

interface CodeSnippetProps {
  code: string;
  language: 'bash' | 'javascript';
  label?: string;
}

export default function CodeSnippet(props: CodeSnippetProps) {
  const { label = 'Code Snippet', language, code } = props;
  const theme = useTheme();
  const labelId = `${useId()}-label`;

  return (
    <FormControl data-testid="CodeSnippet" margin="dense">
      <InputLabel
        id={labelId}
        sx={(theme) => ({
          left: theme.spacing(-1.5),
        })}
      >
        {label}
      </InputLabel>

      <RoundedPaper
        elevation={0} // determines background color
        sx={{
          display: 'flex',
          marginTop: 1.5,
          alignItems: 'flex-start',
        }}
      >
        <SyntaxHighlighter
          aria-labelledby={labelId}
          data-code={code}
          showLineNumbers
          language={language}
          style={materialOceanic}
          customStyle={{
            ...theme.typography.bodyMedium,
            margin: 0,
            background: 'none transparent',
            flexGrow: 1,
          }}
          codeTagProps={{}} // applies background color to the code tag
        >
          {code}
        </SyntaxHighlighter>

        <CopyButton
          value={code}
          sx={{
            marginTop: '7.5px',
            marginLeft: '6px',
            marginRight: '3px',
          }}
        />
      </RoundedPaper>
    </FormControl>
  );
}
