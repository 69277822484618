import { Domain as DomainIcon } from '@mui/icons-material';
import { IconButton, IconButtonProps, Tooltip } from '@mui/material';

export default function WorkspaceScopedIndicator(props: IconButtonProps) {
  return (
    <Tooltip title="Workspace Scoped">
      <IconButton size="small" {...props}>
        <DomainIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}
