import { Typography } from '@mui/material';
import CopyButton from 'components/CopyButton/CopyButton';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import { useJsonEditorState } from 'components/JsonEditor/useJsonEditorState';
import { objectValidation } from 'components/ui/rhf-components/utils/validationRules';
import { NodeType } from 'features/Flow/Flow.types';
import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ExecutionCheckpointFormProps } from '../../../ExecutionCheckpoint/ExecutionCheckpointForm.types';
import JobInfoTitle from '../../../JobInfoTitle/JobInfoTitle';
import * as Styled from '../ExecutionCheckpointForm.styles';

const ExecutionCheckpointObjectForm = ({
  disabled,
  input,
  pendingCheckpoint,
  pendingCheckpointHeader,
}: ExecutionCheckpointFormProps) => {
  const { metadata } = pendingCheckpoint;
  const initialValue = pendingCheckpoint.data.inputs[input.name];

  const { content, error, value, onChange: onJsonChange } = useJsonEditorState(initialValue);
  const { setError } = useFormContext();
  const { field } = useController({
    name: input.name,
    rules: {
      required: true,
      ...objectValidation,
    },
  });
  const { onChange: controllerOnChange } = field;

  useEffect(() => {
    controllerOnChange(value);
  }, [value, controllerOnChange]);

  useEffect(() => {
    setError(field.name, error ? { message: 'Invalid JSON object' } : {});
  }, [field.name, error, setError]);

  return (
    <Styled.FormContainer>
      <Styled.CheckpointApprovalHeader>
        <JobInfoTitle
          displaySecondaryName
          type={NodeType.CHECKPOINT}
          name={metadata.name}
          customTitle={metadata.customTitle}
        />

        <Styled.CheckpointApprovalRightBox>
          <Styled.CheckpointApprovalHeaderChip>
            {pendingCheckpointHeader}
          </Styled.CheckpointApprovalHeaderChip>
        </Styled.CheckpointApprovalRightBox>
      </Styled.CheckpointApprovalHeader>

      <Styled.CheckpointApprovalSubheader>
        <Typography variant="labelSmall">JSON Data</Typography>

        <Styled.CheckpointApprovalRightBox>
          <CopyButton sx={{ marginRight: 1 }} value={JSON.stringify(field.value, null, 2)} />
        </Styled.CheckpointApprovalRightBox>
      </Styled.CheckpointApprovalSubheader>

      <Styled.EditorContainer>
        <JsonEditor content={content} readOnly={disabled} onChange={onJsonChange} type="object" />
      </Styled.EditorContainer>
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointObjectForm;
