import { Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { NodeType } from 'features/Flow/Flow.types';
import { ChangeEvent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import JobInfoTitle from '../../../JobInfoTitle/JobInfoTitle';
import { ExecutionCheckpointErrorMessages } from '../../ExecutionCheckpoint.type';
import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';
import * as Styled from '../ExecutionCheckpointForm.styles';
import { ExecutionCheckpointTextFormData } from './ExecutionCheckpointTextForm.types';

const arrayToObject = (arr?: string[]) =>
  arr?.reduce((obj, item, index) => ({ ...obj, [index]: item }), {}) ?? {};

const areInitialValuesArray = (initialValues: unknown): initialValues is string[] => {
  return Array.isArray(initialValues);
};

const ExecutionCheckpointTextForm = ({
  disabled = false,
  input,
  pendingCheckpoint,
  pendingCheckpointHeader,
}: ExecutionCheckpointFormProps) => {
  const { metadata, data } = pendingCheckpoint;
  const initialValues = data.inputs[input.name];

  if (!areInitialValuesArray(initialValues)) {
    throw new Error('Initial values must be an array');
  }

  const { control, register, setValue, watch } = useFormContext<ExecutionCheckpointTextFormData>();
  const { name } = register(input.name, {
    value: [],
    validate: (values) => values.length > 0 || ExecutionCheckpointErrorMessages.NO_INPUT_SELECTED,
  });
  const formValues = watch(name, []);
  const allSelected = formValues.length === initialValues.length;

  const [reviewText, setReviewText] = useState<Record<string, string>>(
    arrayToObject(initialValues),
  );
  const [checkedText, setCheckedText] = useState<Record<string, boolean | undefined>>({});

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const updatedValues = checked ? Object.values(reviewText) : [];
    setValue(name, updatedValues, { shouldValidate: true });

    // Mark everything as checked
    const allChecked = updatedValues.reduce((obj, _, index) => ({ ...obj, [index]: checked }), {});
    setCheckedText(allChecked);
  };

  return (
    <Styled.FormContainer>
      <Styled.CheckpointApprovalHeader>
        <JobInfoTitle
          displaySecondaryName
          type={NodeType.CHECKPOINT}
          name={metadata.name}
          customTitle={metadata.customTitle}
        />

        <Styled.CheckpointApprovalRightBox>
          <Styled.CheckpointApprovalHeaderChip>
            {pendingCheckpointHeader}
          </Styled.CheckpointApprovalHeaderChip>
        </Styled.CheckpointApprovalRightBox>
      </Styled.CheckpointApprovalHeader>

      <Styled.CheckpointApprovalSubheader>
        <Typography variant="labelSmall">{input.title}</Typography>

        <Styled.CheckpointApprovalRightBox>
          <FormControlLabel
            disabled={disabled}
            label="Select All"
            labelPlacement="start"
            control={
              <Checkbox
                checked={allSelected}
                indeterminate={formValues.length > 0 && !allSelected}
                onChange={handleSelectAllChange}
              />
            }
          />
        </Styled.CheckpointApprovalRightBox>
      </Styled.CheckpointApprovalSubheader>

      <FormGroup>
        <Controller
          control={control}
          disabled={disabled}
          name={name}
          render={({ field }) => (
            <>
              {initialValues.map((text, index) => (
                <TextField
                  key={text}
                  disabled={field.disabled}
                  multiline
                  value={reviewText[index]}
                  sx={{ my: 0.5 }}
                  onChange={(event) => {
                    const newReviewText: Record<string, string> = {
                      ...reviewText,
                      [index]: event.target.value,
                    };
                    setReviewText(newReviewText);
                    const checkedValues = Object.keys(checkedText).map((key) => newReviewText[key]);
                    field.onChange(checkedValues);
                  }}
                  slotProps={{
                    input: {
                      disableUnderline: true,
                      endAdornment: (
                        <Checkbox
                          checked={checkedText[index] ?? false}
                          disabled={field.disabled}
                          edge="end"
                          sx={{ marginRight: -0.5 }}
                          onChange={(event, checked) => {
                            let checkedTextChanges;
                            if (checked) {
                              checkedTextChanges = { ...checkedText, [event.target.value]: true };
                            } else {
                              const { [event.target.value]: _removed, ...rest } = checkedText;
                              checkedTextChanges = { ...rest };
                            }

                            setCheckedText({ ...checkedTextChanges });
                            const checkedValues = Object.keys(checkedTextChanges).map(
                              (key) => reviewText[key],
                            );

                            field.onChange(checkedValues);
                          }}
                          value={index}
                        />
                      ),
                    },
                  }}
                />
              ))}
            </>
          )}
        />
      </FormGroup>
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointTextForm;
