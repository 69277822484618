import { ConnectionHandler } from 'features/Flow/EditorContext';
import { isHandleConnection } from 'features/Flow/Flow.types';
import { useSelectNodeOnClick } from 'features/Flow/Flow.utils';
import { CustomHandleData, HandleKey } from 'features/Flow/Handles/handle.store';
import { useGetHandle } from 'features/Flow/Handles/handles';
import CustomHandle from 'features/Flow/components/NeuronHandle/CustomHandle';
import { useDeferredConnectionState } from 'features/Flow/hooks/useDeferredConnectionState';
import { useEditorContext } from 'features/Flow/hooks/useEditorContext';
import useFlow from 'features/Flow/hooks/useFlow';
import { useGetNodeExecution } from 'features/Flow/hooks/useGetNodeExecution';
import { useNodeError } from 'features/Flow/hooks/useNodeError';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import * as Styled from 'features/Flow/nodes/Node/Node.styles';
import { useCallback, useEffect, useMemo } from 'react';
import { NodeProps, getConnectedEdges, useStore, useUpdateNodeInternals } from 'reactflow';
import { FlowState } from 'types/reactflow';
import { shallow } from 'zustand/shallow';
import { NodeInput, NodeOutput } from '../../Node/Node.types';
import NodeInputHandle from '../../Node/NodeInputHandle';
import NodeOutputHandle from '../../Node/NodeOutputHandle';
import { NodeHeader } from '../../Node/components/NodeHeader';
import { DynamicCheckpointData, isDynamicCheckpointNode } from './DynamicCheckpoint.types';
import { useDynamicCheckpoint } from './useDynamicCheckpoint';

const selector = ({ edges }: FlowState) => ({
  edges,
});

export interface CustomAddHandles {
  input: CustomHandleData & Pick<NodeInput, 'title' | 'name'>;
  output: CustomHandleData & Pick<NodeOutput, 'title' | 'name'>;
}

export interface DataHandles {
  input: NodeInput;
  output: NodeOutput;
}

export type DynamicCheckpointProps = NodeProps<DynamicCheckpointData>;

export default function DynamicCheckpoint(props: DynamicCheckpointProps) {
  const { id: nodeId, selected, data } = props;
  const { getNodeOrThrow } = useFlow();
  const node = useMemo(
    () => getNodeOrThrow(nodeId, isDynamicCheckpointNode),
    [getNodeOrThrow, nodeId],
  );
  const { nodeError } = useNodeError(nodeId);

  const updateNodeInternals = useUpdateNodeInternals();
  const selectNodeOnClick = useSelectNodeOnClick(nodeId);
  const { addConnectionHandler, removeConnectionHandler } = useEditorContext();
  const { setDeferredConnectionState } = useDeferredConnectionState({
    inputs: data.inputs,
    outputs: data.outputs,
  });
  const getHandle = useGetHandle();
  const updateNodeData = useUpdateNodeData();
  const { execution } = useGetNodeExecution(props);

  const { edges } = useStore(selector, shallow);
  const nodeConnectedEdges = getConnectedEdges([node], edges);
  const isNodeConnected = !!nodeConnectedEdges.length;

  const { customAddHandles, dataHandles, displayAddHandles, displayDataHandles } =
    useDynamicCheckpoint({
      ...props,
      isNodeConnected,
    });

  const handleAddConnection = useCallback<ConnectionHandler>(
    (connection, actionCallback) => {
      // Delegate to the next connection handler like PipelineComplete.
      if (!isHandleConnection(connection) || isNodeConnected) return null;

      const sourceHandle = getHandle({
        id: connection.sourceHandle,
        // Ignore configured handle.
        nodeId: connection.source === nodeId ? '' : connection.source,
      });
      const targetHandle = getHandle({
        id: connection.targetHandle,
        // Ignore configured handle.
        nodeId: connection.target === nodeId ? '' : connection.target,
      });
      const handle = sourceHandle ?? targetHandle;

      if (!handle) throw new Error('Handle not found');
      // Delegate to the next connection handler.
      if (handle.type !== 'data') return null;

      const inputName = customAddHandles.input.name;
      const outputName = customAddHandles.output.name;

      updateNodeData<typeof data>(nodeId, (data) => ({
        inputs: [
          {
            config: {
              ...handle.config,
              required: true,
            },
            dataSchema: handle.schema,
            name: inputName,
            title: customAddHandles.input.title,
            value: data.inputs.at(0)?.value,
          },
        ],
        outputs: [
          {
            config: handle.config,
            dataSchema: handle.schema,
            name: outputName,
            title: customAddHandles.output.title,
          },
        ],
      }));

      setDeferredConnectionState({
        action: actionCallback,
        inputName,
        outputName,
      });

      return 'defer';
    },
    [
      customAddHandles,
      getHandle,
      isNodeConnected,
      nodeId,
      setDeferredConnectionState,
      updateNodeData,
    ],
  );

  useEffect(() => {
    const inputHandlerKey: HandleKey = {
      id: customAddHandles.input.id,
      nodeId,
    };
    const outputHandlerKey: HandleKey = {
      id: customAddHandles.output.id,
      nodeId,
    };

    addConnectionHandler?.(inputHandlerKey, handleAddConnection);
    addConnectionHandler?.(outputHandlerKey, handleAddConnection);

    return () => {
      removeConnectionHandler?.(inputHandlerKey);
      removeConnectionHandler?.(outputHandlerKey);
    };
  }, [
    addConnectionHandler,
    customAddHandles,
    handleAddConnection,
    nodeId,
    removeConnectionHandler,
  ]);

  useEffect(() => {
    updateNodeInternals(nodeId);

    if (dataHandles) return;

    updateNodeData<typeof data>(nodeId, (prev) => {
      return {
        ...prev,
        inputs: [],
        outputs: [],
      };
    });
  }, [dataHandles, nodeId, updateNodeData, updateNodeInternals]);

  return (
    <Styled.NodeContainer
      $error={nodeError}
      $isSelected={selected}
      $nodeType={data.metadata.type}
      $status={execution?.status}
      onClick={selectNodeOnClick}
    >
      <NodeHeader error={nodeError} execution={execution} metadata={data.metadata} />

      <Styled.NodeDataRow className="nodrag">
        <Styled.NodeDataColumn>
          {displayAddHandles && (
            <CustomHandle handle={customAddHandles.input} title={customAddHandles.input.title} />
          )}
          {displayDataHandles && dataHandles && (
            <NodeInputHandle nodeId={nodeId} input={dataHandles.input} />
          )}
        </Styled.NodeDataColumn>

        <Styled.NodeDataColumn>
          {displayAddHandles && (
            <CustomHandle handle={customAddHandles.output} title={customAddHandles.output.title} />
          )}
          {displayDataHandles && dataHandles && (
            <NodeOutputHandle nodeId={nodeId} output={dataHandles.output} />
          )}
        </Styled.NodeDataColumn>
      </Styled.NodeDataRow>
    </Styled.NodeContainer>
  );
}
