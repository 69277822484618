import { Box, Stack, Tooltip, Typography } from '@mui/material';
import FlowChartIcon from 'components/Icons/FlowChartIcon';
import TriggerIcon from 'components/Icons/TriggerIcon';

interface IndicatorCellProps {
  subpipeline: boolean;
  triggers: number;
}

const EMPTY = <Box sx={{ height: 24, width: 24 }} />;

export const IndicatorCell = ({ subpipeline, triggers }: IndicatorCellProps) => {
  return (
    <Stack direction="row" gap={4}>
      {triggers ? (
        <Tooltip title={`${triggers} API Triggers`}>
          <Box
            sx={{
              display: 'inline-flex',
              gap: 0.5,
              alignItems: 'center',
            }}
            data-testid="externalTriggerIndicator"
          >
            <TriggerIcon />
            <Typography variant="caption">{triggers}</Typography>
          </Box>
        </Tooltip>
      ) : (
        EMPTY
      )}
      {subpipeline ? (
        <Tooltip title="Subpipeline Capable">
          <Box data-testid="subpipelineIndicator">
            <FlowChartIcon />
          </Box>
        </Tooltip>
      ) : (
        EMPTY
      )}
    </Stack>
  );
};
