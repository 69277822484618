import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { NodeInput } from '../../../Node/Node.types';
import { Grid2, IconButton, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Edit as EditIcon } from '@mui/icons-material';
import DataSchemaIcon from 'components/DataSchemaIcon/DataSchemaIcon';
import DragIndicatorHandle from 'components/DragIndicatorHandle/DragIndicatorHandle';

interface SortableNodeInputProps {
  input: NodeInput;
  index: number;
  isDragging: boolean;
  onEditInput: (input: NodeInput, position: number) => void;
  onRemoveInput: (index: number) => void;
}

const SortableNodeInput = ({
  index,
  input,
  isDragging,
  onEditInput,
  onRemoveInput,
}: SortableNodeInputProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: input.name,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Grid2
        container
        sx={{
          alignItems: 'center',
        }}
      >
        <Grid2
          size={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DragIndicatorHandle {...listeners} $isDragging={isDragging} />
        </Grid2>
        <Grid2 size={1}>
          <DataSchemaIcon dataSchema={input.dataSchema} size={'24px'} />
        </Grid2>
        <Grid2 size={8}>
          <Typography>{input.title || input.name}</Typography>
        </Grid2>
        <Grid2 size={1}>
          <IconButton
            onClick={() => {
              onEditInput(input, index);
            }}
          >
            <EditIcon />
          </IconButton>
        </Grid2>
        <Grid2 size={1}>
          <IconButton
            onClick={() => {
              onRemoveInput(index);
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid2>
      </Grid2>
    </div>
  );
};

export default SortableNodeInput;
