import { FormGroup, Stack, Autocomplete } from '@mui/material';
import { DummyNeuronFormData } from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/DummyNeuronModalCreate';
import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import RHFTextField from 'components/ui/rhf-components/RHFTextField/RHFTextField';
import { useFormContext } from 'react-hook-form';
import {
  COMMON_MIME_TYPES,
  DUMMY_NEURON_ARRAY_TYPE_MENU_ITEMS,
  DUMMY_NEURON_DATA_MENU_ITEMS,
} from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuron.consts';
import { mapDataTypeOption } from 'features/Flow/components/DataTypeField/DataTypeField.utils';
import { getDataMenuItemValue } from 'utils/dataSchema';

interface DummyNeuronFormFieldsProps {
  items: NodeInput[] | NodeOutput[];
  isConnected?: boolean;
}

const DummyNeuronFormFields = ({ items, isConnected = false }: DummyNeuronFormFieldsProps) => {
  const { formState, watch, setValue } = useFormContext<DummyNeuronFormData>();

  const dataType = watch('dataType');
  const mediaTypeValue = watch('mediaType');

  const validateDuplicateName = (name?: string | null) => {
    if (!items.filter((item) => item.name === name).length) {
      return true;
    }
    return 'Cannot contain duplicate names.';
  };

  const mediaTypeField = (
    <Autocomplete
      options={COMMON_MIME_TYPES}
      onChange={(_event, value) => {
        setValue('mediaType', value ?? null);
      }}
      value={mediaTypeValue ?? null}
      disabled={isConnected}
      renderInput={(params) => (
        <RHFTextField
          {...params}
          name="mediaType"
          required
          rules={{ required: true }}
          label="Media Type"
        />
      )}
    />
  );

  return (
    <FormGroup>
      <Stack direction="column" spacing={1}>
        <RHFTextField
          name="name"
          fullWidth
          label="Key"
          error={!!formState.errors.name}
          helperText={formState.errors.name?.message}
          required
          rules={{
            required: 'This field is required.',
            validate: validateDuplicateName,
          }}
        />
        <RHFTextField
          select
          name="dataType"
          label="Data Type"
          required
          fullWidth
          helperText={formState.errors.name?.message}
          rules={{ required: 'This field is required.' }}
          disabled={isConnected}
        >
          {DUMMY_NEURON_DATA_MENU_ITEMS.map(({ schema, title }) =>
            mapDataTypeOption({
              schema,
              title,
              value: getDataMenuItemValue(schema),
            }),
          )}
        </RHFTextField>
        {dataType === 'array' && (
          <>
            <RHFTextField
              select
              name="arrayDataType"
              label="Array Data Type"
              required
              fullWidth
              defaultValue="text"
              helperText={formState.errors.name?.message}
              rules={{ required: 'This field is required.' }}
              disabled={isConnected}
            >
              {DUMMY_NEURON_ARRAY_TYPE_MENU_ITEMS.map(({ schema, title }) =>
                mapDataTypeOption({
                  schema,
                  title,
                  value: getDataMenuItemValue(schema),
                }),
              )}
            </RHFTextField>
            {watch('arrayDataType') === 'asset' && mediaTypeField}
          </>
        )}
        {dataType === 'asset' && mediaTypeField}
        <RHFTextField name="title" fullWidth label="Title" />
      </Stack>
    </FormGroup>
  );
};

export default DummyNeuronFormFields;
