import { Config, useRequest } from 'api/client';
import { PipelineTriggerDetailResponse } from 'api/services/usePipelineTriggers/usePipelineTriggers.types';

const usePipelineTrigger = (
  pipelineId: string,
  triggerId: string,
  pipelineVersionId?: string,
  config?: Config<PipelineTriggerDetailResponse>,
) => {
  const queryParams = new URLSearchParams();

  if (pipelineVersionId) {
    queryParams.append('pipelineVersionId', pipelineVersionId);
  }

  const response = useRequest<PipelineTriggerDetailResponse>(
    {
      url: `/pipelines/${pipelineId}/triggers/${triggerId}?${queryParams.toString()}`,
    },
    config,
  );

  return {
    isTriggerLoading: response.isLoading || response.isValidating,
    trigger: response.data,
    triggerError: response.error,
    mutateTrigger: response.mutate,
  };
};

export default usePipelineTrigger;
