import { SvgIcon, SvgIconProps } from '@mui/material';

const PathwaysProjectIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="10" fill="url(#paint0_radial_10327_30015)" />
      <path
        d="M10.0324 29.6667H6.11511V17.3254H14.4004C17.4684 17.3254 19.4098 19.1107 19.4098 21.6241C19.4098 24.1547 17.4164 25.9401 14.3311 25.9401H10.0324V29.6667ZM10.0324 20.1854V23.1841H13.7764C14.8684 23.1841 15.5098 22.6814 15.5098 21.6587C15.5098 20.6707 14.8858 20.1854 13.7244 20.1854H10.0324ZM37.2592 21.8841L38.7152 17.3254H42.6499L38.3165 29.6667H33.8445L32.4232 24.6747C32.0419 23.3054 31.6432 21.9361 31.2792 20.5667C30.8979 21.9707 30.4992 23.3921 30.1005 24.8307L28.7485 29.6667H24.2592L19.9605 17.3254H23.9125L25.3339 21.8667C25.7672 23.2707 26.1485 24.7441 26.5125 26.1654C26.8765 24.7267 27.2752 23.2881 27.7085 21.8667L29.0779 17.3254H33.4979L34.8845 21.8667C35.3179 23.3054 35.6992 24.6921 36.0632 26.1307C36.4272 24.7094 36.7912 23.3054 37.2592 21.8841Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_10327_30015"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(48 48) rotate(-135) scale(67.8823)"
        >
          <stop offset="0.1" stopColor="#BFAFF9" />
          <stop offset="0.9" stopColor="#4F25EE" />
        </radialGradient>
      </defs>
    </svg>
  </SvgIcon>
);

export default PathwaysProjectIcon;
