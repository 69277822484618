import 'reactflow/dist/style.css';
import * as Styled from './Flow.styles';
import CustomControl from 'features/Flow/components/CustomControl/CustomControl';
import ElementSidebar from 'features/Flow/components/ElementSidebar/ElementSidebar';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import PipelineAction from 'features/Flow/components/PipelineAction/PipelineAction';
import { useEffect, useRef, useState } from 'react';
import SelectedNodePanel from 'features/Flow/components/RightSidebar/components/SelectedNodePanel/SelectedNodePanel';
import SelectedNodesPanel from './components/RightSidebar/components/SelectedNodesPanel/SelectedNodesPanel';
import useCopyPasteShortcuts from './hooks/useCopyPasteShortcuts';
import useSavePipeline from 'api/services/useSavePipeline/useSavePipeline';
import useToast from 'contexts/toast/useToast';
import { fitViewOptions } from './Flow.consts';
import { useNodesInitialized, useStoreApi } from 'reactflow';
import { FlowEditorProps, RightSidebarView } from './Flow.types';
import { Layout, LayoutContainer } from 'components/Layout/Layout.styles';
import { Sidebar } from 'components/Sidebar/Sidebar.styles';
import { useAppRoutes } from 'utils/routes';
import { useNavigate } from 'react-router-dom';
import { useGetHandle } from './Handles/handles';
import { useFlowHandlers } from './useFlowHandlers';
import useCopyPipeline from 'api/services/useCopyPipeline/useCopyPipeline';
import { runNextTick } from 'utils/browser';
import Flow from './Flow';
import useExportPipeline from 'api/services/useExportPipeline/useExportPipeline';
import { AxiosError } from 'axios';
import useDebouncedFitView from './hooks/useDebouncedFitView';
import usePublishPipelineVersion from 'api/services/usePublishPipelineVersion/usePublishPipelineVersion';
import useRightSidebarView from 'features/Flow/hooks/useRightSidebarView';
import PipelineSidebar from 'features/Flow/components/PipelineSidebar/PipelineSidebar';
import BackButton from 'components/BackButton/BackButton';
import useNeuronScopes from 'api/services/useNeuronScopes/useNeuronScopes';
import useWorkspaceConnectedCredentials from 'api/services/useWorkspaceConnectedCredentials/useWorkspaceConnectedCredentials';
import useWorkspace from 'hooks/useWorkspace';
import { BreadcrumbText } from 'components/NavigationBar/NavigationBar.styles';

export default function FlowEditor({ pipeline, refreshPipeline }: FlowEditorProps) {
  useCopyPasteShortcuts();
  const navigate = useNavigate();
  const toast = useToast();
  const storeApi = useStoreApi();
  const nodesInitialized = useNodesInitialized();
  const { edges, nodes, eventHandlers, setInitialEdges, setInitialNodes } = useFlowHandlers();
  const routes = useAppRoutes();
  const { id: workspaceId } = useWorkspace();
  const { debouncedFitView, isFitViewPending } = useDebouncedFitView();
  const { neuronScopes } = useNeuronScopes();
  const { connectedCredentialProviders } = useWorkspaceConnectedCredentials(workspaceId);

  const [pipelineName, setPipelineName] = useState<string>(pipeline.name);
  const [pipelineDescription, setPipelineDescription] = useState<string | undefined>(
    pipeline.description,
  );
  const [pipelineNameError, setPipelineNameError] = useState(false);

  const selectedNodes = nodes.filter((node) => node.selected);
  const selectedNode = selectedNodes.at(0);

  const { savePipeline, isSaving } = useSavePipeline();
  const { exportPipeline, isLoading: isExporting } = useExportPipeline();
  const { copyPipeline } = useCopyPipeline();
  const { publishPipelineVersion, isPublishing } = usePublishPipelineVersion();
  const getHandle = useGetHandle();

  const rightSidebarView = useRightSidebarView(selectedNodes);
  useEffect(() => {
    storeApi.setState(() => ({
      mode: 'editor',
      isFlowLoading: isFitViewPending,
      neuronScopes,
      connectedCredentialProviders: connectedCredentialProviders,
    }));
  }, [isFitViewPending, neuronScopes, connectedCredentialProviders, storeApi]);

  const isFlowInitialized = useRef(false);

  useEffect(() => {
    if (isFlowInitialized.current) return;

    setInitialNodes(pipeline.nodes);
    setInitialEdges(pipeline.edges);
    setPipelineName(pipeline.name);
    setPipelineDescription(pipeline.description);
    isFlowInitialized.current = true;
  }, [pipeline, setInitialEdges, setInitialNodes]);

  useEffect(() => {
    if (!nodesInitialized) return;

    debouncedFitView();
  }, [debouncedFitView, nodesInitialized]);

  const pipelineNameRef = useRef<HTMLInputElement>(null);

  function focusPipelineName() {
    setPipelineNameError(true);
    storeApi.getState().onNodesChange?.(
      selectedNodes.map((node) => ({
        type: 'select',
        id: node.id,
        selected: false,
      })),
    );

    runNextTick(() => {
      pipelineNameRef.current?.focus();
    });
  }

  const handleSavePipeline = () => {
    if (pipelineName.trim().length === 0) {
      focusPipelineName();
    } else {
      setPipelineNameError(false);
      savePipeline({
        nodes,
        edges,
        pipelineName,
        pipelineDescription,
        pipeline,
        getHandle,
      })
        .then(() => {
          toast.success({
            message: 'Your changes were successfully saved.',
          });
        })
        .then(() => refreshPipeline())
        .catch((error: Error) => {
          toast.error({
            message: `Something went wrong. ${error.message}`,
          });
        });
    }
  };

  const handleSaveCopy = () => {
    copyPipeline({
      pipelineId: pipeline.id,
    })
      .then(({ data }) => {
        navigate(routes.pipelines(data.id));
        toast.success({
          message: 'Pipeline duplicated successfully.',
        });
      })
      .catch((error: Error) => {
        toast.error({
          message: `Something went wrong. ${error.message}`,
        });
      });
  };

  const handleSaveAndExport = async () => {
    try {
      // TODO: In the future we should consider a way that avoids having to save if it doesn't need to with an `editor.isDirty` sort of check
      await savePipeline({
        nodes,
        edges,
        pipelineName,
        pipelineDescription,
        pipeline,
        getHandle,
      });
      await exportPipeline({ pipelineId: pipeline.id });
      toast.success({
        message: 'Pipeline schema exported successfully.',
      });
      await refreshPipeline();
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        // eslint-disable-next-line
        const message = err.response ? err.response.data.message : 'Please try again';
        toast.error({
          message: `An error occurred and the pipeline could not be exported.\n${message}.`,
        });
      }
    }
  };

  const handlePublishVersion = async () => {
    try {
      if (pipeline.pipelineState === 'published') {
        toast.warning({
          message: 'This pipeline version is already published.',
        });
        return;
      }

      const {
        data: { id: versionId },
      } = await savePipeline({
        nodes,
        edges,
        pipelineName,
        pipelineDescription,
        pipeline,
        getHandle,
      });

      await publishPipelineVersion({
        pipelineId: pipeline.id,
        versionId: versionId,
      });
      toast.success({
        message: 'Pipeline version published successfully.',
      });
      navigate(routes.executions.runs(pipeline.id));
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        // eslint-disable-next-line
        const message = err.response ? err.response.data.message : 'Please try again';
        toast.error({
          message: `An error occurred and the pipeline version could not be published.\n${message}.`,
        });
      }
    }
  };

  const isLoading = isSaving || isExporting || isPublishing;
  return (
    <Layout>
      <NavigationBar
        title={pipelineName}
        firstBreadcrumb={{
          label: (
            <BackButton data-testid="PipelineBackButton" to={routes.executions.runs(pipeline.id)}>
              <BreadcrumbText variant="titleMedium">Edit Pipeline </BreadcrumbText>
            </BackButton>
          ),
        }}
        hideHome
      >
        <PipelineAction
          pipeline={pipeline}
          isLoading={isLoading}
          onSave={handleSavePipeline}
          onSaveCopy={handleSaveCopy}
          onExport={handleSaveAndExport}
          pipelinePublishingActionAttributes={{
            onPublish: handlePublishVersion,
            pipelineName,
            pipelineVersion: pipeline.version,
            shouldShowPublishButton: pipeline.pipelineState === 'draft',
          }}
        />
      </NavigationBar>

      <LayoutContainer>
        <ElementSidebar pipelineType={pipeline.type} />

        <Flow edges={edges} nodes={nodes} {...eventHandlers}>
          <CustomControl fitViewOptions={fitViewOptions} />
          <Styled.FlowBackground />
        </Flow>

        <Sidebar $borderLeft $width={384}>
          {rightSidebarView === RightSidebarView.PIPELINE && (
            <PipelineSidebar
              pipelineNameRef={pipelineNameRef}
              pipelineName={pipelineName}
              setPipelineName={setPipelineName}
              pipelineNameError={pipelineNameError}
              pipeline={pipeline}
              pipelineDescription={pipelineDescription}
              setPipelineDescription={setPipelineDescription}
            />
          )}
          {rightSidebarView === RightSidebarView.SELECTED_NODE && selectedNode && (
            <SelectedNodePanel selectedNode={selectedNode} />
          )}
          {rightSidebarView === RightSidebarView.SELECTED_NODES && (
            <SelectedNodesPanel selectedNodes={selectedNodes} />
          )}
        </Sidebar>
      </LayoutContainer>
    </Layout>
  );
}
