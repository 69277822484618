import {
  Dialog as BaseDialog,
  DialogActions as BaseDialogActions,
  DialogContent as BaseDialogContent,
  DialogTitle as BaseDialogTitle,
  Box,
  styled,
} from '@mui/material';

export const Dialog = styled(BaseDialog)`
  .MuiPaper-root {
    height: 600px;
    max-height: 90vh;
  }
`;

export const DialogTitle = styled(BaseDialogTitle)<{ $hasBorder?: boolean }>`
  border-bottom: ${({ theme, $hasBorder }) =>
    $hasBorder ? `${theme.palette.surface.dividerMain} 1px solid` : 'none'};
`;

export const DialogActions = styled(BaseDialogActions)<{ $hasBorder?: boolean }>`
  border-top: ${({ theme, $hasBorder }) =>
    $hasBorder ? `${theme.palette.surface.dividerMain} 1px solid` : 'none'};
`;

export const DialogContent = styled(BaseDialogContent)`
  padding: ${({ theme }) => `${theme.spacing(2, 3)}!important`};
  overflow-x: hidden;
`;

export const NoItemsBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.surface.level0};
  margin-top: 16px;
  padding: 16px;
  border-radius: 8px;
  text-align: center;
`;
