import { Typography } from '@mui/material';
import { JobInfoResponse } from 'api/services/usePipelineExecutionOutputs/usePipelineExecutionOutputs.types';
import JobTypeIcon from 'components/JobTypeIcon/JobTypeIcon';
import { getJobTitle } from 'utils/neurons';

type JobInfoTitleProps = Pick<JobInfoResponse, 'name' | 'type' | 'customTitle'> & {
  displaySecondaryName?: boolean;
};

export default function JobInfoTitle(props: JobInfoTitleProps) {
  const title = getJobTitle(props);
  const hasTitleChanged = title !== props.name;
  const canDisplaySecondaryName = props.displaySecondaryName && hasTitleChanged;

  return (
    <Typography
      variant="titleMedium"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1.5,
      }}
    >
      <JobTypeIcon type={props.type} variant="filled" />
      <div>
        {title}
        {canDisplaySecondaryName && (
          <Typography component="p" variant="bodySmall" color="textSecondary">
            {props.name}
          </Typography>
        )}
      </div>
    </Typography>
  );
}
