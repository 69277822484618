import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export interface ConfirmPublishingVersionModalProps {
  isOpen?: boolean;
  isLoading?: boolean;
  onClose: () => void;
  pipelineName: string;
  pipelineVersion: number;
  onConfirmPublish: () => Promise<void>;
}

const ModalCard = styled.div`
  background-color: ${({ theme }) => theme.palette.surface.level4};
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.spacing(1)};
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
  gap: ${({ theme }) => theme.spacing(2)};
  box-shadow: unset;
`;

export default function ConfirmPublishingVersionModal(props: ConfirmPublishingVersionModalProps) {
  const {
    isOpen = false,
    isLoading = false,
    onClose,
    onConfirmPublish,
    pipelineName,
    pipelineVersion,
  } = props;

  const closeModal = () => {
    onClose();
  };

  const handlePublish = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await onConfirmPublish();
    onClose();
  };

  const titleId = 'publish-pipeline-version-dialog-title';
  return (
    <Dialog
      maxWidth="xs"
      data-testid="confirm-publishing-modal"
      open={isOpen}
      onClose={closeModal}
      disableEnforceFocus
    >
      <DialogTitle id={titleId}>Publish Pipeline</DialogTitle>
      <DialogContent>
        <ModalCard>
          <Typography
            variant="titleSmall"
            sx={(theme) => ({
              color: theme.palette.text.primary,
            })}
          >
            {pipelineName}
          </Typography>
          <Typography variant="bodySmall">Version {pipelineVersion}</Typography>
        </ModalCard>
        <DialogContentText
          sx={{
            paddingTop: 1.5,
          }}
        >
          <Typography
            variant="bodySmall"
            sx={(theme) => ({
              color: theme.palette.text.primary,
            })}
          >
            Publishing this will make it available to run for all manual and automated locations
            where this pipeline is accessible.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={closeModal} disabled={isLoading}>
          Cancel
        </Button>
        <Button onClick={handlePublish} disabled={isLoading}>
          {isLoading ? (
            <CircularProgress
              size={18}
              sx={(theme) => ({ color: theme.palette.action.disabled })}
            />
          ) : (
            'Publish'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
