import { Stack, Typography } from '@mui/material';

interface PipelineSearchNotFoundCardProps {
  searchQuery: string;
}

const PipelineSearchNotFoundCard = ({ searchQuery }: PipelineSearchNotFoundCardProps) => (
  <Stack
    sx={{
      display: 'grid',
      gap: 2,
      textAlign: 'center',
      maxWidth: 480,
      marginTop: 2,
    }}
  >
    <Typography variant="titleLarge">No Results for {searchQuery}</Typography>
    <Typography
      variant="bodyLarge"
      sx={{
        color: 'text.secondary',
      }}
    >
      Try another search term or make sure there are no typos.
    </Typography>
  </Stack>
);

export default PipelineSearchNotFoundCard;
