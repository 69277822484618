import { AudioFile } from '@mui/icons-material';
import { SvgIcon, SvgIconProps } from '@mui/material';
import ImageIcon from './Image';
import ImageArrayIcon from './ImageArray';
import AudioFileArrayIcon from './AudioFileArray';

export const AssetObjectFile: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <rect width="32" height="32" rx="8" fill="#9575CD" />
        <mask id="mask0_5199_68729" maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
          <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_5199_68729)">
          <path
            d="M10.5998 25C10.1048 25 9.68105 24.8237 9.32855 24.4713C8.97605 24.1187 8.7998 23.695 8.7998 23.2V8.8C8.7998 8.305 8.97605 7.88125 9.32855 7.52875C9.68105 7.17625 10.1048 7 10.5998 7H17.7998L23.1998 12.4V23.2C23.1998 23.695 23.0236 24.1187 22.6711 24.4713C22.3186 24.8237 21.8948 25 21.3998 25H10.5998ZM16.8998 13.3H21.3998L16.8998 8.8V13.3Z"
            fill="black"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export const ArrayAssetObjectFile: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <rect width="32" height="32" rx="8" fill="#9575CD" />
        <g mask="url(#mask0_5219_5038)">
          <path
            d="M12.2 22C11.87 22 11.5875 21.8825 11.3525 21.6475C11.1175 21.4125 11 21.13 11 20.8V11.2C11 10.87 11.1175 10.5875 11.3525 10.3525C11.5875 10.1175 11.87 10 12.2 10H17L20.6 13.6V20.8C20.6 21.13 20.4825 21.4125 20.2475 21.6475C20.0125 21.8825 19.73 22 19.4 22H12.2ZM16.4 14.2H19.4L16.4 11.2V14.2Z"
            fill="black"
          />
        </g>
        <path
          d="M26.125 25.125C25.5417 25.7083 24.8333 26 24 26H22C21.7167 26 21.4792 25.9042 21.2875 25.7125C21.0958 25.5208 21 25.2833 21 25C21 24.7167 21.0958 24.4792 21.2875 24.2875C21.4792 24.0958 21.7167 24 22 24H24C24.2833 24 24.5208 23.9042 24.7125 23.7125C24.9042 23.5208 25 23.2833 25 23V9C25 8.71667 24.9042 8.47917 24.7125 8.2875C24.5208 8.09583 24.2833 8 24 8H22C21.7167 8 21.4792 7.90417 21.2875 7.7125C21.0958 7.52083 21 7.28333 21 7C21 6.71667 21.0958 6.47917 21.2875 6.2875C21.4792 6.09583 21.7167 6 22 6H24C24.8333 6 25.5417 6.29167 26.125 6.875C26.7083 7.45833 27 8.16667 27 9V23C27 23.8333 26.7083 24.5417 26.125 25.125Z"
          fill="black"
        />
        <path
          d="M5.875 25.125C6.45833 25.7083 7.16667 26 8 26H10C10.2833 26 10.5208 25.9042 10.7125 25.7125C10.9042 25.5208 11 25.2833 11 25C11 24.7167 10.9042 24.4792 10.7125 24.2875C10.5208 24.0958 10.2833 24 10 24H8C7.71667 24 7.47917 23.9042 7.2875 23.7125C7.09583 23.5208 7 23.2833 7 23V9C7 8.71667 7.09583 8.47917 7.2875 8.2875C7.47917 8.09583 7.71667 8 8 8H10C10.2833 8 10.5208 7.90417 10.7125 7.7125C10.9042 7.52083 11 7.28333 11 7C11 6.71667 10.9042 6.47917 10.7125 6.2875C10.5208 6.09583 10.2833 6 10 6H8C7.16667 6 6.45833 6.29167 5.875 6.875C5.29167 7.45833 5 8.16667 5 9V23C5 23.8333 5.29167 24.5417 5.875 25.125Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export const AssetObjectImage = ImageIcon;
export const ArrayAssetObjectImage = ImageArrayIcon;
export const AssetAudio = AudioFile;
export const ArrayAssetAudio = AudioFileArrayIcon;
