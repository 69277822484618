import { Grid2, TextField as MuiTextField, MenuItem } from '@mui/material';
import { isEmpty } from 'lodash';
import { Edge, useEdges, useNodes } from 'reactflow';
import {
  isAssetDataSchema,
  isArrayDataSchema,
  isDynamicEditorValue,
  isEnumDataSchema,
} from '@pathways/pipeline-schema/web';
import { createHandleId } from 'features/Flow/Flow.utils';
import RightSidebarTextInput from 'features/Flow/components/RightSidebar/components/Inputs/RightSidebarTextInput';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import {
  integerValidation,
  numberValidation,
} from 'components/ui/rhf-components/utils/validationRules';
import { FlowNode, FlowNodeData } from 'types/reactflow';
import * as Styled from './Inputs.styles';
import { InputProps, VisibilityProps } from './Inputs.types';
import VisibilityIconButton from 'features/Flow/components/RightSidebar/components/Inputs/VisibilityIconButton';
import UnlinkIcon from 'components/Icons/Unlink';
import { useState } from 'react';
import { getJobTitle } from 'utils/neurons';
import ObjectInput from 'features/Flow/components/RightSidebar/components/Inputs/ObjectInput';
import RightSidebarAutocomplete from 'features/Flow/components/RightSidebar/components/Inputs/RightSidebarAutocomplete';
import { isUrl } from 'utils/string';
import { isObjectDataSchema } from 'features/Flow/nodes/Node/DataSchemas/objectSchema';
import { isIntegerDataSchema } from 'features/Flow/nodes/Node/DataSchemas/integerSchema';
import { isTextDataSchema } from 'features/Flow/nodes/Node/DataSchemas/textSchema';
import { isNumberDataSchema } from 'features/Flow/nodes/Node/DataSchemas/numberSchema';
import { useUpdateNodeInput } from 'features/Flow/hooks/useUpdateNodeInput';
import { isBooleanDataSchema } from 'features/Flow/nodes/Node/DataSchemas/booleanSchema';
import BooleanSelect from 'components/Inputs/BooleanSelect/BooleanSelect';
import AssetInput from 'components/Inputs/AssetUpload/AssetInput';
import DecimalInput from 'components/Inputs/DecimalInput/DecimalInput';
import useRemoveEdge from 'features/Flow/hooks/useRemoveEdge';
import TextField from 'components/Inputs/TextField/TextField';

const getNodeNameBySource = (source: string, nodes: FlowNode[]) => {
  const node = nodes.find((node) => node.id === source);

  return getJobTitle({
    id: node?.id,
    ...node?.data.metadata,
  });
};

const getValidNumbers = (values: string[]) => {
  return values.map(Number).filter((v) => !isNaN(v));
};

const getEdgeByInputName = (selectedNode: FlowNode, edges: Edge[], inputName: string) => {
  const edge = edges.find(
    (edge) =>
      edge.target === selectedNode.id && edge.targetHandle === createHandleId('input', inputName),
  );

  return edge;
};

const shouldDisplayError = (inputValue: unknown, validationPattern: RegExp) => {
  if (inputValue == null) return false;

  if (Array.isArray(inputValue)) {
    return inputValue.some((value) => !validationPattern.test(String(value)));
  }

  return !validationPattern.test(String(inputValue));
};

type InputByTypeProps = Omit<InputProps, 'inputs' | 'setEdges'> & {
  input: NodeInput;
  readOnly?: boolean;
};

const InputByType = ({ selectedNode, input, readOnly = false }: InputByTypeProps) => {
  const [helpText, setHelpText] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isHideIconVisible, setIsHideIconVisible] = useState(false);
  const nodes = useNodes<FlowNodeData>();
  const edges = useEdges();
  const removeEdge = useRemoveEdge();
  const edge = getEdgeByInputName(selectedNode, edges, input.name);
  const source = edge && getNodeNameBySource(edge.source, nodes);
  const updateNodeInput = useUpdateNodeInput();

  const updateCurrentInput = (inputUpdates: Partial<typeof input>) => {
    updateNodeInput(
      {
        nodeId: selectedNode.id,
        inputName: input.name,
      },
      () => inputUpdates,
    );
  };

  const visibilityProps: VisibilityProps = {
    isHideIconVisible,
    hoverEvents: {
      onMouseEnter: () => {
        setIsHideIconVisible(true);
      },
      onMouseLeave: () => {
        setIsHideIconVisible(false);
      },
    },
    onVisibilityIconClick() {
      updateCurrentInput({
        config: {
          hidden: !input.config.hidden,
        },
      });
    },
  };

  const label = input.title || input.name;

  return (
    <Grid2 key={input.name} size={12}>
      {source ? (
        <MuiTextField
          fullWidth
          label={label}
          required={input.config.required}
          size="small"
          value={source}
          slotProps={{
            input: {
              readOnly,
              endAdornment: (
                <Styled.MuiIconButton
                  aria-label={`Unlink ${input.name}`}
                  size="small"
                  disabled={readOnly}
                  onClick={() => {
                    removeEdge(edge);
                  }}
                >
                  <UnlinkIcon />
                </Styled.MuiIconButton>
              ),
            },
          }}
        />
      ) : (
        <>
          {/* TODO we might want to distribute these across the dataTypes somehow */}
          {isNumberDataSchema(input.dataSchema) && (
            <DecimalInput
              {...visibilityProps.hoverEvents}
              name={input.name}
              label={label}
              required={input.config.required}
              placeholder={readOnly ? '' : 'Enter or connect data...'}
              size="small"
              fullWidth
              InputProps={{
                readOnly,
                endAdornment: (
                  <VisibilityIconButton
                    required={input.config.required}
                    hidden={input.config.hidden}
                    visible={isHideIconVisible}
                    edge={edge}
                    onClick={visibilityProps.onVisibilityIconClick}
                  />
                ),
              }}
              value={input.value ?? ''}
              onChange={(value) => {
                updateCurrentInput({
                  value,
                });
              }}
            />
          )}

          {isIntegerDataSchema(input.dataSchema) && (
            <TextField
              {...visibilityProps.hoverEvents}
              name={input.name}
              label={label}
              required={input.config.required}
              placeholder={readOnly ? '' : 'Enter or connect data...'}
              size="small"
              fullWidth
              InputProps={{
                readOnly,
                endAdornment: (
                  <VisibilityIconButton
                    required={input.config.required}
                    hidden={input.config.hidden}
                    visible={isHideIconVisible}
                    edge={edge}
                    onClick={visibilityProps.onVisibilityIconClick}
                  />
                ),
              }}
              valueAs="integer"
              value={input.value ?? ''}
              onChange={(value) => {
                updateCurrentInput({
                  value: value === '' ? undefined : value,
                });
              }}
            />
          )}

          {isBooleanDataSchema(input.dataSchema) && (
            <BooleanSelect
              {...visibilityProps.hoverEvents}
              name={input.name}
              label={label}
              required={input.config.required}
              fullWidth
              defaultOptionLabel="Select or connect data..."
              InputProps={{
                readOnly,
                sx: {
                  '.visibility-icon': {
                    transform: 'translateX(-100%)',
                  },
                },
                endAdornment: (
                  <VisibilityIconButton
                    required={input.config.required}
                    hidden={input.config.hidden}
                    visible={isHideIconVisible}
                    edge={edge}
                    onClick={visibilityProps.onVisibilityIconClick}
                  />
                ),
              }}
              value={input.value}
              onChange={(value) => {
                updateCurrentInput({
                  value,
                });
              }}
            />
          )}

          {isArrayDataSchema(input.dataSchema, 'number') && (
            <RightSidebarAutocomplete
              edge={edge}
              error={shouldDisplayError(input.value, numberValidation.pattern.value)}
              helperText={
                shouldDisplayError(input.value, numberValidation.pattern.value) &&
                numberValidation.pattern.message
              }
              input={input}
              label={label}
              readOnly={readOnly}
              valueAs="number"
              value={input.value as number[]}
              visibilityProps={visibilityProps}
              onChange={(_event, values) => {
                updateCurrentInput({
                  value: values.length > 0 ? getValidNumbers(values) : undefined,
                });
              }}
            />
          )}

          {isArrayDataSchema(input.dataSchema, 'integer') && (
            <RightSidebarAutocomplete
              edge={edge}
              error={shouldDisplayError(input.value, integerValidation.pattern.value)}
              helperText={
                shouldDisplayError(input.value, integerValidation.pattern.value) &&
                integerValidation.pattern.message
              }
              input={input}
              label={label}
              readOnly={readOnly}
              valueAs="integer"
              value={input.value as number[]}
              visibilityProps={visibilityProps}
              onChange={(_event, values) => {
                updateCurrentInput({
                  value: values.length > 0 ? getValidNumbers(values) : undefined,
                });
              }}
            />
          )}

          {isArrayDataSchema(input.dataSchema, 'text') && (
            <RightSidebarAutocomplete
              edge={edge}
              input={input}
              label={label}
              readOnly={readOnly}
              value={input.value as string[]}
              visibilityProps={visibilityProps}
              onChange={(_event, values) => {
                updateCurrentInput({
                  value: values.length > 0 ? values : undefined,
                });
              }}
            />
          )}

          {isArrayDataSchema(input.dataSchema, 'image-uri') && (
            <RightSidebarAutocomplete
              edge={edge}
              error={hasError}
              helperText={helpText}
              input={input}
              label={label}
              readOnly={readOnly}
              value={input.value as string[]}
              visibilityProps={visibilityProps}
              onChange={(_event, values) => {
                if (values.every(isUrl)) {
                  updateCurrentInput({
                    value: values.length > 0 ? values : undefined,
                  });
                  setHelpText('');
                  setHasError(false);
                } else {
                  setHelpText('Invalid image URL');
                  setHasError(true);
                }
              }}
            />
          )}

          {isTextDataSchema(input.dataSchema) && (
            <RightSidebarTextInput
              {...input}
              edge={edge}
              label={label}
              readOnly={readOnly}
              value={input.value as string | undefined}
              visibilityProps={visibilityProps}
              onChange={(event) => {
                updateCurrentInput({
                  value: event.target.value === '' ? undefined : event.target.value,
                });
              }}
              onConfirmChange={(value: string) => {
                updateCurrentInput({
                  value: value === '' ? undefined : value,
                });
              }}
            />
          )}

          {isEnumDataSchema(input.dataSchema) && (
            <MuiTextField
              {...visibilityProps.hoverEvents}
              select
              name={input.name}
              label={label}
              required={input.config.required}
              size="small"
              fullWidth
              /**
               * If no value or value is an object, set the value to an empty string,
               * to avoid MUI warning: You have provided an out-of-range value `[object Object]` or `undefined`.
               * @example
               * Updating the connection of an enum input while the node is selected and the field is displayed on the right sidebar.
               */
              value={!input.value || isDynamicEditorValue(input.value) ? '' : input.value}
              onChange={(event) => {
                updateCurrentInput({
                  value: event.target.value,
                });
              }}
              slotProps={{
                input: {
                  readOnly,
                  sx: {
                    '.visibility-icon': {
                      transform: 'translateX(-100%)',
                    },
                  },
                  endAdornment: (
                    <VisibilityIconButton
                      required={input.config.required}
                      hidden={input.config.hidden}
                      visible={isHideIconVisible}
                      edge={edge}
                      onClick={visibilityProps.onVisibilityIconClick}
                    />
                  ),
                },

                select: { displayEmpty: true },
              }}
            >
              <MenuItem value="">Select or connect data...</MenuItem>
              {input.dataSchema.values.map((enumOption) => (
                <MenuItem key={enumOption} value={enumOption}>
                  {enumOption}
                </MenuItem>
              ))}
            </MuiTextField>
          )}

          {(isObjectDataSchema(input.dataSchema) ||
            isArrayDataSchema(input.dataSchema, 'object')) && (
            <ObjectInput
              input={input}
              label={label}
              edge={edge}
              onConfirmChange={(value) => {
                updateCurrentInput({
                  value: isEmpty(value) ? undefined : value,
                });
              }}
              visibilityProps={visibilityProps}
              readOnly={readOnly}
            />
          )}

          {isAssetDataSchema(input.dataSchema) || isArrayDataSchema(input.dataSchema, 'asset') ? (
            <AssetInput
              assetScope={{
                type: 'static',
              }}
              input={input}
              value={input.value}
              label={label}
              TextFieldProps={{
                ...visibilityProps.hoverEvents,
                InputProps: {
                  readOnly,
                  endAdornment: (
                    <VisibilityIconButton
                      required={input.config.required}
                      hidden={input.config.hidden}
                      visible={visibilityProps.isHideIconVisible}
                      edge={edge}
                      onClick={visibilityProps.onVisibilityIconClick}
                    />
                  ),
                },
              }}
              onConfirmChange={(value) => {
                if (isEmpty(value)) {
                  updateCurrentInput({
                    value: undefined,
                  });
                  return;
                }

                if (Array.isArray(value)) {
                  updateCurrentInput({
                    value: value.map((asset) => ({
                      filename: asset.filename,
                      mediaType: asset.mediaType,
                      pathwayAssetId: asset.pathwayAssetId,
                    })),
                  });
                  return;
                }

                updateCurrentInput({
                  value: {
                    filename: value.filename,
                    mediaType: value.mediaType,
                    pathwayAssetId: value.pathwayAssetId,
                  },
                });
              }}
            />
          ) : null}
        </>
      )}
    </Grid2>
  );
};

export default InputByType;
