import { CheckCircleOutlined } from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Fade,
  Stack,
  Typography,
} from '@mui/material';
import CopyButton from 'components/CopyButton/CopyButton';
import TextField from 'components/Inputs/TextField/TextField';
import { useState } from 'react';

export interface TriggerCreatedDialogProps {
  DialogProps?: DialogProps;
  token: string;
  closeDialog: () => void;
  onViewTrigger: () => void;
}

export default function TriggerCreatedDialog(props: TriggerCreatedDialogProps) {
  const { closeDialog } = props;
  const [wasTokenCopied, setWasTokenCopied] = useState(false);

  return (
    <Dialog
      open
      fullWidth
      slotProps={{
        paper: {
          sx: {
            maxWidth: 480,
          },
        },
      }}
      {...props.DialogProps}
      onClose={closeDialog}
    >
      <DialogTitle>Trigger created successfully</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="bodyMedium">
            Save this secret key somewhere safe and accessible:
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            value={props.token}
            slotProps={{
              input: {
                endAdornment: (
                  <CopyButton
                    value={props.token}
                    size="medium"
                    sx={{
                      marginRight: 0,
                    }}
                    onCopy={() => {
                      setWasTokenCopied(true);
                    }}
                  />
                ),
                sx: {
                  marginTop: 0,
                },
              },
              htmlInput: {
                readOnly: true,
                sx: {
                  textOverflow: 'ellipsis',
                },
              },
            }}
          />

          <Alert severity="warning">
            For security purposes, you will not be able to view this key once closing this modal. If
            you loose this key, you will need to create a new trigger.
          </Alert>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          paddingX: 3,
          paddingY: 2,
          justifyContent: 'space-between',
        }}
      >
        {wasTokenCopied ? (
          <Fade appear in>
            <Typography
              color="success"
              variant="bodyMedium"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <CheckCircleOutlined /> Copied to clipboard
            </Typography>
          </Fade>
        ) : (
          // Keeps the close button on the right side.
          <span />
        )}

        <Button variant="contained" onClick={props.onViewTrigger}>
          Close & View Trigger
        </Button>
      </DialogActions>
    </Dialog>
  );
}
