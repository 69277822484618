import usePipelineExecutionCheckpoints from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints';
import { CheckpointStatus } from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints.types';
import { usePipelineExecutionInputs } from 'api/services/usePipelineExecutionInputs/usePipelineExecutionInputs';
import usePipelineExecutionOutputs from 'api/services/usePipelineExecutionOutputs/usePipelineExecutionOutputs';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import usePipelineJobs from 'api/services/usePipelineJobs/usePipelineJobs';
import useToast from 'contexts/toast/useToast';
import { EditorContextProvider } from 'features/Flow/EditorContextProvider';
import Flow from 'features/Flow/Flow';
import { fitViewOptions } from 'features/Flow/Flow.consts';
import { NodeType } from 'features/Flow/Flow.types';
import { HandleStoreProvider } from 'features/Flow/Handles/handle.provider';
import CustomControl from 'features/Flow/components/CustomControl/CustomControl';
import useDebouncedFitView from 'features/Flow/hooks/useDebouncedFitView';
import { isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import {
  PIPELINE_COMPLETE_NAME,
  PIPELINE_COMPLETE_NODE_ID,
} from 'features/Flow/nodes/PipelineComplete/PipelineComplete.consts';
import {
  PIPELINE_START_NAME,
  PIPELINE_START_NODE_ID,
} from 'features/Flow/nodes/PipelineStart/PipelineStart.consts';
import { useEffect } from 'react';
import { useEdgesState, useNodesInitialized, useNodesState, useStoreApi } from 'reactflow';
import 'reactflow/dist/style.css';
import { FlowExecutionJob, FlowNodeData } from 'types/reactflow';
import { isPipelineExecutionActive } from 'utils/execution';
import { RuntimeFlowProps } from './RuntimeFlow.types';

export default function RuntimeFlow({ pipeline, pipelineExecution }: RuntimeFlowProps) {
  const toast = useToast();
  const storeApi = useStoreApi();
  const nodesInitialized = useNodesInitialized();
  const { debouncedFitView, isFitViewPending } = useDebouncedFitView();

  const [nodes, setNodes, onNodesChange] = useNodesState<FlowNodeData>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const pipelineExecutionActive = isPipelineExecutionActive(pipelineExecution.status);
  const pipelineExecutionCompleted = pipelineExecution.status === ExecutionStatus.COMPLETED;
  const { pipelineExecutionInputs } = usePipelineExecutionInputs(pipelineExecution.id);
  const { pipelineExecutionOutputs } = usePipelineExecutionOutputs(pipelineExecution.id, {
    shouldFetch: pipelineExecutionCompleted,
  });
  const { pipelineJobs, pipelineJobsError, queryPipelineJobs } = usePipelineJobs(
    pipelineExecution.id,
    {
      shouldFetch: !pipelineExecutionActive,
    },
  );
  const { checkpoints, checkpointsError, mutateCheckpoints } = usePipelineExecutionCheckpoints(
    pipelineExecution.id,
    {
      shouldFetch: !pipelineExecutionActive,
    },
  );

  useEffect(() => {
    setNodes(
      pipeline.nodes.map((node) => {
        node.draggable = false;
        node.style = {
          cursor: 'pointer',
        };

        if (isGroupNode(node)) {
          node.selectable = false;
        }

        return node;
      }),
    );
    setEdges(pipeline.edges);
  }, [pipeline, setEdges, setNodes]);

  useEffect(() => {
    if (!nodesInitialized) return;

    debouncedFitView();
  }, [debouncedFitView, nodesInitialized]);

  useEffect(() => {
    if (pipelineExecutionActive) {
      void queryPipelineJobs();
      void mutateCheckpoints();
    }

    storeApi.setState({
      mode: 'runtime',
      pipelineExecution,
      isFlowLoading: isFitViewPending,
    });
  }, [
    isFitViewPending,
    mutateCheckpoints,
    pipelineExecution,
    pipelineExecutionActive,
    queryPipelineJobs,
    storeApi,
  ]);

  useEffect(() => {
    if (pipelineJobsError ?? checkpointsError) {
      toast.error({
        message: 'There was an error loading the execution jobs status.',
      });
      return;
    }

    if (pipelineJobs.length === 0) return;

    const executionJobs: FlowExecutionJob[] = pipelineJobs.map((job) => {
      const checkpoint = checkpoints.find((checkpoint) => checkpoint.metadata.jobId === job.id);

      if (checkpoint?.status === CheckpointStatus.PENDING) {
        if (pipelineExecutionActive) {
          return {
            ...job,
            status: ExecutionStatus.REVIEW,
          };
        }

        return {
          ...job,
          status: ExecutionStatus.CANCELED,
          comment: 'Canceled.',
        };
      }

      return job;
    });
    executionJobs.push(
      {
        id: PIPELINE_START_NODE_ID,
        name: PIPELINE_START_NAME,
        type: NodeType.PIPELINE_START,
        status: ExecutionStatus.COMPLETED,
        outputs: pipelineExecutionInputs,
      },
      {
        id: PIPELINE_COMPLETE_NODE_ID,
        name: PIPELINE_COMPLETE_NAME,
        type: NodeType.PIPELINE_COMPLETE,
        status: pipelineExecutionCompleted ? ExecutionStatus.COMPLETED : ExecutionStatus.PENDING,
        inputs: pipelineExecutionCompleted ? pipelineExecutionOutputs : undefined,
      },
    );

    storeApi.setState({
      executionJobs,
    });
  }, [
    checkpoints,
    checkpointsError,
    pipelineExecutionCompleted,
    pipelineExecutionActive,
    pipelineExecutionInputs,
    pipelineExecutionOutputs,
    pipelineJobs,
    pipelineJobsError,
    storeApi,
    toast,
  ]);

  return (
    <HandleStoreProvider>
      <EditorContextProvider>
        <Flow
          deleteKeyCode={null}
          edges={edges}
          edgesUpdatable={false}
          nodes={nodes}
          nodesConnectable={false}
          selectionOnDrag={false}
          onEdgesChange={onEdgesChange}
          onNodesChange={onNodesChange}
        >
          <CustomControl fitViewOptions={fitViewOptions} />
        </Flow>
      </EditorContextProvider>
    </HandleStoreProvider>
  );
}
