import { useRequest } from 'api/client';
import { useMemo } from 'react';
import { NeuronScopeMap } from 'types/reactflow';
import { NeuronScopeResponse } from './useNeuronScopes.types';

export default function useNeuronScopes() {
  const request = useRequest<NeuronScopeResponse[]>({
    url: `/neurons/scope/all`,
  });

  const neuronScopes = useMemo(
    () =>
      request.data?.reduce<NeuronScopeMap>((map, current) => {
        map[current.neuronKey] = current;
        return map;
      }, {}),
    [request.data],
  );

  return {
    areNeuronScopesLoading: request.isLoading,
    neuronScopes,
    neuronScopesError: request.error,
    mutateNeuronScopes: request.mutate,
  };
}
