import { Typography } from '@mui/material';
import { getNodeInput, useSelectNodeOnClick } from 'features/Flow/Flow.utils';
import { HandleKey } from 'features/Flow/Handles/handle.store';
import { createCustomHandle, useGetSourceHandle } from 'features/Flow/Handles/handles';
import CustomHandle from 'features/Flow/components/NeuronHandle/CustomHandle';
import { useEditorContext } from 'features/Flow/hooks/useEditorContext';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import React, { useCallback, useEffect, useMemo } from 'react';
import { getConnectedEdges, useStore, useStoreApi, useUpdateNodeInternals } from 'reactflow';
import { FlowState } from 'types/reactflow';
import { PIPELINE_COMPLETE_NAME } from './PipelineComplete.consts';
import * as Styled from './PipelineComplete.styles';
import { PipelineCompleteProps, isPipelineCompleteNode } from './PipelineComplete.types';
import { canConnectToPipelineComplete } from './PipelineComplete.utils';
import { useDeferredConnectionState } from 'features/Flow/hooks/useDeferredConnectionState';
import { isHandleConnection } from 'features/Flow/Flow.types';
import useFlow from 'features/Flow/hooks/useFlow';
import { usePipelineCompleteInputs } from './usePipelineCompleteInputs';
import { useGetNodeExecution } from 'features/Flow/hooks/useGetNodeExecution';
import NodeInputHandle from '../Node/NodeInputHandle';
import { usePipelineCompleteModal } from './PipelineCompleteModal/usePipelineCompleteModal';
import PipelineCompleteModal from './PipelineCompleteModal/PipelineCompleteModal';
import { usePipelineCompleteQuickCreateModal } from './PipelineCompleteQuickCreateModal/usePipelineCompleteQuickCreateModal';
import { NodeInput } from '../Node/Node.types';
import PipelineCompleteQuickCreateModal from './PipelineCompleteQuickCreateModal/PipelineCompleteQuickCreateModal';
import ConfigureSchemaButton from 'features/Flow/components/ConfigureSchemaButton/ConfigureSchemaButton';

const selector = ({ edges, executionJobs, mode, getNodes }: FlowState) => ({
  edges,
  executionJobs,
  mode,
  getNodes,
});

const PipelineComplete = React.memo(function PipelineComplete(props: PipelineCompleteProps) {
  const {
    id: nodeId,
    data: { inputs },
  } = props;

  const updateNodeInternals = useUpdateNodeInternals();
  const storeApi = useStoreApi();
  const { edges, mode } = useStore(selector);
  const { execution } = useGetNodeExecution(props);
  const { getNodeOrThrow } = useFlow();
  const node = useMemo(
    () => getNodeOrThrow(nodeId, isPipelineCompleteNode),
    [getNodeOrThrow, nodeId],
  );

  const handleSelectNodeOnClick = useSelectNodeOnClick(nodeId);
  const { addConnectionHandler, removeConnectionHandler } = useEditorContext();
  const { deferredConnection, setDeferredConnectionState, cancelDeferredConnectionAction } =
    useDeferredConnectionState({
      inputs,
    });

  const getSourceHandle = useGetSourceHandle();
  const { handleDisconnectedInput, handleConnectedInput, attemptAddInput } =
    usePipelineCompleteInputs({
      nodeId,
      inputs,
    });
  const nodeEdges = useMemo(() => getConnectedEdges([node], edges), [edges, node]);

  const addInputHandle = useMemo(
    () => createCustomHandle(nodeId, 'input', canConnectToPipelineComplete),
    [nodeId],
  );

  const createInputFromModal = useCallback(
    (input: NodeInput) => {
      if (!deferredConnection) {
        throw new Error('No deferred connection found.');
      }

      const newInput = getNodeInput(input);
      const isAdded = attemptAddInput(newInput, nodeId);

      if (!isAdded) {
        throw new Error('Input was not added.');
      }

      setDeferredConnectionState({
        connection: {
          ...deferredConnection,
          targetHandle: newInput.id,
        },
        inputName: newInput.name,
      });
    },
    [attemptAddInput, deferredConnection, nodeId, setDeferredConnectionState],
  );

  const pipelineCompleteModalState = usePipelineCompleteModal({ nodeId });
  const { openModal, ...quickCreateModal } = usePipelineCompleteQuickCreateModal({
    onCreateInput: createInputFromModal,
    onCloseModal: cancelDeferredConnectionAction,
  });

  useEffect(() => {
    const connectionHandlerKey: HandleKey = {
      id: addInputHandle.id,
      nodeId,
    };

    addConnectionHandler?.(connectionHandlerKey, (connection, actionCallback) => {
      if (!isHandleConnection(connection)) return connection;

      const sourceHandle = getSourceHandle(connection);

      const newInput = getNodeInput({
        config: sourceHandle.config,
        dataSchema: sourceHandle.schema,
        name: sourceHandle.name,
        title: sourceHandle.title,
        description: sourceHandle.description,
        value: '',
      });

      const isAdded = attemptAddInput(newInput, nodeId);

      setDeferredConnectionState({
        action: actionCallback,
        connection: {
          ...connection,
          targetHandle: newInput.id,
        },
      });

      if (isAdded) {
        setDeferredConnectionState({
          inputName: newInput.name,
        });
      } else {
        // Open the modal for the user to finish creating the new output
        openModal(newInput);
      }

      return 'defer';
    });

    return () => {
      removeConnectionHandler?.(connectionHandlerKey);
    };
  }, [
    addConnectionHandler,
    addInputHandle.id,
    attemptAddInput,
    getSourceHandle,
    nodeId,
    openModal,
    removeConnectionHandler,
    setDeferredConnectionState,
  ]);

  useEffect(() => {
    updateNodeInternals(nodeId);

    if (deferredConnection) return;

    const completeNode = getNodeOrThrow(nodeId, isPipelineCompleteNode);
    const pipelineCompleteInputs = completeNode.data.inputs.map(getNodeInput);

    storeApi.setState(() => ({
      pipelineCompleteInputs,
    }));

    pipelineCompleteInputs.forEach((input) => {
      const inputEdges = nodeEdges.filter((edge) => edge.targetHandle === input.id);
      const isInputConnected = inputEdges.length > 0;
      if (isInputConnected) {
        handleConnectedInput(input, inputEdges[0]);
      } else {
        handleDisconnectedInput(input);
      }
    });
  }, [
    deferredConnection,
    getNodeOrThrow,
    handleConnectedInput,
    handleDisconnectedInput,
    nodeEdges,
    nodeId,
    storeApi,
    updateNodeInternals,
  ]);

  return (
    <Styled.NodeContainer $isSelected={props.selected}>
      <Styled.NodeHeader>
        <Typography variant="labelLarge">{PIPELINE_COMPLETE_NAME}</Typography>
        {mode === 'runtime' && (
          <StatusIcon status={execution?.status} color={({ palette }) => palette.text.primary} />
        )}
        {mode === 'editor' && (
          <ConfigureSchemaButton onClick={pipelineCompleteModalState.openModal} />
        )}
      </Styled.NodeHeader>

      <Styled.NodeDataRow className="nodrag" onClick={handleSelectNodeOnClick}>
        {' '}
        <Styled.NodeDataColumn>
          {inputs.map((input) => (
            <NodeInputHandle key={input.id ?? input.name} input={input} nodeId={nodeId} />
          ))}

          {mode === 'editor' && (
            <CustomHandle handle={addInputHandle} title="Add data to show completion..." />
          )}
        </Styled.NodeDataColumn>
      </Styled.NodeDataRow>

      {mode === 'editor' && pipelineCompleteModalState.open && (
        <PipelineCompleteModal inputs={inputs} {...pipelineCompleteModalState} />
      )}

      {mode === 'editor' && quickCreateModal.open && (
        <PipelineCompleteQuickCreateModal inputs={inputs} {...quickCreateModal} />
      )}
    </Styled.NodeContainer>
  );
});

export default PipelineComplete;
