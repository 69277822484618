import { Stack, Typography } from '@mui/material';
import Loader from 'components/Loader/Loader';
import { useNodes } from 'reactflow';
import { FlowNodeData } from 'types/reactflow';
import ElementLayerButton from '../ElementLayerButton/ElementLayerButton';
import { ElementLayerSkeleton } from '../ElementLayerButton/ElementLayerButton.styles';

export default function ElementLayers() {
  const nodes = useNodes<FlowNodeData>();
  const loading = nodes.length === 0;

  return (
    <Stack
      spacing={1}
      sx={{
        width: '100%',
      }}
    >
      <Typography variant="labelLarge">Elements</Typography>

      {loading &&
        Array(2)
          .fill(0)
          .map((_, index) => (
            <Loader key={index} loading={loading} width="100%">
              <ElementLayerSkeleton />
            </Loader>
          ))}

      {nodes.map((node) => (
        <ElementLayerButton key={node.id} node={node} />
      ))}
    </Stack>
  );
}
