import { Stack, Typography } from '@mui/material';
import { ProgressPaper } from './ExecutionProgress.styles';

interface ProgressCardProps {
  label: string;
  completedCount: number;
  totalCount: number;
}

export default function ProgressCard(props: ProgressCardProps) {
  const { completedCount, totalCount, label } = props;

  return (
    <ProgressPaper elevation={2}>
      <Stack
        sx={{
          gap: 2,
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'baseline',
            gap: 1,
          }}
        >
          <Typography variant="headlineLarge">{completedCount}</Typography>
          <Typography
            variant="headlineSmall"
            sx={{
              color: 'text.disabled',
            }}
          >
            of
          </Typography>
          <Typography variant="headlineLarge">{totalCount}</Typography>
        </Stack>
        <Typography
          variant="bodyLarge"
          sx={{
            color: 'text.secondary',
            textAlign: 'center',
          }}
        >
          {label}
        </Typography>
      </Stack>
    </ProgressPaper>
  );
}
