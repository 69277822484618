import DynamicImageWithLoader from 'components/DynamicImageWithLoader/DynamicImageWithLoader';
import {
  ImageCard,
  ImageCardActionsContainer,
  ImageContainer,
} from 'components/ImageCard/ImageCard.styles';
import { isAssetImageType } from '../ExecutionCheckpoint/forms/ExecutionCheckpointAssetForm/ExecutionCheckpointAsset.utils';
import { AssetLoaderProps } from './AssetLoader.types';

const AssetLoader = ({ asset, leftAction, rightAction }: AssetLoaderProps): JSX.Element => {
  const isImageType = isAssetImageType(asset);

  return (
    <ImageCard>
      {isImageType && (
        <ImageContainer>
          <DynamicImageWithLoader imageUrl={asset.signedUrl} alt="Generated asset" />
        </ImageContainer>
      )}
      <ImageCardActionsContainer>
        {leftAction}
        {rightAction}
      </ImageCardActionsContainer>
    </ImageCard>
  );
};

export default AssetLoader;
