import { NodeType } from 'features/Flow/Flow.types';
import { createContext } from 'react';
import type { Connection, Edge } from 'reactflow';
import { type HandleKey } from './Handles/handle.store';

export type ConnectionHandlerConnection = Connection & Omit<Partial<Edge>, 'source' | 'target'>;

export type ConnectionHandlerAction = (handledConnection?: ConnectionHandlerConnection) => void;

interface ConnectionHandlerParams {
  connection: Connection;
  actionCallback: ConnectionHandlerAction;
}

/**
 * @param conn The originating connection object for react flows.
 * @returns Modified connection object or null.
 * If it is null, the connection will not be made and will attempt to check the opposing handler.
 * If it is 'defer', the connection will not be made and the opposing handler will not be checked and the callConnectionHandler will return null
 */
export type ConnectionHandler = (
  conn: ConnectionHandlerParams['connection'],
  actionCallback: ConnectionHandlerParams['actionCallback'],
) => ConnectionHandlerConnection | null | 'defer';

export interface ConnectionHandlers {
  /** A record of connection handlers for specific handleIds */
  connectionHandlers: Record<string, ConnectionHandler | undefined>;
  /** Calls the connection handler for the given connection */
  callConnectionHandler: (params: ConnectionHandlerParams) => ConnectionHandlerConnection | null;
  /** Runs the specified callback when the given handleId is creating a connection, useful to intercept and run custom logic */
  addConnectionHandler?: (key: HandleKey, handler: ConnectionHandler) => void;
  /** Removes the connection handler for the specific id */
  removeConnectionHandler?: (key: HandleKey) => void;
}

export interface NewNodeTypeProps {
  newNodeType: NodeType | null;
  setNewNodeType: (type: NodeType | null) => void;
}

type EditorContextValue = ConnectionHandlers & NewNodeTypeProps;

export const EditorContext = createContext<EditorContextValue | null>(null);
