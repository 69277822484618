import { Circle as CircleIcon } from '@mui/icons-material';
import { Grid2, MenuItem, Paper, Stack, TextField } from '@mui/material';
import { Color } from './ColorField.consts';
import { ColorFieldProps } from './ColorField.types';
import { ColorIconButton } from './ColorIconButton';

export default function ColorField(props: ColorFieldProps) {
  const { value, onColorClick, ...textFieldProps } = props;

  return (
    <TextField
      {...textFieldProps}
      value={value}
      select
      slotProps={{
        select: {
          renderValue: () => (
            <Stack
              direction="row"
              sx={{
                gap: 1,
              }}
            >
              <CircleIcon htmlColor={value} />
              {value.toUpperCase()}
            </Stack>
          ),
          MenuProps: {
            slotProps: {
              paper: {
                sx: {
                  background: 'transparent',
                  '.MuiMenu-list': {
                    padding: 0,
                  },
                },
              },
            },
          },
        },
      }}
    >
      <MenuItem
        disableRipple
        value={value}
        sx={{
          background: 'none !important',
          padding: 0,
        }}
      >
        <Paper
          elevation={1}
          sx={{
            padding: 1,
            width: 'min-content',
          }}
        >
          <Grid2
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              textAlign: 'center',
            }}
          >
            {Object.values(Color).map((color) => (
              <Grid2 key={color}>
                <ColorIconButton value={value} color={color} onColorClick={onColorClick} />
              </Grid2>
            ))}
          </Grid2>
        </Paper>
      </MenuItem>
    </TextField>
  );
}
