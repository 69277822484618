import { MonksPaletteColor } from '@monksflow/monks-ui';
import { BoxProps, Theme } from '@mui/material';
import { CONNECTION_COLOR_DATA_KEY, ConnectionColorReference } from 'features/Flow/Flow.consts';
import { CSSProperties } from 'react';
import { ConnectingHandle } from 'reactflow';

export function getColorDataFromHandle({ nodeId, type, handleId }: Partial<ConnectingHandle>) {
  const handleElement = document.querySelector(`[data-id='${nodeId}-${handleId}-${type}']`);
  const colorElement = handleElement?.querySelector(`[${CONNECTION_COLOR_DATA_KEY}]`);

  if (!handleElement || !colorElement) return;

  const color = getComputedStyle(colorElement).backgroundColor;
  const reference = getConnectionColorReference(colorElement);

  return {
    color,
    reference,
  };
}

function getConnectionColorReference(element?: Element) {
  const reference = element?.getAttribute(CONNECTION_COLOR_DATA_KEY) as
    | ConnectionColorReference
    | undefined;

  return reference ?? ConnectionColorReference.DEFAULT;
}

export function getPaletteColor(color: BoxProps['color'], theme: Theme) {
  const resolved = theme.unstable_sx({
    color,
  });

  if (isPaletteColor(resolved.color)) {
    return resolved.color;
  }

  return undefined;
}

const properties: (keyof MonksPaletteColor)[] = [
  'contrastText',
  'dark',
  'focus',
  'focusVisible',
  'hover',
  'light',
  'main',
  'outlinedBorder',
  'selected',
];

const isPaletteColor = (obj: unknown): obj is MonksPaletteColor => {
  if (typeof obj !== 'object') return false;
  if (!obj) return false;

  if (properties.some((prop) => !(obj as Record<string, unknown>)[prop])) return false;

  return true;
};

export function getCssValue(value: CSSProperties['width']) {
  return typeof value === 'number' ? `${value}px` : value;
}

export function ignoreTransientProps(propName: PropertyKey) {
  return !(propName as string).startsWith('$');
}
