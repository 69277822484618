import { Box, Grid2, Stack, TypographyProps } from '@mui/material';
import { PipelineJobOutput } from 'api/services/usePipelineExecutionOutputs/usePipelineExecutionOutputs.types';
import CopyButton from 'components/CopyButton/CopyButton';
import DownloadButton from 'components/DownloadButton';
import { SignedAssetObject } from 'features/Flow/nodes/Node/Node.types';
import ImageCard from 'components/ImageCard/ImageCard';
import * as Styled from './ExecutionCompleted.styles';
import { ExecutionOutputGrid } from './ExecutionOutputGrid';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import AssetLoader from '../AssetLoader/AssetLoader';
import { isOpenableAsset } from 'utils/assetObjects';
import ExecutionObjectListView from 'pages/ExecutionsPage/pages/ViewExecutionPage/components/ExecutionObjectListView/ExecutionObjectListView';
import OpenInNewTab from 'components/OpenInNewTab/OpenInNewTab';

function Header({ children }: { children: TypographyProps['children'] }) {
  return (
    <Styled.ContainedLabel variant="labelSmall" color="text.secondary">
      {children}
    </Styled.ContainedLabel>
  );
}

function TextHeader() {
  return (
    <Styled.ContainedLabel variant="labelSmall" color="text.secondary">
      <Grid2 container>
        <Grid2 size={3}>Data Label</Grid2>
        <Grid2
          sx={{
            display: 'flex',
            justifyContent: 'end',
            marginRight: 5.5,
          }}
          size="grow"
        >
          Value(s)
        </Grid2>
      </Grid2>
    </Styled.ContainedLabel>
  );
}

interface ExecutionOutputByTypeProps {
  output: PipelineJobOutput;
}

export default function ExecutionOutputByType({ output }: ExecutionOutputByTypeProps) {
  const { dataSchema } = output;
  const title = output.title ? output.title : output.name;
  const value = output.value;
  if (value == null) return null;

  // TODO we may want to distribute them across the different DataTypes
  switch (dataSchema.type) {
    case 'array': {
      switch (dataSchema.items.type) {
        case 'image-uri': {
          const values = value as string[];
          return (
            <>
              <Header>{title}</Header>
              <Styled.ImageLayout>
                {values.map((val, index) => (
                  <ImageCard
                    key={`${val}-${index}`}
                    imageUrl={val}
                    alt={''}
                    actions={
                      <>
                        <DownloadButton href={val} fileType="jpg" />
                        <OpenInNewTab href={val} linkText="Open URL" />
                      </>
                    }
                  />
                ))}
              </Styled.ImageLayout>
            </>
          );
        }

        case 'text':
        case 'number':
        case 'integer': {
          return (
            <>
              <TextHeader />
              <ExecutionOutputGrid
                title={title}
                values={(value as string[] | number[] | undefined) ?? []}
              />
            </>
          );
        }

        case 'asset': {
          const assets = value as SignedAssetObject[];
          return (
            <>
              <Header>{title}</Header>
              <Styled.ImageLayout>
                {assets.map((asset) => (
                  <AssetLoader
                    key={asset.pathwayAssetId}
                    asset={asset}
                    leftAction={<DownloadButton asset={asset} />}
                    rightAction={
                      isOpenableAsset(asset) && (
                        <OpenInNewTab href={asset.signedUrl} linkText="Open URL" />
                      )
                    }
                  />
                ))}
              </Styled.ImageLayout>
            </>
          );
        }
        case 'object': {
          const objects = value as Record<string, unknown>[];
          return <ExecutionObjectListView title={title} data={objects} />;
        }
        default: {
          return <></>;
        }
      }
    }
    case 'image-uri': {
      const url = value as string;
      return (
        <>
          <Header>{title}</Header>
          <Styled.ImageLayout>
            <ImageCard
              imageUrl={url}
              alt={''}
              actions={
                <>
                  <DownloadButton href={url} fileType="jpg" />
                  <OpenInNewTab href={url} linkText="Open URL" />
                </>
              }
            />
          </Styled.ImageLayout>
        </>
      );
    }
    case 'asset': {
      const asset = value as SignedAssetObject;
      return (
        <>
          <Header>{title}</Header>
          <Box sx={{ mt: 2, mb: 2 }}>
            <AssetLoader
              key={asset.pathwayAssetId}
              asset={asset}
              leftAction={<DownloadButton asset={asset} />}
              rightAction={
                isOpenableAsset(asset) && (
                  <OpenInNewTab href={asset.signedUrl} linkText="Open URL" />
                )
              }
            />
          </Box>
        </>
      );
    }
    case 'object': {
      return (
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <Header>
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {title}

              <CopyButton
                value={JSON.stringify(value as object, null, 2)}
                sx={{
                  marginY: -1,
                  marginRight: 0,
                }}
              />
            </Stack>
          </Header>
          <JsonEditor content={{ json: value }} readOnly />
        </Stack>
      );
    }
    case 'boolean':
      return (
        <>
          <TextHeader />
          <ExecutionOutputGrid title={title} values={[(value as boolean) ? 'True' : 'False']} />
        </>
      );
    case 'integer':
    case 'number':
    case 'text':
    case 'enum':
    default: {
      return (
        <>
          <TextHeader />
          <ExecutionOutputGrid title={title} values={[value as string]} />
        </>
      );
    }
  }
}
