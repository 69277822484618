import { Paper, PaperProps, TypographyProps } from '@mui/material';
import * as Styled from './HeaderChip.styles';

export interface HeaderChipProps extends TypographyProps, Pick<PaperProps, 'elevation'> {
  $noSpacingLeft?: boolean;
  $noSpacingRight?: boolean;
}

export default function HeaderChip({ children, ...props }: HeaderChipProps) {
  return (
    <Styled.Container
      color="text.secondary"
      variant="bodySmall"
      elevation={2}
      {...props}
      component={Paper}
    >
      {children}
    </Styled.Container>
  );
}
