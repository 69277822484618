import { MenuItem, Table, TableBody, TableCell, Typography } from '@mui/material';
import ActionsMenu from 'components/ActionsMenu/ActionsMenu';
import Loader from 'components/Loader/Loader';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { MouseEvent, useMemo } from 'react';
import PipelineErrorCard from '../PipelineErrorCard/PipelineErrorCard';
import { COLUMN_CONFIG_ARRAY, ColumnId } from './PipelineTable.consts';
import { Pipeline } from './PipelineTable.types';
import * as Styled from 'components/Table/Table.styles';
import CustomTableHead from 'components/Table/TableHead';
import { formatDate } from 'utils/string';
import PaginationControls from 'components/PaginationControls/PaginationControls';
import usePaginatedPipelines from 'api/services/usePaginatedPipelines/usePaginatedPipelines';
import { IndicatorCell } from './Cells/IndicatorCell';

const SKELETON_WIDTH = 120;

export interface PipelineTableProps {
  loading: boolean;
  loadingFailed: boolean;
  pipelines: Pipeline[];
  pagination: ReturnType<typeof usePaginatedPipelines>['pagination'];
  onRowClick: (pipelineId: Pipeline['id']) => void;
  onEdit: (pipelineId: Pipeline['id']) => void;
  onDuplicate: (pipeline: Pipeline) => void;
  onDelete: (pipelineId: Pipeline['id']) => void;
  onPageChange: (pageNumber: number) => void;
}

export default function PipelineTable(props: PipelineTableProps) {
  const { loading, loadingFailed, pipelines, pagination } = props;
  const canClickRow = !loading;

  const displayError = loadingFailed;
  const disableHeader = loading || displayError;
  const displayRows = !displayError;
  const displayActionsMenu = !loading;

  const rows = useMemo(() => {
    if (loading) {
      return Array<Pipeline>(5).fill({} as Pipeline);
    }

    return pipelines;
  }, [loading, pipelines]);

  const createRowClickHandler = (pipelineId: Pipeline['id']) =>
    canClickRow
      ? (event: MouseEvent) => {
          const target = event.target as HTMLElement;
          const isButtonTarget = !!target.closest('button');
          const isMenuTarget = !!target.closest('.MuiMenu-root');
          const ignoreEvent = isButtonTarget || isMenuTarget;

          if (ignoreEvent) return;

          props.onRowClick(pipelineId);
        }
      : undefined;

  return (
    <>
      <Styled.TableContainer>
        <Table aria-label="pipelines" sx={{ minWidth: 880 }}>
          <CustomTableHead
            disabled={disableHeader}
            columns={COLUMN_CONFIG_ARRAY}
            order={pagination.orderBy}
            orderBy={pagination.sortBy}
            onSort={(columnId) => {
              pagination.updateSort(columnId as ColumnId);
            }}
          />

          <TableBody>
            {displayRows &&
              rows.map((pipeline, index) => {
                return (
                  <Styled.TableBodyRow
                    hover={!loading}
                    key={pipeline.id || index}
                    onClick={createRowClickHandler(pipeline.id)}
                  >
                    <TableCell>
                      <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                        <TextEllipsis color="text.primary" maxWidth="20vw">
                          {pipeline.name}
                        </TextEllipsis>
                      </Loader>
                    </TableCell>

                    <TableCell>
                      <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                        {pipeline.createdByUsername}
                      </Loader>
                    </TableCell>

                    <TableCell>
                      <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                        {formatDate(pipeline.updatedAt)}
                      </Loader>
                    </TableCell>

                    <TableCell>
                      <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                        {formatDate(pipeline.mostRecentlyStartedExecutionDate ?? undefined)}
                      </Loader>
                    </TableCell>

                    <TableCell>
                      <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                        <IndicatorCell
                          subpipeline={pipeline.type === 'callable'}
                          triggers={pipeline.externalTriggerCount}
                        />
                      </Loader>
                    </TableCell>

                    <TableCell align="right">
                      <Loader variant="text" loading={loading} width="100%">
                        <Typography
                          variant="labelLarge"
                          sx={{
                            color: 'text.primary',
                          }}
                        >
                          {pipeline.executionCount}
                        </Typography>
                      </Loader>
                    </TableCell>

                    <TableCell align="right">
                      <Loader variant="text" loading={loading} width="100%">
                        <ReviewCount value={pipeline.pendingReviewCount} />
                      </Loader>
                    </TableCell>

                    <TableCell align="right">
                      <Loader variant="text" loading={loading} width="100%">
                        <FailedCount value={pipeline.failedCount} />
                      </Loader>
                    </TableCell>

                    <TableCell align="center">
                      {displayActionsMenu && (
                        <ActionsMenu
                          renderActions={() => [
                            <MenuItem
                              key="edit"
                              onClick={() => {
                                props.onEdit(pipeline.id);
                              }}
                            >
                              Edit
                            </MenuItem>,
                            <MenuItem
                              key="duplicate"
                              onClick={() => {
                                props.onDuplicate(pipeline);
                              }}
                            >
                              Duplicate
                            </MenuItem>,
                            <MenuItem
                              key="delete"
                              $dividerTop
                              onClick={() => {
                                props.onDelete(pipeline.id);
                              }}
                            >
                              Delete
                            </MenuItem>,
                          ]}
                        />
                      )}
                    </TableCell>
                  </Styled.TableBodyRow>
                );
              })}

            {displayError && (
              <Styled.TableBodyRow>
                <TableCell colSpan={10}>
                  <PipelineErrorCard />
                </TableCell>
              </Styled.TableBodyRow>
            )}
          </TableBody>
        </Table>
      </Styled.TableContainer>
      <PaginationControls
        page={pagination.pageNumber + 1}
        totalCount={pagination.totalCount}
        pageSize={pagination.pageSize}
        onPageChange={props.onPageChange}
      />
    </>
  );
}

function ReviewCount({ value }: { value: number }) {
  return value > 0 ? (
    <Typography
      variant="labelLarge"
      sx={{
        color: 'warning.main',
      }}
    >
      {value}
    </Typography>
  ) : (
    '–'
  );
}

function FailedCount({ value }: { value: number }) {
  return value > 0 ? (
    <Typography
      variant="labelLarge"
      sx={{
        color: 'error.main',
      }}
    >
      {value}
    </Typography>
  ) : (
    '–'
  );
}
