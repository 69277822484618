import {
  ArrayDataSchema,
  AssetDataSchema,
  DataSchema,
  isArrayDataSchema,
  isAssetDataSchema,
  isEnumDataSchema,
} from '@pathways/pipeline-schema/web';
import { ALL_DATA_TYPE_OPTIONS } from 'features/Flow/components/DataTypeField/DataTypeField.consts';
import { formatRequiredMessage } from './message';

export const unsetDataSchema = <T extends DataSchema = DataSchema>(dataSchema: T): T => {
  if (isEnumDataSchema(dataSchema)) {
    return {
      ...dataSchema,
      values: [],
    };
  }

  if (isAssetDataSchema(dataSchema)) {
    return {
      ...dataSchema,
      mediaType: [],
    };
  }

  if (isArrayDataSchema<AssetDataSchema>(dataSchema, 'asset')) {
    return {
      ...dataSchema,
      items: {
        ...dataSchema.items,
        type: 'asset',
        mediaType: [],
      },
    };
  }

  return dataSchema;
};

export interface DataMenuItem {
  schema: DataSchema;
  title: string;
}

export function getDataMenuItemValue(schema?: DataSchema) {
  if (!schema) return '';
  return isArrayDataSchema(schema) ? schema.items.type ?? schema.type : schema.type;
}

export function getSchemaByDataType(
  dataType: string,
  arrayDataType?: ArrayDataSchema['items']['type'],
  mediaType?: AssetDataSchema['mediaType'],
) {
  const key = arrayDataType ? `array-${arrayDataType}` : dataType;
  const dataSchema = ALL_DATA_TYPE_OPTIONS.find((option) => option.value === key)?.schema;

  if (!dataSchema) {
    throw new Error(formatRequiredMessage('dataSchema'));
  }

  if (mediaType) {
    if (isArrayDataSchema(dataSchema) && dataSchema.items.type === 'asset') {
      dataSchema.items.mediaType = mediaType;
    }
    if (dataSchema.type === 'asset') {
      dataSchema.mediaType = mediaType;
    }
  }

  return dataSchema;
}
