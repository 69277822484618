import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Stack,
  CircularProgress,
} from '@mui/material';
import usePipelineExecutionLogs from 'api/services/usePipelineExecutionLogs/usePipelineExecutionLogs';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import React, { useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useTheme } from 'styled-components';

const EmptyPlaceholder = () => {
  const [showMsg, setShowMsg] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMsg(true);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [setShowMsg]);

  return (
    <Stack alignItems={'center'} justifyContent={'center'} height={'100%'}>
      <CircularProgress variant="indeterminate" sx={{ mb: 2, width: 150 }} />

      {showMsg && (
        <>
          <Typography variant="bodyMedium" textAlign={'center'} mx={4}>
            Execution logs are only available for 7 days.
          </Typography>
          <Typography variant="bodyMedium" textAlign={'center'} mx={4}>
            If they are not present, the logs may have expired and cannot be retrieved.
          </Typography>
        </>
      )}
    </Stack>
  );
};

interface ViewExecutionLogsProps {
  executionId: string;
}

export const ViewExecutionLogs = React.memo(function ViewLogs({
  executionId,
}: ViewExecutionLogsProps) {
  const theme = useTheme();
  const { pipelineExecutionLogs } = usePipelineExecutionLogs(executionId);

  return (
    <Virtuoso
      data={pipelineExecutionLogs}
      components={{ EmptyPlaceholder }}
      itemContent={(i, log) => {
        return (
          <Accordion
            disableGutters
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              '&.MuiPaper-root': { borderRadius: 0 },
            }}
          >
            <AccordionSummary
              sx={{ background: theme.palette.surface.level1 }}
              expandIcon={<ExpandMore />}
            >
              <TextEllipsis
                variant="caption"
                title={String(i)}
                sx={{
                  minWidth: 32,
                  maxWidth: 32,
                  mr: 1,
                }}
              >
                {i}
              </TextEllipsis>

              <Typography
                variant="bodyMedium"
                sx={{ color: log.level === 'error' ? 'red' : undefined }}
              >
                {log.message}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <JsonEditor readOnly content={log} />
            </AccordionDetails>
          </Accordion>
        );
      }}
    />
  );
});
