import { Add, Podcasts, Warning } from '@mui/icons-material';
import { Box, Button, Stack, Table, TableBody, TableCell, Typography } from '@mui/material';
import { PipelineTriggerResponse } from 'api/services/usePipelineTriggers/usePipelineTriggers.types';
import Loader from 'components/Loader/Loader';
import * as Styled from 'components/Table/Table.styles';
import CustomTableHead from 'components/Table/TableHead';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { useMemo, useState } from 'react';
import { Order, getComparator } from 'utils/order';
import { formatDate } from 'utils/string';
import { COLUMN_CONFIG, COLUMN_CONFIG_ARRAY, COLUMN_IDS, ColumnId } from './TriggersTable.consts';

export interface TriggersTableProps {
  loading: boolean;
  loadingFailed: boolean;
  triggers: PipelineTriggerResponse[];
  onCreateTriggerClick: () => void;
  onRowClick: (triggerId: PipelineTriggerResponse['id']) => void;
}

export default function TriggersTable(props: TriggersTableProps) {
  const { loading, loadingFailed: displayError, triggers } = props;
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<ColumnId>(COLUMN_IDS.CREATED);

  const canClickRow = !loading;
  const disableHeader: boolean = loading || displayError;
  const displayEmpty: boolean = !loading && !displayError && triggers.length === 0;
  const displayMessage: boolean = displayError || displayEmpty;
  const displayTable = !displayMessage;

  const rows = useMemo(() => {
    if (loading) {
      return Array.from(
        { length: 5 },
        (_, index) => ({ id: String(index) }) as PipelineTriggerResponse,
      );
    }

    const orderByProperty = COLUMN_CONFIG[orderBy]?.orderProperty;

    if (!orderByProperty) return triggers;

    return triggers.slice().sort(getComparator(order, orderByProperty));
  }, [loading, order, orderBy, triggers]);

  const handleSort = (columnId: ColumnId) => {
    const isAsc = orderBy === columnId && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const handleRowClick = (id: PipelineTriggerResponse['id']) => {
    if (!canClickRow) return;
    props.onRowClick(id);
  };

  return (
    <Box data-testid="triggers-table-box">
      <Styled.TableToolbar>
        <Typography
          variant="titleMedium"
          sx={{
            display: 'inline-flex',
            gap: 1,
          }}
        >
          <Podcasts />
          API Triggers
        </Typography>

        <Button variant="outlined" startIcon={<Add />} onClick={props.onCreateTriggerClick}>
          Create trigger
        </Button>
      </Styled.TableToolbar>

      {displayTable && (
        <Styled.TableContainer sx={{ maxWidth: '95vw' }}>
          <Table
            aria-label="Table of pipeline triggers"
            sx={{
              minWidth: 880,
            }}
          >
            <CustomTableHead
              disabled={disableHeader}
              columns={COLUMN_CONFIG_ARRAY}
              order={order}
              orderBy={orderBy}
              onSort={(columnId) => {
                handleSort(columnId as ColumnId);
              }}
            />

            <TableBody>
              {rows.map((trigger) => {
                return (
                  <Styled.TableBodyRow
                    key={trigger.id}
                    hover={!loading}
                    onClick={() => {
                      handleRowClick(trigger.id);
                    }}
                  >
                    <TableCell>
                      <Loader variant="text" loading={loading} width={372}>
                        <TextEllipsis variant="titleSmall" color="text.primary" width={372}>
                          {trigger.name}
                        </TextEllipsis>
                      </Loader>
                    </TableCell>
                    <TableCell>
                      <Loader variant="text" loading={loading} width={572}>
                        <TextEllipsis variant="bodyMedium" width={572}>
                          {trigger.notes}
                        </TextEllipsis>
                      </Loader>
                    </TableCell>
                    <TableCell>
                      <Loader variant="text" loading={loading} width={200}>
                        <TextEllipsis variant="bodyMedium" width={200}>
                          {formatDate(trigger.createdAt)}
                        </TextEllipsis>
                      </Loader>
                    </TableCell>
                  </Styled.TableBodyRow>
                );
              })}
            </TableBody>
          </Table>
        </Styled.TableContainer>
      )}

      {displayMessage && (
        <Styled.TableMessage>
          {displayEmpty && (
            <Typography variant="bodyLarge" color="textSecondary">
              No API triggers created
            </Typography>
          )}
          {displayError && (
            <Stack direction="row" spacing={1}>
              <Warning color="error" />
              <Typography variant="bodyLarge">
                An error occurred loading the API triggers.
              </Typography>
            </Stack>
          )}
        </Styled.TableMessage>
      )}
    </Box>
  );
}
