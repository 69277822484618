import * as Styled from './Node.styles';
import { PathwayNodeProps } from './Node.types';
import { NodeHeader } from './components/NodeHeader';
import { NodeDataSection } from './components/NodeDataSection';
import { useGetNodeExecution } from 'features/Flow/hooks/useGetNodeExecution';
import { NodeVariation } from './components/NodeVariation/index';
import { NodeVariationProps } from './components/NodeVariation/Variation.types';
import { useStore } from 'reactflow';
import { FlowState } from 'types/reactflow';

const selector = ({ mode }: FlowState) => ({
  mode,
});

const Node: React.FC<PathwayNodeProps> = (props) => {
  const { data } = props;
  const { mode } = useStore(selector);
  const { execution } = useGetNodeExecution(props);

  return (
    <Styled.NodeContainer
      data-node-id={props.id}
      $nodeType={data.metadata.type}
      $isSelected={props.selected}
      $status={execution?.status}
    >
      <NodeHeader execution={execution} metadata={data.metadata} />
      {data.schemas?.variations && (
        <NodeVariation
          key={props.id}
          nodeId={props.id}
          selectedVariation={data.metadata.variation}
          schemas={data.schemas as NodeVariationProps['schemas']}
          inputs={data.inputs}
          outputs={data.outputs}
          disabled={mode === 'runtime'}
        />
      )}
      <NodeDataSection nodeId={props.id} inputs={data.inputs} outputs={data.outputs} />
    </Styled.NodeContainer>
  );
};

export default Node;
