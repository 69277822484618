import { isBatchDetailedFailure } from '../features/Flow/nodes/Batch/Batch.types';

/** @example
 * if (!pipelineId) throw new Error(formatRequiredMessage('pipelineId'));
 */
export function formatRequiredMessage(name: string) {
  return `"${name}" is required.`;
}

/** @example
 * if (!pipelineId) throw new Error(formatUrlMissingParameterMessage('pipelineId'));
 */
export function formatUrlMissingParameterMessage(parameterName: string) {
  return `Url is missing "${parameterName}" parameter.`;
}

export function getNodeErrorMessage(
  error: string | undefined,
  errors: unknown,
): string | undefined {
  if (isBatchDetailedFailure(errors)) {
    return errors.details[0].failure?.message ?? errors.message ?? error;
  }
  return error;
}
