import { useParams } from 'react-router-dom';
import usePipelineInputs from 'api/services/usePipelineInputs/usePipelineInputs';
import PipelineExecutionForm from './components/PipelineExecutionForm/PipelineExecutionForm';
import usePipelineVersions from 'api/services/usePipelineVersions/usePipelineVersions';
import { useEffect, useMemo, useState } from 'react';
import { MuiContainer, ContentPaper } from 'components/Layout/Layout.styles';
import { Box, Typography } from '@mui/material';
import VersionSelect from 'components/VersionSelect/VersionSelect';
import PipelineExecutionFormLoader from './components/PipelineExecutionFormLoader/PipelineExecutionFormLoader';

const NewExecutionPage = () => {
  const { pipelineId } = useParams();

  if (!pipelineId) {
    throw new Error('pipelineId is required');
  }

  const [selectedVersion, setSelectedVersion] = useState<string | null>(null);
  const { pipelineVersionsData } = usePipelineVersions(pipelineId);
  const { arePipelineInputsLoading, pipelineInputs } = usePipelineInputs({
    pipelineId,
    versionId: selectedVersion ?? undefined,
    config: {
      shouldFetch: !!selectedVersion,
    },
  });

  const availableVersions = useMemo(() => {
    if (!pipelineVersionsData) return [];
    return pipelineVersionsData.items.slice(0, 2);
  }, [pipelineVersionsData]);

  useEffect(() => {
    if (!availableVersions.length) return;
    setSelectedVersion(availableVersions[0].id);
  }, [availableVersions]);

  return (
    <MuiContainer>
      <ContentPaper
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
      >
        <Typography variant="titleLarge" component="h1">
          Start New Run
        </Typography>
        {selectedVersion && (
          <VersionSelect
            selectedId={selectedVersion}
            versions={availableVersions}
            onChange={(versionId: string) => {
              setSelectedVersion(versionId);
            }}
          />
        )}
      </ContentPaper>
      <Box
        sx={{
          mt: 3,
        }}
      >
        {arePipelineInputsLoading || !selectedVersion ? (
          <ContentPaper>
            <PipelineExecutionFormLoader quantity={8} />
          </ContentPaper>
        ) : (
          <PipelineExecutionForm
            pipelineId={pipelineId}
            versionId={selectedVersion}
            pipelineInputs={pipelineInputs}
          />
        )}
      </Box>
    </MuiContainer>
  );
};

export default NewExecutionPage;
