import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { RemoveCircleOutline } from '@mui/icons-material';
import { Box, IconButton, Stack, TextField, Tooltip } from '@mui/material';
import DragIndicatorHandle from 'components/DragIndicatorHandle/DragIndicatorHandle';
import { SectionDivider } from 'components/Sidebar/Sidebar.styles';
import {
  createArrayDataTypeOptions,
  createDataTypeOptions,
} from 'features/Flow/components/DataTypeField/DataTypeField.utils';
import { useDataTypeField } from 'features/Flow/components/DataTypeField/useDataTypeField';
import { DynamicCheckpointNode } from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/DynamicCheckpoint.types';
import {
  TableSchemaColumnConfig,
  useTableSchemaInput,
} from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/tableSchemaInput';

interface TableSchemaFormRowProps {
  column: TableSchemaColumnConfig;
  hasDuplicatedKey: boolean;
  node: DynamicCheckpointNode;
}

export function TableSchemaFormRow(props: TableSchemaFormRowProps) {
  const { column, hasDuplicatedKey, node } = props;

  const {
    attributes,
    isSorting,
    listeners,
    transform,
    transition,
    setNodeRef,
    setActivatorNodeRef,
  } = useSortable({
    id: column.uniqueKey,
  });
  const dragStyles = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const dragHandleTooltip = isSorting ? undefined : 'Drag to reorder';

  const { dataTypeValue, arrayDataTypeValue, getDataTypeFieldOptionSchema } = useDataTypeField({
    value: column.valueSchema,
  });

  const { tableSchemaInput, updateTableSchemaInput } = useTableSchemaInput({ node });

  const updateColumn = (updates: Partial<TableSchemaColumnConfig>) => {
    const nextValue = tableSchemaInput?.value.map((current) => {
      if (current.uniqueKey === column.uniqueKey) {
        return {
          ...column,
          ...updates,
        };
      }
      return current;
    });

    updateTableSchemaInput(nextValue);
  };

  const removeColumn = () => {
    const nextValue = tableSchemaInput?.value.filter(
      (current) => current.uniqueKey !== column.uniqueKey,
    );

    updateTableSchemaInput(nextValue);
  };

  const handleDataTypeChange = (event: React.ChangeEvent<{ value: string }>) => {
    const optionSchema = getDataTypeFieldOptionSchema(event.target.value);

    updateColumn({
      valueSchema: optionSchema,
    });
  };

  return (
    <div ref={setNodeRef} style={dragStyles} {...attributes}>
      <Stack
        sx={{
          padding: 2,
          gap: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
            }}
          >
            <Tooltip ref={setActivatorNodeRef} title={dragHandleTooltip}>
              <DragIndicatorHandle {...listeners} $isDragging={isSorting} />
            </Tooltip>
          </Box>

          <Tooltip title="Remove">
            <IconButton data-testid="RemoveColumn" onClick={removeColumn}>
              <RemoveCircleOutline />
            </IconButton>
          </Tooltip>
        </Stack>

        <TextField
          name="key"
          label="Key"
          error={hasDuplicatedKey}
          fullWidth
          helperText={hasDuplicatedKey ? 'Key must be unique' : undefined}
          required
          size="small"
          value={column.id}
          onChange={(event) => {
            updateColumn({
              id: event.target.value,
              label: event.target.value,
            });
          }}
        />

        <TextField
          select
          name="dataType"
          label="Data Type"
          value={dataTypeValue}
          onChange={handleDataTypeChange}
        >
          {createDataTypeOptions()}
        </TextField>

        {dataTypeValue.startsWith('array') && (
          <TextField
            select
            name="arrayDataType"
            label="Array Data Type"
            value={arrayDataTypeValue}
            onChange={handleDataTypeChange}
          >
            {createArrayDataTypeOptions()}
          </TextField>
        )}
      </Stack>

      <SectionDivider $addMargin={false} />
    </div>
  );
}
