import { IconButton, IconButtonProps, Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

export default function DisconnectedProviderIndicator(props: IconButtonProps) {
  return (
    <Tooltip title="Disconnected Provider">
      <IconButton size="small" {...props}>
        <WarningIcon color="warning" />
      </IconButton>
    </Tooltip>
  );
}
