import { NodeOutput } from '../../../Node/Node.types';
import { Button, Grid2 } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { NodeOutputFormData } from '../ObjectExtractor.types';
import ObjectExtractorForm from './ObjectExtractorForm';
import { useCallback } from 'react';
import { createHandleId } from 'features/Flow/Flow.utils';
import { useFindHandleEdge } from 'features/Flow/hooks/useFindHandleEdge';
import * as Styled from 'components/Dialog/Dialog.styles';
import { createExtractorDataSchema } from '../ObjectExtractor.utils';
import { isArrayDataSchema } from '@pathways/pipeline-schema/web';

interface ObjectExtractorEditFormProps {
  nodeId: string;
  outputToEdit: NodeOutput;
  pathToEdit: string;
  onCancelEdit: () => void;
  onConfirmEdit: (output: NodeOutput, path: string) => void;
}

const createDefaultValue = (edit: NodeOutput, path: string): NodeOutputFormData => {
  return {
    path,
    title: edit.title,
    dataType: edit.dataSchema.type,
    arrayDataType: isArrayDataSchema(edit.dataSchema) ? edit.dataSchema.items.type : undefined,
  };
};

const ObjectExtractorEditForm: React.FC<ObjectExtractorEditFormProps> = ({
  nodeId,
  outputToEdit,
  pathToEdit,
  onCancelEdit,
  onConfirmEdit,
}) => {
  const findEdge = useFindHandleEdge({ nodeId });
  const form = useForm<NodeOutputFormData>({
    defaultValues: createDefaultValue(outputToEdit, pathToEdit),
    mode: 'onBlur',
  });

  const submitForm: SubmitHandler<NodeOutputFormData> = useCallback(
    (data) => {
      onConfirmEdit(
        { ...outputToEdit, ...data, dataSchema: createExtractorDataSchema(data) },
        data.path,
      );
    },
    [onConfirmEdit, outputToEdit],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  return (
    <FormProvider {...form}>
      <Styled.DialogTitle $hasBorder={true}>Edit Output</Styled.DialogTitle>
      <Styled.DialogContent>
        <ObjectExtractorForm
          control={form.control}
          isConnected={!!findEdge(createHandleId('output', outputToEdit.name))}
        />
      </Styled.DialogContent>
      <Styled.DialogActions $hasBorder={true}>
        <Grid2 container>
          <Button variant="text" sx={{ color: 'common.white' }} onClick={onCancelEdit}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={!form.formState.isValid}>
            Confirm
          </Button>
        </Grid2>
      </Styled.DialogActions>
    </FormProvider>
  );
};

export default ObjectExtractorEditForm;
