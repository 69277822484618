import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';

export interface DeleteTriggerDialogProps extends Pick<DialogProps, 'open'> {
  onClose: () => void;
  onConfirm: () => void;
  isDeleting: boolean;
}

export default function DeleteTriggerDialog(props: DeleteTriggerDialogProps) {
  const titleId = 'delete-pipeline-trigger-title';

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby={titleId}
      PaperProps={{
        elevation: 24,
      }}
    >
      <DialogTitle id={titleId}>Delete Trigger?</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you&apos;d like to delete this trigger?</DialogContentText>
        <DialogContentText mt={2}>
          Deleting this API trigger is a permanent action and cannot be undone. All associated
          integrations and functionality will be disrupted, so please ensure all dependent services
          are updated as needed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="white" onClick={props.onClose}>
          Cancel
        </Button>
        <Button color="error" onClick={props.onConfirm} loading={props.isDeleting}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
