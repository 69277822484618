import { FormControlLabel, Stack, Typography } from '@mui/material';
import RoundedPaper from 'components/RoundedPaper/RoundedPaper';
import PipelineIcon from 'components/Icons/Pipeline';
import usePipelineId from 'hooks/usePipelineId';
import SubpipelineSwitch from '../SubpipelineSwitch/SubpipelineSwitch';

export default function SubpipelineSection() {
  const pipelineId = usePipelineId();

  return (
    <RoundedPaper
      data-testid="subpipeline-section"
      sx={(theme) => ({
        padding: theme.spacing(2, 3),
      })}
    >
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          paddingBottom: 1,
        }}
      >
        <Typography
          variant="titleMedium"
          sx={{
            display: 'inline-flex',
            gap: 1,
          }}
        >
          <PipelineIcon />
          Subpipeline Capable
        </Typography>

        <FormControlLabel
          control={<SubpipelineSwitch pipelineId={pipelineId} />}
          label="Allow nesting"
          sx={{
            margin: 0,
          }}
        />
      </Stack>

      <Typography
        component="p"
        variant="bodyMedium"
        sx={{
          width: 800,
        }}
      >
        If this setting is turned on, other pipelines in this workspace will be able to call this
        pipeline from within their own process. Pipelines will call the most recently published
        version of this pipeline at the time of their creation. Note that disabling this setting
        will NOT block this pipeline from being called by pipelines that already do so.
      </Typography>
    </RoundedPaper>
  );
}
