import { NodeManifest, NodeType } from 'features/Flow/Flow.types';
import { useFeatureFlag } from 'features/FeatureFlag/useFeatureFlag';
import SidebarElementCard from './SidebarElementCard/SidebarElementCard';
import * as Styled from '../AddElementSidebar.styles';
import { batchNode, dummyNode } from '../AddElementSidebar.const';
import { JobNodeType } from 'types/reactflow';
import useManifests from 'api/services/useManifests/useManifests';
import ElementsLoader from 'features/Flow/components/AddElementSidebar/components/ElementsLoader/ElementsLoader';
import useWorkspace from 'hooks/useWorkspace';

interface AddElementNodeListProps {
  searchVal: string;
  nodeType?: JobNodeType;
  onDragStart: (e: React.DragEvent, manifest: NodeManifest) => void;
}

export default function AddElementNodeList({
  searchVal,
  nodeType,
  onDragStart,
}: AddElementNodeListProps) {
  const workspace = useWorkspace();
  const { areManifestsLoading, manifests } = useManifests(workspace.id);
  const isDraftEditorEnabled = useFeatureFlag('draftEditor');

  if (areManifestsLoading) {
    return <ElementsLoader quantity={5} />;
  }

  const filterManifests = (manifests: NodeManifest[]) => {
    let filteredManifests = manifests.filter((manifest) => manifest.type === nodeType);
    const value = searchVal.toLowerCase();

    if (nodeType === NodeType.FUNCTION) {
      filteredManifests.push(batchNode);
    }

    if (nodeType === NodeType.NEURON) {
      isDraftEditorEnabled && filteredManifests.push(dummyNode);
    }

    if (searchVal.length >= 3)
      filteredManifests = filteredManifests.filter((manifest) => {
        return (
          manifest.name.toLowerCase().includes(value) ||
          manifest.description.toLowerCase().includes(value) ||
          manifest.tags?.some((tag) => tag.toLowerCase().includes(value))
        );
      });

    return filteredManifests;
  };

  const elementsToDisplay = filterManifests(manifests);

  return elementsToDisplay.length > 0 ? (
    elementsToDisplay.map((manifest) => (
      <SidebarElementCard
        key={`${manifest.type}:${manifest.key}`}
        manifest={manifest}
        onDragStart={(e) => {
          onDragStart(e, manifest);
        }}
      />
    ))
  ) : (
    <Styled.NoResultText variant="bodyMedium">{`No results for '${searchVal}'`}</Styled.NoResultText>
  );
}
