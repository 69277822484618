import PlatformApiSdk from '@monksflow/platform-api-sdk';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import theme from 'config/theme';
import AppAuthProvider from 'contexts/auth/AppAuthProvider';
import { ToastProvider } from 'contexts/toast/ToastProvider';
import FeatureFlagProvider from 'features/FeatureFlag/FeatureFlagProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './instrumentation';

const root = document.getElementById('root');

if (!root) {
  throw new Error('No element with id "root" found.');
}

PlatformApiSdk.init({
  platformApiUrl: import.meta.env.MONKS_FLOW_PLATFORM_API_URL,
  platformAppUrl: import.meta.env.MONKS_FLOW_PLATFORM_HOME_URL,
  withAuthInterceptor: !import.meta.env.USE_LOCAL_AUTH,
});

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    {/* Material UI theme provider is required to apply the theme custom default props for components */}
    <MuiThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <AppAuthProvider>
        <FeatureFlagProvider>
          <ToastProvider>
            <App />
          </ToastProvider>
        </FeatureFlagProvider>
      </AppAuthProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
);
