import { Button } from '@mui/material';
import { PipelineTriggerResponse } from 'api/services/usePipelineTriggers/usePipelineTriggers.types';
import { useState } from 'react';
import DeleteTriggerDialog from '../DeleteTriggerDialog/DeleteTriggerDialog';
import useDeletePipelineTrigger from 'api/services/useDeletePipelineTrigger/useDeletePipelineTrigger';
import useToast from 'contexts/toast/useToast';
import { useNavigate } from 'react-router-dom';
import { useAppRoutes } from 'utils/routes';

export interface DeleteSectionProps {
  trigger: PipelineTriggerResponse;
  pipelineId: string;
}

export default function DeleteSection(props: DeleteSectionProps) {
  const { trigger, pipelineId } = props;

  const { deletePipelineTrigger, isDeletingTrigger } = useDeletePipelineTrigger();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const toast = useToast();
  const routes = useAppRoutes();
  const navigate = useNavigate();

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const deleteTrigger = async () => {
    try {
      await deletePipelineTrigger({ pipelineId, triggerId: trigger.id });
      toast.success({
        message: `Trigger "${trigger.name}" deleted successfully.`,
      });
      navigate(routes.settings(pipelineId));
    } catch (_) {
      toast.error({
        message: 'An error occurred while deleting the trigger.',
      });
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="error"
        onClick={() => {
          setIsDeleteModalOpen(true);
        }}
      >
        Delete API trigger
      </Button>

      <DeleteTriggerDialog
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={deleteTrigger}
        isDeleting={isDeletingTrigger}
      />
    </div>
  );
}
