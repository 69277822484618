import Toast from 'components/Toast/Toast';
import { ReactNode } from 'react';
import { ToastContext } from './ToastContext';
import useToastState from './useToastState';

interface ToastProviderProps {
  children: ReactNode;
}

export function ToastProvider({ children }: ToastProviderProps) {
  const [handlers, state] = useToastState();

  return (
    <ToastContext.Provider value={handlers}>
      {children}
      <Toast {...state} />
    </ToastContext.Provider>
  );
}
