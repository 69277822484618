import { useCallback, useState } from 'react';
import { removeByKey } from 'utils/objects';
import { ConnectionHandler, ConnectionHandlers, EditorContext } from './EditorContext';
import { NodeType } from './Flow.types';
import { computeHandleKey, createHandleKey } from './Handles/handle.store';

export const EditorContextProvider = ({ children }: React.PropsWithChildren) => {
  const [newNodeType, setNewNodeType] = useState<NodeType | null>(null);
  const [connectionHandlers, setConnectionHandlers] = useState<
    Record<string, ConnectionHandler | undefined>
  >({});

  const addConnectionHandler: Required<ConnectionHandlers>['addConnectionHandler'] = useCallback(
    (key, handler) => {
      setConnectionHandlers((handlers) => ({
        ...handlers,
        [computeHandleKey(key)]: handler,
      }));
    },
    [],
  );

  const removeConnectionHandler: Required<ConnectionHandlers>['removeConnectionHandler'] =
    useCallback((key) => {
      setConnectionHandlers((handlers) => removeByKey(handlers, computeHandleKey(key)));
    }, []);

  const callConnectionHandler: ConnectionHandlers['callConnectionHandler'] = useCallback(
    (params) => {
      const { connection, actionCallback } = params;

      let sourceHandler: ConnectionHandler | undefined;
      let targetHandler: ConnectionHandler | undefined;

      for (const key in connectionHandlers) {
        const { id: handleId, nodeId } = createHandleKey(key);

        if (connection.source === nodeId && connection.sourceHandle === handleId) {
          sourceHandler = connectionHandlers[key];
        }

        if (connection.target === nodeId && connection.targetHandle === handleId) {
          targetHandler = connectionHandlers[key];
        }

        if (sourceHandler && targetHandler) {
          break;
        }
      }

      if (!sourceHandler && !targetHandler) {
        return connection;
      }

      const sourceResult = sourceHandler?.(connection, actionCallback);

      if (sourceResult === 'defer') {
        return null;
      }

      const targetResult = targetHandler?.(connection, actionCallback);

      if (targetResult === 'defer') {
        return null;
      }

      return sourceResult ?? targetResult ?? connection;
    },
    [connectionHandlers],
  );

  return (
    <EditorContext.Provider
      value={{
        connectionHandlers,
        callConnectionHandler,
        addConnectionHandler,
        removeConnectionHandler,
        newNodeType,
        setNewNodeType,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};
