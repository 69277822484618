import { useCallback, useEffect } from 'react';
import { NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { Button, Grid2 } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { NodeOutputFormData } from '../PipelineStartModal/PipelineStartModal.types';
import * as Styled from 'components/Dialog/Dialog.styles';
import PipelineStartForm from './PipelineStartForm';
import { createHandleId } from 'features/Flow/Flow.utils';
import { createOutputDataSchema, createOutputDefaultValues } from '../PipelineStart.utils';

interface PipelineStartCreateFormProps {
  defaultValues: NodeOutput | null;
  nodeOutputs: NodeOutput[];
  isPendingConnection?: boolean;
  onCancelCreate: () => void;
  onConfirmCreate: (output: NodeOutput) => void;
}

const PipelineStartCreateForm: React.FC<PipelineStartCreateFormProps> = ({
  defaultValues,
  nodeOutputs,
  isPendingConnection,
  onCancelCreate,
  onConfirmCreate,
}) => {
  const form = useForm<NodeOutputFormData>({
    defaultValues: createOutputDefaultValues(defaultValues),
    mode: 'onBlur',
  });

  const submitForm: SubmitHandler<NodeOutputFormData> = useCallback(
    (data: NodeOutputFormData) => {
      onConfirmCreate({
        name: data.name,
        dataSchema: createOutputDataSchema(data),
        title: data.title ?? '',
        config: {
          required: !!isPendingConnection,
        },
        id: createHandleId('output', data.name),
      });
    },
    [onConfirmCreate, isPendingConnection],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  useEffect(() => {
    if (defaultValues) {
      void form.trigger();
    }
  }, [defaultValues, form]);

  return (
    <FormProvider {...form}>
      <Styled.DialogTitle $hasBorder>Create Input</Styled.DialogTitle>
      <Styled.DialogContent>
        <PipelineStartForm
          otherOutputs={nodeOutputs}
          control={form.control}
          isConnected={false}
          isPendingConnection={isPendingConnection}
        />
      </Styled.DialogContent>
      <Styled.DialogActions $hasBorder>
        <Grid2 container>
          <Button variant="text" color="inherit" onClick={onCancelCreate}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={!form.formState.isValid}>
            Confirm
          </Button>
        </Grid2>
      </Styled.DialogActions>
    </FormProvider>
  );
};

export default PipelineStartCreateForm;
