import React, { useCallback, useMemo } from 'react';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import PipelineCompleteForm from './PipelineCompleteForm';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useFindHandleEdge } from 'features/Flow/hooks/useFindHandleEdge';
import { createHandleId } from 'features/Flow/Flow.utils';
import * as Styled from 'components/Dialog/Dialog.styles';
import { Button, Grid2 } from '@mui/material';
import { NodeInputFormData } from '../PipelineCompleteModal/PipelineCompleteModal.types';
import { createInputDefaultValues, mergeInputUpdate } from '../PipelineComplete.utils';

interface PipelineCompleteEditFormProps {
  nodeId: string;
  inputs: NodeInput[];
  inputToEdit: NodeInput;
  onCancelEdit: () => void;
  onConfirmEdit: (input: NodeInput) => void;
}

export const PipelineCompleteEditForm: React.FC<PipelineCompleteEditFormProps> = ({
  nodeId,
  inputs,
  inputToEdit,
  onCancelEdit,
  onConfirmEdit,
}) => {
  const form = useForm<NodeInputFormData>({
    defaultValues: createInputDefaultValues(inputToEdit),
    mode: 'onBlur',
  });

  const findEdge = useFindHandleEdge({ nodeId });
  const isConnected = !!findEdge(inputToEdit.id ?? createHandleId('input', inputToEdit.name));

  const inputsNotInEdit = useMemo(() => {
    return inputs.filter((input) => input.name !== inputToEdit.name);
  }, [inputs, inputToEdit.name]);

  const submitForm: SubmitHandler<NodeInputFormData> = useCallback(
    (data) => {
      onConfirmEdit(mergeInputUpdate(data, inputToEdit, isConnected));
    },
    [onConfirmEdit, isConnected, inputToEdit],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  return (
    <FormProvider {...form}>
      <Styled.DialogTitle $hasBorder>Edit Output</Styled.DialogTitle>
      <Styled.DialogContent>
        <PipelineCompleteForm
          otherInputs={inputsNotInEdit}
          control={form.control}
          isConnected={isConnected}
        />
      </Styled.DialogContent>
      <Styled.DialogActions $hasBorder>
        <Grid2 container>
          <Button variant="text" color="inherit" onClick={onCancelEdit}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={!form.formState.isValid}>
            Confirm
          </Button>
        </Grid2>
      </Styled.DialogActions>
    </FormProvider>
  );
};
