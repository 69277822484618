import { DataSchema, isArrayDataSchema } from '@pathways/pipeline-schema/web';
import { DummyNodeInputDataSchema } from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuron.types';
import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { getSchemaByDataType } from 'utils/dataSchema';

export const createDummyFieldDataSchema = (data: DummyNodeInputDataSchema) => {
  const mediaType = data.mediaType ? [data.mediaType] : undefined;

  return getSchemaByDataType(data.dataType, data.arrayDataType, mediaType);
};

const createDefaultMediaType = (dataSchema: DataSchema | undefined): string | undefined => {
  if (dataSchema?.type === 'array' && dataSchema.items.type === 'asset') {
    return dataSchema.items.mediaType[0];
  }

  if (dataSchema?.type === 'asset') {
    return dataSchema.mediaType[0];
  }

  return undefined;
};

export const createDefaultValues = (item: NodeInput | NodeOutput | null) => ({
  name: item?.name ?? '',
  title: item?.title ?? '',
  dataType: item?.dataSchema.type ?? 'text',
  arrayDataType:
    item?.dataSchema && isArrayDataSchema(item.dataSchema) ? item.dataSchema.items.type : undefined,
  mediaType: createDefaultMediaType(item?.dataSchema),
});
