import { useRequest } from 'api/client';
import { ManifestResponse } from './useManifests.types';

const useManifests = (workspaceId: string) => {
  const query = new URLSearchParams({
    workspaceId,
  });
  const request = useRequest<ManifestResponse[]>({
    url: `/manifests?${query.toString()}`,
  });

  return {
    areManifestsLoading: request.isLoading,
    manifests: request.data ?? [],
    manifestsError: request.error,
    mutateManifests: request.mutate,
  };
};

export default useManifests;
