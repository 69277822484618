import { Chip, css, styled } from '@mui/material';
import { ignoreTransientProps, getPaletteColor } from 'utils/styles';

export const MuiChip = styled(Chip, {
  shouldForwardProp: ignoreTransientProps,
})<{ $variant: string }>(({ theme, color, $variant }) => {
  const paletteColor = getPaletteColor(color, theme);
  const backgroundColor = paletteColor?.selected ?? 'transparent';

  if ($variant === 'elevated')
    return css`
      background-color: ${backgroundColor};
      color: ${paletteColor?.main};
    `;
});
