import { Button, Grid2, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import * as Styled from './ObjectBuilderModal.styles';
import { NodeInput } from '../../../Node/Node.types';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableNodeInput from './SortableNodeInput';
import { useState } from 'react';
import * as StyledDialog from 'components/Dialog/Dialog.styles';

interface ObjectBuilderListProps {
  inputs: NodeInput[];
  onCreateInput: () => void;
  onEditInput: (input: NodeInput, position: number) => void;
  onRemoveInput: (index: number) => void;
  onCloseModal: () => void;
  onConfirm: () => void;
  onReorder: (from: string, to: string) => void;
}

const ObjectBuilderList: React.FC<ObjectBuilderListProps> = ({
  inputs,
  onCreateInput,
  onEditInput,
  onRemoveInput,
  onCloseModal,
  onConfirm,
  onReorder,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setIsDragging(false);
    if (event.over && event.active.id !== event.over.id) {
      onReorder(event.active.id as string, event.over.id as string);
    }
  };

  return (
    <>
      <StyledDialog.DialogTitle $hasBorder={true}>Configure Schema</StyledDialog.DialogTitle>
      <StyledDialog.DialogContent>
        <Styled.SortableBox $isDragging={isDragging}>
          {inputs.length === 0 && (
            <StyledDialog.NoItemsBox>
              <Typography
                variant="labelSmall"
                sx={{
                  color: 'text.secondary',
                }}
              >
                No inputs created yet. Use the &apos;Create Input&apos; button below to get started.
              </Typography>
            </StyledDialog.NoItemsBox>
          )}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            autoScroll={false}
          >
            <SortableContext
              items={inputs.map((input) => input.name)}
              strategy={verticalListSortingStrategy}
            >
              {inputs.map((input, index) => (
                <SortableNodeInput
                  key={input.name}
                  index={index}
                  input={input}
                  isDragging={isDragging}
                  onEditInput={onEditInput}
                  onRemoveInput={onRemoveInput}
                />
              ))}
            </SortableContext>
          </DndContext>
        </Styled.SortableBox>
      </StyledDialog.DialogContent>
      <StyledDialog.DialogActions $hasBorder={true}>
        <Grid2
          container
          sx={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Button startIcon={<AddIcon />} onClick={onCreateInput}>
            Create Input
          </Button>
          <div>
            <Button variant="text" sx={{ color: 'common.white' }} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </div>
        </Grid2>
      </StyledDialog.DialogActions>
    </>
  );
};

export default ObjectBuilderList;
