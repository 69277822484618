import { FormGroup, Typography } from '@mui/material';
import RHFTextField from 'components/ui/rhf-components/RHFTextField/RHFTextField';
import { NodeType } from 'features/Flow/Flow.types';
import { useFormContext } from 'react-hook-form';
import JobInfoTitle from '../../../JobInfoTitle/JobInfoTitle';
import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';
import * as Styled from '../ExecutionCheckpointForm.styles';

const ExecutionCheckpointSingleTextForm = ({
  disabled,
  input,
  pendingCheckpoint,
  pendingCheckpointHeader,
}: ExecutionCheckpointFormProps) => {
  const { metadata } = pendingCheckpoint;
  const defaultValue = pendingCheckpoint.data.inputs[input.name];
  const { control } = useFormContext();

  return (
    <Styled.FormContainer>
      <Styled.CheckpointApprovalHeader>
        <JobInfoTitle
          displaySecondaryName
          type={NodeType.CHECKPOINT}
          name={metadata.name}
          customTitle={metadata.customTitle}
        />

        <Styled.CheckpointApprovalRightBox>
          <Styled.CheckpointApprovalHeaderChip>
            {pendingCheckpointHeader}
          </Styled.CheckpointApprovalHeaderChip>
        </Styled.CheckpointApprovalRightBox>
      </Styled.CheckpointApprovalHeader>

      <Styled.CheckpointApprovalSubheader>
        <Typography variant="labelSmall">{input.title}</Typography>
      </Styled.CheckpointApprovalSubheader>

      <FormGroup>
        <RHFTextField
          control={control}
          defaultValue={defaultValue}
          disabled={disabled}
          fullWidth
          multiline
          name={input.name}
        />
      </FormGroup>
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointSingleTextForm;
