import { executionsAppRoutes } from 'pages/ExecutionsPage/routes';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

function createBaseUrl(workspaceSlug: string, projectId?: string): string {
  if (!projectId) {
    return `/workspaces/${workspaceSlug}`;
  }
  return `/workspaces/${workspaceSlug}/projects/${projectId}`;
}

function getManageCredentialsApiUrl(workspaceId: string) {
  const { origin } = new URL(import.meta.env.MONKS_FLOW_PLATFORM_HOME_URL);
  return `${origin}/admin/workspaces/${workspaceId}?tab=credentials`;
}

export const useAppRoutes = () => {
  const { workspaceSlug = '', projectId = '' } = useParams();
  const baseUrl = createBaseUrl(workspaceSlug, projectId);
  const routes = useMemo(() => {
    return {
      index: createBaseUrl,
      dashboard: () => `${baseUrl}/dashboard`,
      executions: executionsAppRoutes(baseUrl),
      pipelines: (pipelineId: string) => `${baseUrl}/pipelines/${pipelineId}`,
      runtime(pipelineId: string, pipelineExecutionId: string) {
        return `${this.pipelines(pipelineId)}/runtime/${pipelineExecutionId}`;
      },
      versions(pipelineId: string) {
        return `${this.pipelines(pipelineId)}/versions`;
      },
      pipelineVersion(pipelineId: string, versionId: string) {
        return `${this.versions(pipelineId)}/${versionId}`;
      },
      settings(pipelineId: string) {
        return `${this.pipelines(pipelineId)}/settings`;
      },
      viewTrigger(pipelineId: string, triggerId: string) {
        return `${this.pipelines(pipelineId)}/triggers/${triggerId}`;
      },
      platform: {
        manageCredentialsApiUrl: getManageCredentialsApiUrl,
      },
    };
  }, [baseUrl]);

  return routes;
};
