import { VERSION_SEPARATOR, parseVersionedKey } from '@pathways/pipeline-schema/web';
import { useStore } from 'reactflow';
import { FlowState } from 'types/reactflow';
import { shallow } from 'zustand/shallow';
import { PathwayNodeMetadata } from '../nodes/Node/Node.types';

const selector = (state: FlowState) => state.neuronScopes;

export default function useIsNodeWorkspaceScoped(uses: PathwayNodeMetadata['uses']) {
  const neuronScopes = useStore(selector, shallow);
  const neuronKey = uses.includes(VERSION_SEPARATOR) ? parseVersionedKey(uses).key : uses;

  return !!neuronScopes?.[neuronKey]?.workspaceId;
}
