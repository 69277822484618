import { Typography } from '@mui/material';
import CopyButton from 'components/CopyButton/CopyButton';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import { useJsonEditorState } from 'components/JsonEditor/useJsonEditorState';
import { NodeObjectValue } from 'features/Flow/nodes/Node/Node.types';
import { useEffect } from 'react';
import { UseControllerReturn, useFormContext } from 'react-hook-form';
import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';
import * as Styled from '../ExecutionCheckpointForm.styles';

type BaseProps = Pick<ExecutionCheckpointFormProps, 'disabled'>;

interface ExecutionCheckpointObjectListJsonEditorProps extends BaseProps {
  controller: UseControllerReturn<Record<string, NodeObjectValue[]>>;
  initialValue: NodeObjectValue[];
}

const ExecutionCheckpointObjectListJsonEditor = ({
  disabled,
  controller,
  initialValue,
}: ExecutionCheckpointObjectListJsonEditorProps) => {
  const { content, error, value, onChange: onJsonChange } = useJsonEditorState(initialValue);
  const { setError } = useFormContext();
  const {
    field: { onChange: controllerOnChange },
  } = controller;

  useEffect(() => {
    setError(controller.field.name, error ? { message: 'Invalid JSON list' } : {});
  }, [controller.field.name, error, setError]);

  useEffect(() => {
    controllerOnChange(value);
  }, [controllerOnChange, value]);

  return (
    <>
      <Styled.CheckpointApprovalSubheader>
        <Typography variant="labelSmall">JSON Data</Typography>

        <Styled.CheckpointApprovalRightBox>
          <CopyButton
            sx={{ marginRight: 1 }}
            value={JSON.stringify(controller.field.value, null, 2)}
          />
        </Styled.CheckpointApprovalRightBox>
      </Styled.CheckpointApprovalSubheader>

      <Styled.EditorContainer>
        <JsonEditor content={content} readOnly={disabled} onChange={onJsonChange} type={'array'} />
      </Styled.EditorContainer>
    </>
  );
};

export default ExecutionCheckpointObjectListJsonEditor;
