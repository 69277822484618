import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { NodeOutput } from '../../../Node/Node.types';
import { Grid2, IconButton, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Edit as EditIcon } from '@mui/icons-material';
import DataSchemaIcon from 'components/DataSchemaIcon/DataSchemaIcon';
import DragIndicatorHandle from 'components/DragIndicatorHandle/DragIndicatorHandle';

interface SortableNodeOutputProps {
  output: NodeOutput;
  index: number;
  isDragging: boolean;
  onEditOutput: (output: NodeOutput, position: number) => void;
  onRemoveOutput: (index: number) => void;
}

const SortableNodeOutput = ({
  index,
  output,
  isDragging,
  onEditOutput,
  onRemoveOutput,
}: SortableNodeOutputProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: output.name,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Grid2
        container
        sx={{
          alignItems: 'center',
        }}
      >
        <Grid2
          size={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DragIndicatorHandle {...listeners} $isDragging={isDragging} />
        </Grid2>
        <Grid2 size={1}>
          <DataSchemaIcon dataSchema={output.dataSchema} size={'24px'} />
        </Grid2>
        <Grid2 size={8}>
          <Typography>{output.title || output.name}</Typography>
        </Grid2>
        <Grid2 size={1}>
          <IconButton
            onClick={() => {
              onEditOutput(output, index);
            }}
          >
            <EditIcon />
          </IconButton>
        </Grid2>
        <Grid2 size={1}>
          <IconButton
            onClick={() => {
              onRemoveOutput(index);
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid2>
      </Grid2>
    </div>
  );
};

export default SortableNodeOutput;
