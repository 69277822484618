import useToast from 'contexts/toast/useToast';
import { useCallback } from 'react';
import { getConnectedEdges } from 'reactflow';
import { APP_REACTFLOW_DATATRANSFER } from 'features/Flow/Flow.consts';
import { ClipboardData } from 'features/Flow/Flow.types';
import useFlow from '../useFlow';
import { isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import { FlowNode } from 'types/reactflow';
import { mapNodeOutsideGroup } from 'utils/mappings';
import { isBatchGroupNode } from 'features/Flow/nodes/Batch/Batch.types';
import { sanitizeNode } from './useCopyElementsToClipboard.utils';

function isCopyable(node: FlowNode) {
  return !!node.selected && (node.deletable ?? true);
}

const SuccessMessage = {
  single: (elementName: string) => `${elementName} copied successfully.`,
  multiple: (elementsCount: number) => `${elementsCount} elements copied successfully.`,
};

export default function useCopyElementsToClipboard() {
  const toast = useToast();
  const { getNodes, getEdges } = useFlow();

  return useCallback(async () => {
    try {
      const nodes = getNodes();
      const selectedGroupNodes = nodes
        .filter((node) => isGroupNode(node) || isBatchGroupNode(node))
        .filter(isCopyable);
      const selectedNodes = nodes.reduce<FlowNode[]>((filtered, node) => {
        const selected = isCopyable(node);
        const childOfGroup = !!node.parentNode;

        const sanitizedNode = sanitizeNode(node);
        if (childOfGroup) {
          const groupSelected = selectedGroupNodes.find(
            (groupNode) => groupNode.id === node.parentNode,
          );

          if (groupSelected) {
            filtered.push(sanitizedNode);
          }

          if (selected && !groupSelected && !isBatchGroupNode(node)) {
            filtered.push(mapNodeOutsideGroup(sanitizedNode));
          }
        } else if (selected) {
          filtered.push(sanitizedNode);
        }

        return filtered;
      }, []);

      if (!selectedNodes.length) return;

      const nodeIds = selectedNodes.map((node) => node.id);
      const connectedEdges = getConnectedEdges(selectedNodes, getEdges()).filter(
        (edge) => nodeIds.includes(edge.source) && nodeIds.includes(edge.target),
      );

      const clipboardData: ClipboardData = {
        dataType: APP_REACTFLOW_DATATRANSFER,
        nodes: selectedNodes,
        edges: connectedEdges,
      };
      const clipboardText = JSON.stringify(clipboardData);

      await navigator.clipboard.writeText(clipboardText);

      const isMultipleNodesCopied = selectedNodes.length > 1;

      toast.success({
        message: isMultipleNodesCopied
          ? SuccessMessage.multiple(selectedNodes.length)
          : SuccessMessage.single('Element'),
      });
    } catch (_error) {
      toast.error({
        message: 'There was an error copying the selected elements.',
      });
    }
  }, [getEdges, getNodes, toast]);
}
