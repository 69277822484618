import useGetConnectedProvider from 'features/Flow/hooks/useGetConnectedProvider';
import { SidebarSection } from 'components/Sidebar/Sidebar.styles';
import * as Styled from '../../RightSidebar.styles';
import { ConnectedApisAccordion } from '../SelectedNodePanel/SelectedNodePanel.styles';
import {
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Chip,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import OpenInNewTab from 'components/OpenInNewTab/OpenInNewTab';
import { useAppRoutes } from 'utils/routes';
import FeatureFlag from 'features/FeatureFlag/FeatureFlag';

function ConnectedProviderSection(props: {
  workspaceId: string;
  connectedProvider: ReturnType<typeof useGetConnectedProvider>;
}) {
  const {
    platform: { manageCredentialsApiUrl },
  } = useAppRoutes();
  const { connectedProvider, workspaceId } = props;

  if (!connectedProvider) return null;

  return (
    <SidebarSection>
      <Styled.SectionDivider />
      <ConnectedApisAccordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <TitleBox>
            <Typography variant="titleSmall">APIs</Typography>
            <FeatureFlag feature="none" permissions={['platform:manage-workspaces']}>
              <OpenInNewTab href={manageCredentialsApiUrl(workspaceId)} tooltip="Manage APIs" />
            </FeatureFlag>
          </TitleBox>
        </AccordionSummary>
        <AccordionDetails>
          <Stack gap={2}>
            {connectedProvider.status === 'disconnected' && (
              <Alert severity="warning">
                One or multiple APIs require credentials to function when this pipeline is run.
              </Alert>
            )}
            <ContentBox>
              <Typography variant="titleSmall">{connectedProvider.name}</Typography>
              <ProviderStatus provider={connectedProvider} />
            </ContentBox>
          </Stack>
        </AccordionDetails>
      </ConnectedApisAccordion>
      <Styled.SectionDivider />
    </SidebarSection>
  );
}

function ProviderStatus(props: { provider: ReturnType<typeof useGetConnectedProvider> }) {
  const { provider } = props;
  return (
    <Chip
      variant={'filled'}
      sx={{
        bgcolor: (theme) =>
          provider?.status === 'connected'
            ? theme.palette.success.selected
            : theme.palette.warning.selected,
      }}
      label={
        <Typography
          variant="bodySmall"
          sx={{
            color: (theme) =>
              provider?.status === 'connected'
                ? theme.palette.success.main
                : theme.palette.warning.main,
          }}
        >
          {provider?.status === 'connected' ? 'Connected' : 'Disconnected'}
        </Typography>
      }
    />
  );
}

const ContentBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.palette.surface.level2};
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
  gap: ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.spacing(1)};
`;

const TitleBox = styled(Box)`
  flex-grow: 1;
  padding-right: ${({ theme }) => theme.spacing(1)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default ConnectedProviderSection;
