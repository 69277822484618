import { Stack, Typography } from '@mui/material';
import { Layout, MuiContainer, ScrollContainer } from 'components/Layout/Layout.styles';
import PageNavigation from 'components/PageNavigation/PageNavigation';
import SubpipelineSection from './components/SubpipelineSection/SubpipelineSection';
import TriggersSection from './components/TriggersSection/TriggersSection';

export default function PipelineSettingsPage() {
  return (
    <Layout>
      <PageNavigation />

      <ScrollContainer>
        <MuiContainer fixed>
          <Typography
            component="h1"
            variant="titleLarge"
            sx={{
              paddingBottom: 3,
            }}
          >
            Settings
          </Typography>

          <Stack spacing={4}>
            <SubpipelineSection />
            <TriggersSection />
          </Stack>
        </MuiContainer>
      </ScrollContainer>
    </Layout>
  );
}
