import { Box, PaperProps, css, styled } from '@mui/material';
import HeaderChip from 'components/HeaderChip/HeaderChip';
import RoundedPaper from 'components/RoundedPaper/RoundedPaper';
import { ignoreTransientProps } from 'utils/styles';

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const CheckpointApprovalHeader = styled(Box, {
  shouldForwardProp: ignoreTransientProps,
})(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(3)};
  `,
);

export const CheckpointApprovalSubheader = styled((props: PaperProps) => (
  <RoundedPaper component={CheckpointApprovalHeader} elevation={2} {...props} />
))(
  ({ theme }) => css`
    padding: ${theme.spacing(1, 1.5)};
    max-height: 40px;
  `,
);

export const CheckpointApprovalCardContainer = styled('div')`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const CheckpointApprovalHeaderChip = HeaderChip;

export const CheckpointApprovalRightBox = styled(Box)`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const EditorContainer = styled('div')`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
`;
