import { Box, TextField } from '@mui/material';
import TabContainer from 'components/TabContainer/TabContainer';
import { NodeType } from 'features/Flow/Flow.types';
import PathwayNodePanelHeader from 'features/Flow/components/RightSidebar/components/PathwayNodePanelHeader/PathwayNodePanelHeader';
import SingleElementActionsMenu from 'features/Flow/components/RightSidebar/components/SingleElementActionsMenu/SingleElementActionsMenu';
import StartOrCompletePanel from 'features/Flow/components/RightSidebar/components/StartOrCompletePanel/StartOrCompletePanel';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { isBatchGroupNode } from 'features/Flow/nodes/Batch/Batch.types';
import { isDynamicCheckpointNode } from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/DynamicCheckpoint.types';
import DynamicCheckpointPanel from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/components/DynamicCheckpointPanel/DynamicCheckpointPanel';
import { isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import { isPathwayNode, NodeData, PathwayNodeMetadata } from 'features/Flow/nodes/Node/Node.types';
import { isPipelineCompleteNode } from 'features/Flow/nodes/PipelineComplete/PipelineComplete.types';
import { isPipelineStartNode } from 'features/Flow/nodes/PipelineStart/PipelineStart.types';
import { FlowNode, FlowNodeData } from 'types/reactflow';
import { getJobTitle } from 'utils/neurons';
import * as Styled from '../../RightSidebar.styles';
import GroupPanel from '../GroupPanel/GroupPanel';
import Inputs from '../Inputs/Inputs';
import Outputs from '../Outputs/Outputs';
import ConnectedProviderSection from '../ConnectedProviderSection/ConnectedProviderSection';
import useWorkspace from 'hooks/useWorkspace';
import useGetConnectedProvider from 'features/Flow/hooks/useGetConnectedProvider';

export interface SelectedNodePanelProps {
  selectedNode: FlowNode;
}

function hasProviderType(nodeData: FlowNodeData): nodeData is NodeData {
  return !!(nodeData.metadata as PathwayNodeMetadata).provider?.type;
}

const SelectedNodePanel = (props: SelectedNodePanelProps) => {
  const { selectedNode } = props;
  const workspace = useWorkspace();
  const providerType = isPathwayNode(selectedNode)
    ? selectedNode.data.metadata.provider?.type
    : undefined;
  const connectedProvider = useGetConnectedProvider(providerType);
  const updateNodeData = useUpdateNodeData();

  if (isGroupNode(selectedNode) || isBatchGroupNode(selectedNode)) {
    return <GroupPanel groupNode={selectedNode} />;
  }

  if (isPipelineStartNode(selectedNode) || isPipelineCompleteNode(selectedNode)) {
    return <StartOrCompletePanel selectedNode={selectedNode} />;
  }

  if (isDynamicCheckpointNode(selectedNode)) {
    return <DynamicCheckpointPanel node={selectedNode} />;
  }

  if (!isPathwayNode(selectedNode)) return null;

  return (
    <>
      <Styled.HeaderActionBar>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            flexGrow: 1,
            gap: 1,
          }}
        >
          <PathwayNodePanelHeader selectedNode={selectedNode} />
          <SingleElementActionsMenu selectedNode={selectedNode} />
        </Box>
      </Styled.HeaderActionBar>

      <TextField
        name="customTitle"
        size="small"
        fullWidth
        label={selectedNode.type === NodeType.CHECKPOINT ? 'Checkpoint Name' : 'Neuron Name'}
        value={getJobTitle(selectedNode.data.metadata)}
        onChange={(event) => {
          updateNodeData(selectedNode.id, (data) => ({
            metadata: {
              ...data.metadata,
              customTitle: event.target.value,
            },
          }));
        }}
      />

      <Styled.SectionDivider />

      <TabContainer
        tabPanelChildren={[
          <Inputs
            inputs={selectedNode.data.inputs}
            key={selectedNode.id}
            selectedNode={selectedNode}
          />,
          <Outputs key={selectedNode.id} selectedNode={selectedNode} />,
        ]}
        tabs={['Input', 'Output']}
      />

      {hasProviderType(selectedNode.data) && (
        <ConnectedProviderSection
          workspaceId={workspace.id}
          connectedProvider={connectedProvider}
        />
      )}
    </>
  );
};

export default SelectedNodePanel;
