import { ReactNode, createContext } from 'react';

export interface ExecutionContextValue {
  pageFooter: ReactNode;
  displayLogPanel: boolean;
  setPageFooter: (footer: ReactNode) => void;
  setOrToggleDisplayLogPanel: (display?: boolean) => void;
}

export const ExecutionContext = createContext<ExecutionContextValue | null>(null);
