import { ExecutionResponse } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import { ColumnConfig as CustomColumnConfig } from 'components/Table/TableHead';

export const COLUMN_IDS = {
  RUN_NUMBER: 'runNumber',
  PIPELINE_VERSION: 'pipelineVersion',
  STARTED: 'started',
  STATUS: 'status',
  TRIGGER: 'trigger',
  PROGRESS: 'progress',
} as const;

export type ColumnId = (typeof COLUMN_IDS)[keyof typeof COLUMN_IDS];

export interface ColumnConfig extends Omit<CustomColumnConfig, 'id'> {
  orderProperty?: keyof ExecutionResponse;
}

export const COLUMN_CONFIG: Partial<Record<ColumnId, ColumnConfig>> = {
  [COLUMN_IDS.RUN_NUMBER]: {
    label: 'Run Number',
    orderProperty: 'executionNumber',
  },
  [COLUMN_IDS.PIPELINE_VERSION]: {
    label: 'Pipeline Version',
    orderProperty: 'version',
  },
  [COLUMN_IDS.STARTED]: {
    label: 'Started',
    orderProperty: 'createdAt',
  },
  [COLUMN_IDS.STATUS]: {
    label: 'Status',
    orderProperty: 'status',
  },
  [COLUMN_IDS.TRIGGER]: {
    label: 'Trigger',
    orderProperty: 'triggerType',
  },
  [COLUMN_IDS.PROGRESS]: {
    label: 'Progress',
  },
};

export const COLUMN_CONFIG_ARRAY = Object.entries(COLUMN_CONFIG).map(([id, config]) => ({
  id: id as ColumnId,
  ...config,
}));
