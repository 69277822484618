import { Stack, Typography } from '@mui/material';
import { PipelineTriggerResponse } from 'api/services/usePipelineTriggers/usePipelineTriggers.types';
import Loader from 'components/Loader/Loader';
import { formatDate } from 'utils/string';
import Section, { SectionBaseProps } from '../Section/Section';

interface AboutSectionProps extends SectionBaseProps {
  trigger: PipelineTriggerResponse | undefined;
}

export default function AboutSection(props: AboutSectionProps) {
  const { trigger, loading } = props;
  const displayLoading = loading || !trigger;

  return (
    <Section title="About" loading={displayLoading}>
      <Stack gap={3}>
        <FieldDetail label="Name" value={trigger?.name} loading={displayLoading} />
        <FieldDetail label="Notes" value={trigger?.notes} loading={displayLoading} />
        <FieldDetail
          label="Created"
          value={formatDate(trigger?.createdAt)}
          loading={displayLoading}
        />
      </Stack>
    </Section>
  );
}

interface FieldDetailProps {
  loading: boolean;
  label: string;
  value: string | undefined;
}

function FieldDetail(props: FieldDetailProps) {
  const { loading, label, value } = props;

  return (
    <Stack gap={0.5}>
      <Loader
        data-testid={`${label.toLowerCase()}-label-loader`}
        loading={loading}
        variant="text"
        width={120}
        height={16}
      >
        <Typography variant="labelMedium" color="text.secondary">
          {label}
        </Typography>
      </Loader>

      <Loader
        data-testid={`${label.toLowerCase()}-value-loader`}
        loading={loading}
        variant="text"
        width={342}
        height={24}
      >
        {value ? (
          <Typography variant="bodyLarge">{value}</Typography>
        ) : (
          <Typography variant="bodyLarge" color="textDisabled">
            None added
          </Typography>
        )}
      </Loader>
    </Stack>
  );
}
