import { PipelineTriggerResponse } from 'api/services/usePipelineTriggers/usePipelineTriggers.types';
import { ColumnConfig as CustomColumnConfig } from 'components/Table/TableHead';

export const COLUMN_IDS = {
  NAME: 'name',
  NOTES: 'notes',
  CREATED: 'created',
} as const;

export type ColumnId = (typeof COLUMN_IDS)[keyof typeof COLUMN_IDS];

export interface ColumnConfig extends Omit<CustomColumnConfig, 'id'> {
  orderProperty?: keyof PipelineTriggerResponse;
}

export const COLUMN_CONFIG: Partial<Record<ColumnId, ColumnConfig>> = {
  [COLUMN_IDS.NAME]: {
    label: 'Name',
    orderProperty: 'name',
  },
  [COLUMN_IDS.NOTES]: {
    label: 'Notes',
    orderProperty: 'notes',
  },
  [COLUMN_IDS.CREATED]: {
    label: 'Created',
    orderProperty: 'createdAt',
  },
};

export const COLUMN_CONFIG_ARRAY = Object.entries(COLUMN_CONFIG).map(([id, config]) => ({
  id: id as ColumnId,
  ...config,
}));
