import { Button, Box, Stack, Typography } from '@mui/material';
import PathwaysProjectIcon from 'components/Icons/PathwaysProject';
import { useAppRoutes } from 'utils/routes';
import { useRouteError, Link } from 'react-router-dom';
import { MissingWorkspaceError, UnauthorizedError } from 'utils/customErrors';
import { redirectToPlatform } from 'utils/redirectToPlatform';

export default function ErrorPage() {
  const routes = useAppRoutes();
  const error = useRouteError();
  if (error instanceof MissingWorkspaceError || error instanceof UnauthorizedError) {
    redirectToPlatform();
    return null;
  }

  return (
    <>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          height: 500,
        }}
      >
        <PathwaysProjectIcon fontSize="large" />
        <Typography
          variant="body1"
          sx={{
            mt: 2,
          }}
        >
          Looks like we encountered an error!
        </Typography>
        <Typography variant="caption">
          We are working to resolve the issue. Please try again later or contact support.
        </Typography>
        <Box
          sx={{
            mt: 2,
          }}
        >
          <Button component={Link} color="primary" variant="contained" to={routes.dashboard()}>
            Go to Dashboard
          </Button>
        </Box>
      </Stack>
    </>
  );
}
