import { Box } from '@mui/material';
import { Layout, MuiContainer, ScrollContainer } from 'components/Layout/Layout.styles';
import PageNavigation from 'components/PageNavigation/PageNavigation';
import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { ExecutionLogPanel } from './components/ExecutionLogPanel/ExecutionLogPanel';
import { ExecutionContextProvider } from './contexts/execution/ExecutionProvider';
import { useExecutionContext } from './contexts/execution/useExecutionContext';
import { ExecutionCheckpointProvider } from './pages/ViewExecutionPage/components/ExecutionCheckpoint/contexts/ExecutionCheckpointProvider';

export default function ExecutionsPage() {
  return (
    <ExecutionsPageProviders>
      <Layout>
        <PageNavigation />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
          }}
        >
          <MainContent />
          <ExecutionLogPanel />
        </Box>
      </Layout>
    </ExecutionsPageProviders>
  );
}

export function ExecutionsPageProviders({ children }: PropsWithChildren) {
  return (
    <ExecutionContextProvider>
      <ExecutionCheckpointProvider>{children}</ExecutionCheckpointProvider>
    </ExecutionContextProvider>
  );
}

function MainContent() {
  return (
    <Box
      component="main"
      sx={{
        display: 'grid',
        flexGrow: 1,
        overflow: 'auto',
        alignContent: 'space-between',
      }}
    >
      <ScrollContainer
        component="section"
        sx={{
          paddingX: 3,
        }}
      >
        <MuiContainer>
          <Outlet />
        </MuiContainer>
      </ScrollContainer>

      <ExecutionsPageFooter />
    </Box>
  );
}

export function ExecutionsPageFooter() {
  const context = useExecutionContext();
  return context.pageFooter;
}
