import { useContext } from 'react';
import { ExecutionContext } from './ExecutionContext';

export function useExecutionContext() {
  const context = useContext(ExecutionContext);

  if (!context) {
    throw new Error('useExecutionContext must be used within an ExecutionContextProvider');
  }

  return context;
}

export function useExecutionContextOptional() {
  return useContext(ExecutionContext);
}
