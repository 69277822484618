import { Button, Stack } from '@mui/material';
import NavTabs, { NavTabsItems } from 'components/NavTabs/NavTabs';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { Link } from 'react-router-dom';
import { FlowPipeline } from 'types/reactflow';
import { useAppRoutes } from 'utils/routes';

interface ExecutionsNavProps {
  pipeline: FlowPipeline;
  navTabs: NavTabsItems;
}

const ExecutionsNav = (props: ExecutionsNavProps) => {
  const routes = useAppRoutes();
  const { name, id } = props.pipeline;

  return (
    <NavigationBar
      title={name}
      firstBreadcrumb={{
        label: name,
        route: routes.pipelines(id),
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
        }}
      >
        <NavTabs items={props.navTabs} />

        <Button component={Link} color="primary" variant="contained" to={routes.pipelines(id)}>
          Edit Pipeline
        </Button>
      </Stack>
    </NavigationBar>
  );
};

export default ExecutionsNav;
