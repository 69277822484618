import { capitalize } from '@mui/material';
import { Node, Rect, XYPosition } from 'reactflow';
import { FlowNode, JobNode } from 'types/reactflow';
import type {
  IOSchema,
  NeuronSchemaProperty,
  NeuronSchemas,
} from 'api/services/useManifests/useManifests.types';
import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { FormatNodeDataFn, NodeType, SubpipelineNodeManifest } from 'features/Flow/Flow.types';
import { isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import {
  PIPELINE_COMPLETE_NAME,
  PIPELINE_COMPLETE_NODE_ID,
} from 'features/Flow/nodes/PipelineComplete/PipelineComplete.consts';
import {
  PIPELINE_START_NAME,
  PIPELINE_START_NODE_ID,
} from 'features/Flow/nodes/PipelineStart/PipelineStart.consts';
import { getAssetMediaTypes } from './assetObjects';
import { DataSchema, flattenSchemaVariations, JobIOSchema } from '@pathways/pipeline-schema/web';

// TODO Take another look at this for improvement, and possibility of distributing
export function getDataSchema(neuronSchemaProperty: NeuronSchemaProperty): DataSchema {
  const propertyType = neuronSchemaProperty.type;
  const enumOptions = neuronSchemaProperty.enum;
  const { items } = neuronSchemaProperty;

  if (enumOptions) {
    return { type: 'enum', values: enumOptions };
  }

  if (neuronSchemaProperty.const !== undefined) {
    return { type: 'const', const: neuronSchemaProperty.const };
  }

  switch (propertyType) {
    case 'string':
      if (neuronSchemaProperty.format === 'image-uri') {
        return { type: 'image-uri' };
      }
      return { type: 'text' };
    case 'number':
      return { type: 'number' };
    case 'integer':
      return { type: 'integer' };
    case 'boolean':
      return { type: 'boolean' };
    case 'object':
      if (neuronSchemaProperty.assetObject) {
        return { type: 'asset', mediaType: getAssetMediaTypes(neuronSchemaProperty) };
      }
      return { type: 'object' };
    case 'array':
      switch (items?.type) {
        case 'string':
          if (items.format === 'image-uri') {
            return { type: 'array', items: { type: 'image-uri' } };
          }

          return { type: 'array', items: { type: 'text' } };

        case 'number':
          return { type: 'array', items: { type: 'number' } };
        case 'integer':
          return { type: 'array', items: { type: 'integer' } };
        case 'object':
          if (items.assetObject) {
            return {
              type: 'array',
              items: { type: 'asset', mediaType: getAssetMediaTypes(items) },
            };
          }
          return { type: 'array', items: { type: 'object' } };
        default:
          throw new Error('Found unexpected data type');
      }
    default:
      throw new Error('Found unexpected data type');
  }
}

function getNeuronSchemasVariation(schemas: JobIOSchema): {
  schemas: NeuronSchemas;
  variation?: string;
} {
  const variation = 'variations' in schemas ? schemas.variations?.options[0].title : undefined;

  if (!variation) {
    return { schemas: schemas as NeuronSchemas };
  }

  return {
    schemas: flattenSchemaVariations(schemas, variation) as NeuronSchemas,
    variation,
  };
}

export const formatNodeData: FormatNodeDataFn = (manifest, customTitle) => {
  const { variation, schemas } = getNeuronSchemasVariation(manifest.schemas as JobIOSchema);

  return {
    metadata: {
      type: manifest.type,
      name: manifest.name,
      description: manifest.description,
      uses: manifest.key,
      provider:
        manifest.type === NodeType.NEURON
          ? {
              type: manifest.provider,
            }
          : undefined,
      customTitle,
      variation,
    },
    schemas: manifest.schemas?.variations ? manifest.schemas : undefined,
    inputs: formatNodeInputs(schemas.input),
    outputs: formatNodeOutputs(schemas.output),
  };
};

export const formatSubpipelineNodeData = (
  manifest: SubpipelineNodeManifest,
  customTitle: string,
) => {
  return {
    ...formatNodeData({ ...manifest, key: '' }, customTitle),
    pipeline: manifest.pipeline,
  };
};

export const formatNodeInputs = (props: IOSchema | undefined): NodeInput[] => {
  const { properties = {}, required = [] } = props ?? {};

  return Object.entries(properties).map(([key, value]) => ({
    name: key,
    title: value.title ?? formatDataConnectionKey(key),
    description: value.description,
    dataSchema: getDataSchema(value),
    config: {
      enumOptions: value.enum,
      required: required.includes(key),
      minimum: value.minimum,
      maximum: value.maximum,
      default: value.default,
      forceHide: !!value.const,
    },
    value: value.const,
  }));
};

export const formatNodeOutputs = (props: IOSchema | undefined): NodeOutput[] => {
  const { properties = {}, required = [] } = props ?? {};

  return Object.entries(properties).map(([key, value]) => ({
    name: key,
    title: value.title ?? formatDataConnectionKey(key),
    description: value.description,
    dataSchema: getDataSchema(value),
    config: {
      required: required.includes(key),
      // Hiding const for now but in the future we might
      // decide to show it in the UI.
      forceHide: !!value.const,
    },
    value: value.const,
  }));
};

export const formatDataConnectionKey = (dataConnectionKey: string) => {
  return capitalize(dataConnectionKey.replaceAll('_', ' '));
};

/** Returns the rectangle that represent the position of a group of nodes similar to a selection rectangle. */
export function calculateNodesRect(nodes: Node[]) {
  const pathwayNode = nodes[0];
  const topLeftCorner = nodes.reduce(
    (corner, node) => {
      corner.x = Math.min(corner.x, node.position.x);
      corner.y = Math.min(corner.y, node.position.y);

      return corner;
    },
    { x: pathwayNode.position.x, y: pathwayNode.position.y },
  );

  const bottomRightCorner = nodes.reduce(
    (corner, node) => {
      corner.x = Math.max(corner.x, node.position.x + (node.width ?? 0));
      corner.y = Math.max(corner.y, node.position.y + (node.height ?? 0));

      return corner;
    },
    {
      x: pathwayNode.position.x + (pathwayNode.width ?? 0),
      y: pathwayNode.position.y + (pathwayNode.height ?? 0),
    },
  );

  const nodesRect: Rect = {
    x: topLeftCorner.x,
    y: topLeftCorner.y,
    width: Math.abs(bottomRightCorner.x - topLeftCorner.x),
    height: Math.abs(bottomRightCorner.y - topLeftCorner.y),
  };

  return nodesRect;
}

/**
 * Having a rectangle that represents a group of nodes or a single one,
 * calculate the position of the rectangle so the center matches the cursor position.
 */
export function calculateNodePositionAtCursorPosition(params: {
  nodePosition: XYPosition;
  nodesRect: Rect;
  cursorPosition: XYPosition;
}) {
  const { nodePosition, nodesRect, cursorPosition } = params;

  // Calculate the top left corner to match [0,0] or move the node relative to it.
  const relativePositionX = nodePosition.x - nodesRect.x;
  // Then calculate the top left corner to match the cursor position.
  const topLeftCornerAtCursorPositionX = relativePositionX + cursorPosition.x;
  // Then subtract half of the rect width to center it.
  const centerRectAtCursorPositionX = topLeftCornerAtCursorPositionX - nodesRect.width / 2;

  const relativePositionY = nodePosition.y - nodesRect.y;
  const bottomRightCornerAtCursorPositionY = relativePositionY + cursorPosition.y;
  const centerRectAtCursorPositionY = bottomRightCornerAtCursorPositionY - nodesRect.height / 2;

  const centeredRectPosition: XYPosition = {
    x: centerRectAtCursorPositionX,
    y: centerRectAtCursorPositionY,
  };

  return centeredRectPosition;
}

function getNodePositionAbsolute(positionAbsolute?: Node['positionAbsolute']): Node['position'] {
  return (
    positionAbsolute ?? {
      x: 0,
      y: 0,
    }
  );
}

export function calculateNodePositionInsideGroup(
  groupPosition: XYPosition,
  childPosition: XYPosition,
): XYPosition {
  return {
    x: Math.abs(groupPosition.x - childPosition.x),
    y: Math.abs(groupPosition.y - childPosition.y),
  };
}

export function calculateNodePositionOutsideGroup(childNode: FlowNode): XYPosition {
  const positionAbsolute = getNodePositionAbsolute(childNode.positionAbsolute);

  return {
    x: positionAbsolute.x,
    y: positionAbsolute.y,
  };
}

const PREDEFINED_JOB_NAMES: Record<string, string> = {
  [PIPELINE_START_NODE_ID]: PIPELINE_START_NAME,
  [PIPELINE_COMPLETE_NODE_ID]: PIPELINE_COMPLETE_NAME,
};
const PREDEFINED_JOB_DESCRIPTIONS: Record<string, string> = {
  [PIPELINE_START_NODE_ID]:
    'Determines the run start of the pipeline and what data must be collected for the pipeline to run.',
  [PIPELINE_COMPLETE_NODE_ID]:
    'Collects data meant to be displayed at the end of the pipeline’s run.',
};

export function getJobTitle(job: { id?: string; name?: string; customTitle?: string }) {
  const title = job.customTitle ?? job.name;

  if (title) return title;

  return PREDEFINED_JOB_NAMES[job.id ?? ''];
}

export function getJobName(job: { id?: string; name?: string }) {
  if (job.name) return job.name;

  return PREDEFINED_JOB_NAMES[job.id ?? ''];
}

export function getJobDescription(job: { id?: string; description?: string }) {
  if (job.description) return job.description;

  return PREDEFINED_JOB_DESCRIPTIONS[job.id ?? ''];
}

export function isJobNode(node?: Pick<FlowNode, 'type'>): node is JobNode {
  return !isGroupNode(node);
}
