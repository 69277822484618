import PlatformApiSdk from '@monksflow/platform-api-sdk';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { KeyedMutator, SWRConfiguration, SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { redirectToLogin } from 'utils/redirectToLogin';

type BaseConfig<Data, Error> = SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>;

export type Config<Data, Error = unknown> = Omit<BaseConfig<Data, Error>, 'fallbackData'> & {
  /**
   * Allows conditional fetching. To fetch, use `mutate` from `useRequest` response.
   * @see https://swr.vercel.app/docs/conditional-fetching
   * @example
   * const { pipelineJobs, pipelineJobsError, queryPipelineJobs } = usePipelineJobs(
   *  pipelineExecution.id,
   *  {
   *    shouldFetch: false,
   *  },
   * );
   *
   * // Fetch jobs conditionally. Errors are handled in `error` from `useRequest` response.
   * if(condition === true){
   *   void queryPipelineJobs();
   * }
   */
  shouldFetch?: boolean;
  /**
   * initial data to be returned (note: ***This is per-hook***)
   */
  fallbackData?: MutatorData<Data>;
};

type MutatorData<Data> = Pick<AxiosResponse<Data>, 'data'> & Partial<AxiosResponse<Data>>;

export type Mutator<Data> = KeyedMutator<MutatorData<Data>>;

interface Response<Data, Error>
  extends Omit<SWRResponse<AxiosResponse<Data>, AxiosError<Error>>, 'data' | 'mutate'> {
  data: Data | undefined;
  response: AxiosResponse<Data> | undefined;
  mutate: Mutator<Data>;
}

export const API_ACCEPT_HEADER = 'application/json;v=1';

axios.defaults.baseURL = import.meta.env.API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common = {
  Accept: API_ACCEPT_HEADER,
};

let refreshTokenPromise: Promise<unknown> | null = null;
axios.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response?.status !== 401) {
      return Promise.reject(error);
    }

    try {
      if (refreshTokenPromise) {
        // Avoids infinite loop.
        return Promise.reject(error);
      } else {
        // Avoids multiple requests to refresh the token.
        refreshTokenPromise = PlatformApiSdk.refreshToken();
      }

      await refreshTokenPromise;

      if (!error.config) {
        return Promise.reject(error);
      }

      try {
        // Retry the original request.
        return await axios.request(error.config);
      } catch (error) {
        // Catch to avoid redirecting to login page.
        return Promise.reject(error);
      }
    } catch (_refreshError) {
      redirectToLogin();
    } finally {
      refreshTokenPromise = null;
    }
  },
);

export function useRequest<Data, Error = unknown>(
  request: AxiosRequestConfig,
  config?: Config<Data, Error>,
): Response<Data, Error> {
  const [bypassShouldFetch, setBypassShouldFetch] = useState(false);
  const canFetch = (config?.shouldFetch ?? true) || bypassShouldFetch;
  const {
    data: axiosResponse,
    mutate,
    ...swrResponse
  } = useSWRImmutable<AxiosResponse<Data>, AxiosError<Error>>(
    canFetch ? request.url : null,
    async () => axios.request<Data>(request),
    {
      errorRetryCount: 3,
      errorRetryInterval: 1000,
      revalidateOnMount: true,
      ...(config as BaseConfig<Data, Error>),
    },
  );

  /**
   * Bypass the `shouldFetch` config to behave similarly to `trigger` in `useSWRMutation`.
   * @see https://swr.vercel.app/docs/mutation.en-US#useswrmutation
   */
  const query = useCallback(
    (...args: Parameters<typeof mutate>) => {
      setBypassShouldFetch(true);
      return mutate(...args);
    },
    [mutate],
  ) as Mutator<Data>;

  return {
    ...swrResponse,
    data: axiosResponse?.data,
    response: axiosResponse,
    mutate: query,
  };
}
