import { HandleConnection, NodeType } from 'features/Flow/Flow.types';
import {
  NodeInput,
  NodeOutput,
  PathwayNode,
  isPathwayNode,
} from 'features/Flow/nodes/Node/Node.types';
import { Edge } from 'reactflow';
import {
  PipelineBatchEndJob,
  PipelineBatchJob,
  PipelineBatchStartJob,
  PipelineJob,
  PipelineNeuronJob,
} from 'types/pipeline';
import { FlowNode, FlowNodeBase, FlowNodeData } from 'types/reactflow';

export type BatchNodeType = NodeType.BATCH_GROUP | NodeType.BATCH_START | NodeType.BATCH_END;

export type BatchNode = BatchGroupNode | BatchStartNode | BatchEndNode;

export function isBatchNode(node?: Pick<FlowNode, 'type'>): node is BatchNode {
  return [isBatchGroupNode(node), isBatchStartNode(node), isBatchEndNode(node)].some(Boolean);
}

interface BaseBatchData extends FlowNodeData {
  metadata: {
    name: string;
    type: BatchNodeType;
    description: string;
  };
}

export type BatchJobNode = BatchStartNode | BatchEndNode | PathwayNode;

export function isBatchJobNode(node?: Pick<FlowNode, 'type'>): node is BatchJobNode {
  return [isBatchStartNode(node), isBatchEndNode(node), isPathwayNode(node)].some(Boolean);
}

type WithRequiredId<T> = T & {
  id: string;
};

export type BatchInput = WithRequiredId<NodeInput>;
export type BatchOutput = WithRequiredId<NodeOutput>;

export interface BatchGroupData extends BaseBatchData {
  inputs: BatchInput[];
  outputs: BatchOutput[];
  color: string;
}

export interface BatchGroupSaveData extends BatchGroupNode {
  data: BatchGroupData & {
    jobs: BatchJobNode[];
  };
}

export interface BatchGroupNode extends FlowNodeBase<BatchGroupData> {
  type: NodeType.BATCH_GROUP;
}

export function isBatchGroupNode(node?: Pick<FlowNode, 'type'>): node is BatchGroupNode {
  return node?.type === NodeType.BATCH_GROUP;
}

export interface BatchStartData extends BaseBatchData {
  inputs: NodeInput[];
}

export interface BatchStartNode extends FlowNodeBase<BatchStartData> {
  type: NodeType.BATCH_START;
}

export function isBatchStartNode(node?: Pick<FlowNode, 'type'>): node is BatchStartNode {
  return node?.type === NodeType.BATCH_START;
}

export interface BatchEndData extends BaseBatchData {
  outputs: NodeOutput[];
}

export interface BatchEndNode extends FlowNodeBase<BatchEndData> {
  type: NodeType.BATCH_END;
}

export function isBatchEndNode(node?: Pick<FlowNode, 'type'>): node is BatchEndNode {
  return node?.type === NodeType.BATCH_END;
}

export function isBatchChildNode(node: Pick<FlowNode, 'parentNode'>, nodes: FlowNode[]) {
  return (
    !!node.parentNode &&
    nodes.some((flowNode) => flowNode.id === node.parentNode && isBatchGroupNode(flowNode))
  );
}

// Type guards for Job types
export function isBatchGroupJob(job: PipelineJob): job is PipelineBatchJob {
  return job.data.metadata.type === NodeType.BATCH_GROUP;
}

export function isBatchStartJob(job: PipelineJob): job is PipelineBatchStartJob {
  return job.data.metadata.type === NodeType.BATCH_START;
}

export function isBatchEndJob(job: PipelineJob): job is PipelineBatchEndJob {
  return job.data.metadata.type === NodeType.BATCH_END;
}

export function isBatchNeuronJob(job: PipelineJob): job is PipelineNeuronJob {
  return job.data.metadata.type === NodeType.NEURON || job.data.metadata.type === NodeType.FUNCTION;
}

export type BatchInputEdge<TData = BatchInput> = Edge<TData> &
  HandleConnection & {
    data: TData;
  };

export function isBatchInputEdge(edge: Edge, nodeId: string): edge is BatchInputEdge {
  return edge.target === nodeId && !!edge.data;
}

export type NewBatchInputEdge = Omit<BatchInputEdge, 'id'> & {
  id?: BatchInputEdge['id'];
};

export type BatchOutputEdge<TData = BatchOutput> = Edge<TData> &
  HandleConnection & {
    data: TData;
  };

export function isBatchOutputEdge(edge: Edge, nodeId: string): edge is BatchOutputEdge {
  return edge.source === nodeId && !!edge.data;
}

export interface BatchDetailedFailure {
  name: string;
  stack: string;
  message?: string;
  details: {
    jobId: string;
    jobName: string;
    manifestKey: string;
    iteration: number;
    requestId: string;
    inputs: unknown;
    failure?: { message?: string };
  }[];
}

export function isBatchDetailedFailure(
  detailedFailure: unknown,
): detailedFailure is BatchDetailedFailure {
  const df = detailedFailure as BatchDetailedFailure | undefined;
  if (df && typeof df === 'object' && Array.isArray(df.details) && df.details.length > 0) {
    return (
      typeof df.name === 'string' &&
      typeof df.stack === 'string' &&
      typeof df.message === 'string' &&
      typeof df.details[0] === 'object' &&
      typeof df.details[0].jobId === 'string'
    );
  }
  return false;
}
