import { NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { FormGroup, Stack } from '@mui/material';
import { Control, useFormContext } from 'react-hook-form';
import RHFTextField from 'components/ui/rhf-components/RHFTextField/RHFTextField';
import {
  NodeOutputFormData,
  PIPELINE_START_DATA_TYPE_ITEMS,
  PIPELINE_START_ARRAY_TYPE_ITEMS,
} from '../PipelineStartModal/PipelineStartModal.types';
import { mapDataTypeOption } from 'features/Flow/components/DataTypeField/DataTypeField.utils';
import { getDataMenuItemValue } from 'utils/dataSchema';

interface PipelineStartFormProps {
  control: Control<NodeOutputFormData>;
  otherOutputs: NodeOutput[];
  isConnected: boolean;
  isPendingConnection?: boolean;
}

const PipelineStartForm = ({
  control,
  otherOutputs,
  isConnected,
  isPendingConnection,
}: PipelineStartFormProps) => {
  const { formState, watch } = useFormContext<NodeOutputFormData>();

  const validateDuplicateName = (name?: string | null) => {
    if (!otherOutputs.filter((n) => n.name === name).length) {
      return true;
    }
    return 'Cannot contain duplicate keys.';
  };

  return (
    <FormGroup>
      <Stack direction="column" spacing={1}>
        <RHFTextField
          name="name"
          control={control}
          fullWidth
          label="Key"
          error={!!formState.errors.name}
          helperText={
            isConnected
              ? 'You cannot update the key of an input with a connection.'
              : formState.errors.name?.message
          }
          required
          rules={{
            required: 'This field is required.',
            validate: validateDuplicateName,
          }}
          disabled={isConnected}
        />

        <RHFTextField
          select
          name="dataType"
          control={control}
          label="Data Type"
          required
          fullWidth
          helperText={
            isConnected || isPendingConnection
              ? 'You cannot update the data type of an input with a connection.'
              : formState.errors.name?.message
          }
          rules={{ required: 'This field is required.' }}
          disabled={isConnected || isPendingConnection}
        >
          {PIPELINE_START_DATA_TYPE_ITEMS.map(({ schema, title }) =>
            mapDataTypeOption({
              schema,
              title,
              value: getDataMenuItemValue(schema),
            }),
          )}
        </RHFTextField>
        {watch('dataType') === 'array' && (
          <RHFTextField
            select
            name="arrayDataType"
            control={control}
            label="Array Data Type"
            required
            fullWidth
            defaultValue="text"
            helperText={
              isConnected || isPendingConnection
                ? 'You cannot update the data type of an input with a connection.'
                : formState.errors.name?.message
            }
            rules={{ required: 'This field is required.' }}
            disabled={isConnected || isPendingConnection}
          >
            {PIPELINE_START_ARRAY_TYPE_ITEMS.map(({ schema, title }) =>
              mapDataTypeOption({
                schema,
                title,
                value: getDataMenuItemValue(schema),
              }),
            )}
          </RHFTextField>
        )}

        <RHFTextField name="title" control={control} fullWidth label="Title" />
      </Stack>
    </FormGroup>
  );
};

export default PipelineStartForm;
