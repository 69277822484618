import { ArrowDropDown } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { PipelineState } from '@pathways/pipeline-schema/web';
import { PipelineVersionItem } from 'api/services/usePipelineVersions/usePipelineVersions.types';
import { useState } from 'react';

interface VersionSelectProps {
  selectedId: string;
  versions: PipelineVersionItem[];
  onChange: (versionId: string) => void;
}

const VersionSelect = ({ versions, selectedId, onChange }: VersionSelectProps) => {
  const [anchor, setAnchor] = useState<null | HTMLButtonElement>(null);

  const selectedVersion = versions.find(({ id }) => id === selectedId);
  const selectedVersionLabel = `Version ${selectedVersion?.version} ${
    selectedVersion?.state === PipelineState.DRAFT ? '(Draft)' : ''
  }`;
  const hasVersions = versions.length > 0;
  const dropdownVersionLabel = hasVersions ? selectedVersionLabel : 'No versions';

  const closeMenu = () => {
    setAnchor(null);
  };

  return (
    <>
      <Button
        data-testid="VersionSelect"
        variant="outlined"
        disabled={!hasVersions}
        endIcon={
          <ArrowDropDown
            sx={{
              transform: `rotate(${!anchor ? '0' : '180'}deg)`,
              transition: 'transform 0.2s',
            }}
          />
        }
        onClick={(e) => {
          setAnchor(e.currentTarget);
        }}
        sx={{
          flexShrink: 0,
        }}
      >
        {dropdownVersionLabel}
      </Button>
      <Menu
        open={!!anchor}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'right',
        }}
        onClose={closeMenu}
      >
        {versions.map(({ version, id, state }) => {
          const showDivider = state === PipelineState.DRAFT && versions.length > 1;
          return (
            <MenuItem
              key={id}
              value={id}
              divider={showDivider}
              onClick={() => {
                onChange(id);
                closeMenu();
              }}
            >
              Version {version} {state === PipelineState.DRAFT ? '(Draft)' : ''}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default VersionSelect;
