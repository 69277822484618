import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import OpenInNewTab from 'components/OpenInNewTab/OpenInNewTab';
import { NodeType } from 'features/Flow/Flow.types';
import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AssetLoader from '../../../AssetLoader/AssetLoader';
import JobInfoTitle from '../../../JobInfoTitle/JobInfoTitle';
import { ExecutionCheckpointErrorMessages } from '../../ExecutionCheckpoint.type';
import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';
import * as Styled from '../ExecutionCheckpointForm.styles';
import { ExecutionCheckpointImageFormData } from './ExecutionCheckpointImageForm.types';

const areInitialValuesArray = (initialValues: unknown): initialValues is string[] => {
  return Array.isArray(initialValues);
};

const ExecutionCheckpointImageForm = ({
  disabled,
  input,
  pendingCheckpoint,
  pendingCheckpointHeader,
}: ExecutionCheckpointFormProps) => {
  const { metadata, data } = pendingCheckpoint;
  const initialValues = data.inputs[input.name];

  if (!areInitialValuesArray(initialValues)) {
    throw new Error('Initial values must be an array');
  }

  const { control, register, setValue, watch } = useFormContext<ExecutionCheckpointImageFormData>();
  const { name } = register(input.name, {
    value: [],
    validate: (values) => values.length > 0 || ExecutionCheckpointErrorMessages.NO_INPUT_SELECTED,
  });
  const formValues = watch(name, []);
  const areAllSelected = formValues.length === initialValues.length;

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const updatedValues = checked ? initialValues : [];
    setValue(name, updatedValues, { shouldValidate: true });
  };

  return (
    <Styled.FormContainer sx={{ gap: 3 }}>
      <Styled.CheckpointApprovalHeader>
        <JobInfoTitle
          displaySecondaryName
          type={NodeType.CHECKPOINT}
          name={metadata.name}
          customTitle={metadata.customTitle}
        />

        <Styled.CheckpointApprovalRightBox>
          <FormControlLabel
            disabled={disabled}
            label="Select All"
            control={
              <Checkbox
                checked={areAllSelected}
                indeterminate={formValues.length > 0 && !areAllSelected}
                onChange={handleSelectAllChange}
              />
            }
          />

          <Styled.CheckpointApprovalHeaderChip>
            {pendingCheckpointHeader}
          </Styled.CheckpointApprovalHeaderChip>
        </Styled.CheckpointApprovalRightBox>
      </Styled.CheckpointApprovalHeader>

      <FormGroup>
        <Controller
          name={name}
          control={control}
          disabled={disabled}
          render={({ field }) => (
            <Styled.CheckpointApprovalCardContainer>
              {initialValues.map((imageUrl) => (
                <AssetLoader
                  key={imageUrl}
                  asset={{
                    signedUrl: imageUrl,
                    pathwayAssetId: imageUrl,
                    mediaType: 'image/png', // to render asset as image
                  }}
                  leftAction={
                    <FormControlLabel
                      disabled={field.disabled}
                      label="Select Image"
                      control={
                        <Checkbox
                          checked={field.value.includes(imageUrl)}
                          value={imageUrl}
                          onChange={(event, checked) => {
                            const updatedValues = checked
                              ? [...field.value, event.target.value]
                              : field.value.filter((value) => value !== event.target.value);

                            field.onChange(updatedValues);
                          }}
                        />
                      }
                    />
                  }
                  rightAction={<OpenInNewTab href={imageUrl} linkText="Open URL" />}
                />
              ))}
            </Styled.CheckpointApprovalCardContainer>
          )}
        />
      </FormGroup>
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointImageForm;
