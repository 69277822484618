import { Box, Typography } from '@mui/material';

interface PanelCardProps {
  title: React.ReactNode;
  description: React.ReactNode;
  children?: React.ReactNode;
}

export default function PanelCard(props: PanelCardProps) {
  const { title, description, children } = props;

  return (
    <Box
      sx={{
        display: 'grid',
        justifyItems: 'center',
        gap: 2,
      }}
    >
      {title}

      <Typography
        variant="bodyMedium"
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          textAlign: 'center',
        })}
      >
        {description}
      </Typography>

      {children}
    </Box>
  );
}
