import { AccountTreeOutlined, DvrOutlined, ViewAgendaOutlined } from '@mui/icons-material';
import {
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { PipelineExecution } from 'api/services/usePipelineExecution/usePipelineExecution.types';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import Loader from 'components/Loader/Loader';
import { NavTabsItems } from 'components/NavTabs/NavTabs';
import RuntimeChip from 'components/RuntimeChip/RuntimeChip';
import { formatDistanceToNow } from 'date-fns';
import { useExecutionContextOptional } from 'pages/ExecutionsPage/contexts/execution/useExecutionContext';
import { Link } from 'react-router-dom';
import { formatStatusDateTime, isPipelineExecutionActive } from 'utils/execution';
import { useAppRoutes } from 'utils/routes';

interface ViewExecutionNavDetailsProps {
  loading: boolean;
  pipelineExecution: PipelineExecution;
}

export default function ViewExecutionNavDetails(props: ViewExecutionNavDetailsProps) {
  const routes = useAppRoutes();
  const context = useExecutionContextOptional();
  const { loading, pipelineExecution } = props;
  const {
    createdAt,
    completedAt,
    executionNumber,
    status,
    version,
    pipelineId,
    id: executionId,
  } = pipelineExecution;

  const canDisplayLog = !!context;
  const displayLogTooltip = canDisplayLog
    ? 'Open run log'
    : 'Run log not available in pipeline view';

  const viewModeTabs: NavTabsItems = [
    {
      key: 'execution-view',
      to: routes.executions.view(pipelineId, executionId),
      label: <ViewAgendaOutlined fontSize="small" />,
      title: 'Execution view',
    },
    {
      key: 'runtime-view',
      to: routes.runtime(pipelineId, executionId),
      label: <AccountTreeOutlined fontSize="small" />,
      title: 'Pipeline view',
    },
  ];

  const statusLabel = isPipelineExecutionActive(status) ? 'Started' : 'Finished';
  const relativeStatusDateTime = formatDistanceToNow(completedAt ?? createdAt, {
    addSuffix: true,
    includeSeconds: true,
  });
  const statusDateTime = status === ExecutionStatus.COMPLETED ? completedAt : createdAt;
  const dateTimeTooltip = formatStatusDateTime(status, statusDateTime)?.dateTimeLabel;

  return (
    <Stack
      direction="row"
      data-testid="ExecutionSummary"
      sx={{
        justifyContent: 'space-between',
        width: '100%',
        pl: 2,
      }}
    >
      <Loader data-testid="details-loader" loading={loading}>
        <Stack
          direction="row"
          gap={2}
          sx={{
            alignItems: 'center',
          }}
        >
          <Typography variant="titleMedium">Run {executionNumber}</Typography>

          <Divider
            orientation="vertical"
            sx={(theme) => ({
              height: theme.spacing(3),
            })}
          />

          <Typography variant="bodyMedium" color="textSecondary">
            Pipeline{' '}
            <Typography variant="titleSmall" color="textPrimary">
              Version {version}
            </Typography>
          </Typography>

          <Divider
            orientation="vertical"
            sx={(theme) => ({
              height: theme.spacing(3),
            })}
          />

          <Typography variant="bodyMedium" color="textSecondary">
            {statusLabel}{' '}
            <Tooltip title={dateTimeTooltip}>
              <Typography variant="titleSmall" color="textPrimary">
                {relativeStatusDateTime}
              </Typography>
            </Tooltip>
          </Typography>
        </Stack>
      </Loader>

      <Loader data-testid="status-loader" loading={loading}>
        <Stack
          direction="row"
          sx={{
            gap: 1.5,
            alignItems: 'center',
          }}
        >
          <Tooltip title={displayLogTooltip}>
            <span>
              <ToggleButton
                value="display-run-log"
                disabled={!canDisplayLog}
                selected={context?.displayLogPanel}
                aria-label="toggle run log panel"
                size="small"
                onClick={() => {
                  context?.setOrToggleDisplayLogPanel();
                }}
              >
                <DvrOutlined fontSize="small" />
              </ToggleButton>
            </span>
          </Tooltip>

          <ToggleButtonGroup
            value={location.pathname}
            exclusive
            aria-label="view mode"
            size="small"
          >
            {viewModeTabs.map((tab) => (
              <Tooltip key={tab.key} title={tab.title}>
                <ToggleButton value={tab.to} aria-label={tab.title} component={Link} to={tab.to}>
                  {tab.label}
                </ToggleButton>
              </Tooltip>
            ))}
          </ToggleButtonGroup>

          <RuntimeChip
            status={status}
            startedAt={createdAt}
            completedAt={completedAt}
            version={version}
          />
        </Stack>
      </Loader>
    </Stack>
  );
}
