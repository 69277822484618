import { useStore, NodeProps } from 'reactflow';
import { FlowExecutionJob, FlowState } from '../../../types/reactflow';
import { shallow } from 'zustand/shallow';
import { useMemo } from 'react';
import { NodeType } from '../Flow.types';
import { isBatchDetailedFailure } from '../nodes/Batch/Batch.types';

const selector = ({ executionJobs }: FlowState) => ({
  executionJobs,
});

function failureOccurredInChildNode(id: string, job: FlowExecutionJob) {
  const detailedFailure = job.detailedFailure;
  return isBatchDetailedFailure(detailedFailure) && detailedFailure.details[0].jobId === id;
}

export function useGetNodeExecutionErrors({ id }: Pick<NodeProps, 'id'>) {
  const { executionJobs } = useStore(selector, shallow);

  const execution = useMemo(
    () =>
      executionJobs?.find((job) => {
        if (job.id === id) {
          return true;
        }
        return job.type === NodeType.BATCH_GROUP && failureOccurredInChildNode(id, job);
      }),
    [executionJobs, id],
  );

  return {
    errors: execution?.detailedFailure,
  };
}
