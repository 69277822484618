import { Sidebar } from 'components/Sidebar/Sidebar.styles';
import ElementLayers from 'features/Flow/components/ElementLayers/ElementLayers';

export default function LeftSidebar() {
  return (
    <Sidebar $width={284} $borderRight>
      <ElementLayers />
    </Sidebar>
  );
}
