import { Stack, Typography } from '@mui/material';
import CopyButton from 'components/CopyButton/CopyButton';

interface PipelineIdFieldWithCopyActionProps {
  pipelineId?: string;
}

const PipelineIdFieldWithCopyAction = ({ pipelineId }: PipelineIdFieldWithCopyActionProps) => {
  return (
    <Stack
      sx={{
        gap: 1,
      }}
    >
      <Typography
        variant="labelSmall"
        sx={{
          color: 'text.secondary',
        }}
      >
        Pipeline ID
      </Typography>
      <CopyButton label={pipelineId} size="small" value={String(pipelineId)} />
    </Stack>
  );
};

export default PipelineIdFieldWithCopyAction;
