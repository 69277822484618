import { Button, styled } from '@mui/material';
import RoundedPaper from 'components/RoundedPaper/RoundedPaper';

export const Main = styled(RoundedPaper)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 24px;
`;

export const ExecutionCheckpointData = styled('div')`
  width: 100%;
`;

export const ExecutionCheckpointApproveButtonContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.surface.level1};
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const ExecutionCheckpointApproveButton = styled(Button)`
  border-radius: 1.5rem;
  width: 12rem;
`;

export const ExecutionCheckpointRejectButton = styled(Button)`
  border-radius: 1.5rem;
`;

export const ExecutionCheckpointForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
