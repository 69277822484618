import { TextField } from '@mui/material';
import { BatchGroupData } from 'features/Flow/nodes/Batch/Batch.types';
import { GroupData } from 'features/Flow/nodes/Group/Group.types';
import { SectionDivider } from 'components/Sidebar/Sidebar.styles';
import { getGroupName } from 'utils/group';

interface BaseGroupFormProps {
  label: string;
  name: string;
  description: string;
  onChangeData: (data: Partial<GroupData | BatchGroupData['metadata']>) => void;
  children?: React.ReactNode;
  readOnly?: boolean;
}

export default function BaseGroupForm(props: BaseGroupFormProps) {
  const { label, name, description, onChangeData, children, readOnly = false } = props;

  return (
    <>
      <TextField
        fullWidth
        name="groupName"
        label={`${label} Name`}
        placeholder={label}
        value={name}
        size="small"
        slotProps={{
          input: {
            readOnly,
          },
        }}
        onChange={(event) => {
          onChangeData({
            name: event.target.value,
          });
        }}
        onBlur={() => {
          onChangeData({ name: getGroupName(name ? name : label) });
        }}
      />
      <TextField
        fullWidth
        multiline
        name="groupDescription"
        label={`${label} Description`}
        placeholder={`Describe this ${label}...`}
        value={description}
        margin="normal"
        size="small"
        onChange={(event) => {
          onChangeData({
            description: event.target.value,
          });
        }}
        slotProps={{
          input: {
            readOnly,
          },
        }}
      />
      <SectionDivider />
      {children}
    </>
  );
}
