import { Button, Stack } from '@mui/material';
import styled from 'styled-components';
const ViewButton = styled(Button)`
  border-radius: 24px;
  padding: 4px ${({ theme }) => theme.spacing(2)};
  color: ${({ variant, theme }) => (variant === 'text' ? theme.palette.text.secondary : 'default')};
`;

interface ExecutionHeaderSubsectionProps {
  view: 'all' | 'output';
  onViewChange: (view: 'all' | 'output') => void;
}

export default function ExecutionHeaderSubsection({
  view,
  onViewChange,
}: ExecutionHeaderSubsectionProps) {
  return (
    <Stack direction="row">
      <ViewButton
        variant={view === 'output' ? 'contained' : 'text'}
        onClick={() => {
          onViewChange('output');
        }}
      >
        Pipeline Completion
      </ViewButton>
      <ViewButton
        variant={view === 'all' ? 'contained' : 'text'}
        onClick={() => {
          onViewChange('all');
        }}
      >
        All Neuron Outputs
      </ViewButton>
    </Stack>
  );
}
