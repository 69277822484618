import { Box, FormControlLabel, Link } from '@mui/material';
import { Checkbox, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import RHFTextField from 'components/ui/rhf-components/RHFTextField/RHFTextField';
import {
  ARRAY_TYPE_MENU_ITEMS,
  DATA_MENU_ITEMS,
  OBJECT_DATA_MENU_ITEMS,
} from '../../Function.types';
import { DataManipulatorFormData } from '../DataManipulator.types';
import { requiredValidation } from 'components/ui/rhf-components/utils/validationRules';
import { FC } from 'react';
import { mapDataTypeOption } from 'features/Flow/components/DataTypeField/DataTypeField.utils';
import { getDataMenuItemValue } from 'utils/dataSchema';

interface DataManipulatorFormProps {
  disableInputType: boolean;
  disableOutputType: boolean;
}

const DataManipulatorForm: FC<DataManipulatorFormProps> = ({
  disableOutputType,
  disableInputType,
}) => {
  const { control, watch } = useFormContext<DataManipulatorFormData>();

  return (
    <Box>
      <RHFTextField
        name="query"
        control={control}
        fullWidth
        label="Command"
        multiline={true}
        minRows={4}
        required
        rules={requiredValidation}
        sx={{ my: 1 }}
        placeholder="Enter data..."
        helperText={
          <span>
            Write JQ commands to manipulate data. More information can be found{' '}
            <Link href="https://jqlang.org/manual/" target="_blank">
              here
            </Link>
            .
          </span>
        }
      />

      <Typography
        component="p"
        variant="labelLarge"
        sx={{
          mt: 1,
        }}
      >
        Flags
      </Typography>
      <Controller
        name="slurp"
        defaultValue={false}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            label="Slurp"
            control={<Checkbox checked={field.value} onChange={field.onChange} />}
          />
        )}
      />

      <Typography
        component="p"
        variant="labelLarge"
        sx={{
          mt: 3,
        }}
      >
        Input
      </Typography>
      <RHFTextField
        select
        name="input"
        control={control}
        label="Data Type"
        required
        sx={{ my: 1, mt: 1 }}
        fullWidth
        rules={requiredValidation}
        disabled={disableInputType}
        helperText={
          disableInputType
            ? 'You cannot update the data type of an input with a connection.'
            : undefined
        }
      >
        {OBJECT_DATA_MENU_ITEMS.map(({ schema, title }) =>
          mapDataTypeOption({
            schema,
            title,
            value: schema.type,
          }),
        )}
      </RHFTextField>

      <Typography
        component="p"
        variant="labelLarge"
        sx={{
          mt: 3,
        }}
      >
        Output
      </Typography>
      <RHFTextField
        select
        name="output"
        control={control}
        label="Data Type"
        required
        sx={{ my: 1, mt: 1 }}
        fullWidth
        rules={requiredValidation}
        disabled={disableOutputType}
        helperText={
          disableOutputType
            ? 'You cannot update the data type of an output with a connection.'
            : undefined
        }
      >
        {DATA_MENU_ITEMS.map(({ schema, title }) =>
          mapDataTypeOption({
            schema,
            title,
            value: getDataMenuItemValue(schema),
          }),
        )}
      </RHFTextField>
      {watch('output') === 'array' && (
        <>
          <Typography
            variant="labelLarge"
            sx={{
              mt: 3,
            }}
          >
            Output Array
          </Typography>
          <RHFTextField
            select
            name="outputArrayType"
            control={control}
            label="Data Type"
            defaultValue="text"
            required
            fullWidth
            sx={{ my: 1, mt: 1 }}
            rules={requiredValidation}
            disabled={disableOutputType}
          >
            {ARRAY_TYPE_MENU_ITEMS.map(({ schema, title }) =>
              mapDataTypeOption({
                schema,
                title,
                value: getDataMenuItemValue(schema),
              }),
            )}
          </RHFTextField>
        </>
      )}
    </Box>
  );
};

export default DataManipulatorForm;
