import useAddPipelineTrigger from 'api/services/useAddPipelineTrigger/useAddPipelineTrigger';
import usePipelineTriggers from 'api/services/usePipelineTriggers/usePipelineTriggers';
import usePipelineId from 'hooks/usePipelineId';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppRoutes } from 'utils/routes';
import CreateTriggerDialog, {
  CreateTriggerDialogProps,
} from '../TriggerDialogs/CreateTriggerDialog';
import TriggerCreatedDialog from '../TriggerDialogs/TriggerCreatedDialog';
import TriggersTable from '../TriggersTable/TriggersTable';

export default function TriggersSection() {
  const navigate = useNavigate();
  const routes = useAppRoutes();
  const pipelineId = usePipelineId();
  const { areTriggersLoading, areTriggersValidating, triggers, triggersError, mutateTriggers } =
    usePipelineTriggers(pipelineId);
  const { addPipelineTrigger } = useAddPipelineTrigger();

  const displayLoading: boolean = areTriggersLoading || areTriggersValidating;
  const displayError: boolean = !displayLoading && !!triggersError;

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const closeCreateDialog = () => {
    setCreateDialogOpen(false);
  };

  const createTrigger: CreateTriggerDialogProps['onCreate'] = async (values) => {
    const { data } = await addPipelineTrigger({
      pipelineId,
      name: values.name,
      notes: values.notes,
    });

    setCreatedDialogState({
      open: true,
      triggerId: data.id,
      token: data.token,
    });

    void mutateTriggers();
  };

  const [createdDialogState, setCreatedDialogState] = useState({
    open: false,
    triggerId: '',
    token: '',
  });
  const closeCreatedDialog = () => {
    setCreatedDialogState({
      open: false,
      triggerId: '',
      token: '',
    });
  };
  const viewTriggerDetails = () => {
    navigate(routes.viewTrigger(pipelineId, createdDialogState.triggerId));
    closeCreatedDialog();
  };

  return (
    <>
      <TriggersTable
        loading={displayLoading}
        loadingFailed={displayError}
        triggers={triggers}
        onCreateTriggerClick={() => {
          setCreateDialogOpen(true);
        }}
        onRowClick={(triggerId) => {
          navigate(routes.viewTrigger(pipelineId, triggerId));
        }}
      />

      {createDialogOpen && (
        <CreateTriggerDialog closeDialog={closeCreateDialog} onCreate={createTrigger} />
      )}

      {createdDialogState.open && (
        <TriggerCreatedDialog
          token={createdDialogState.token}
          closeDialog={closeCreatedDialog}
          onViewTrigger={viewTriggerDetails}
        />
      )}
    </>
  );
}
