import { ColumnConfig } from 'components/Table/TableHead';
import { CSSProperties } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { getSelectAllState } from './DynamicTable.utils';
import { TABLE_COMPONENTS } from './TableComponentMap';
import useDynamicTable from './useDynamicTable';

export const ROW_HEIGHT = 77;

export type GenericObject = Record<string, unknown>;

export interface DynamicTableProps {
  config?: ColumnConfig[];
  maxHeight?: CSSProperties['maxHeight'];
  objects: GenericObject[];
  readOnly?: boolean;
  selectable?: boolean;
  onChange?: (selectedRows: GenericObject[]) => void;
}

const DynamicTable = (props: DynamicTableProps) => {
  const { config, maxHeight, objects, readOnly, selectable, onChange } = props;
  const {
    columns,
    displayRowForm,
    order,
    orderBy,
    selectedRows,
    tableRows,
    handleAddRow,
    handleRowFormCancel,
    handleRowFormConfirm,
    handleSelectAll,
    handleSelectRow,
    handleSort,
    handleRowEditConfirm,
  } = useDynamicTable({ config, objects, onChange });

  const headerRowCount = 1;
  const footerRowCount = readOnly ? 0 : 1;
  const tableRowCount = headerRowCount + tableRows.length + footerRowCount;
  const height = ROW_HEIGHT * tableRowCount;

  return (
    <TableVirtuoso
      style={{
        height,
        maxHeight,
      }}
      components={TABLE_COMPONENTS}
      data={tableRows}
      defaultItemHeight={ROW_HEIGHT}
      // Used as flag to render the TableHead component.
      fixedHeaderContent={() => true}
      // Used as flag to render the TableFoot component.
      fixedFooterContent={() => true}
      context={{
        readOnly,
        selectable,
        columns,
        displayRowForm,
        tableHeadProps: {
          disabled: false,
          order,
          orderBy,
          selectAllState: getSelectAllState(tableRows.length, selectedRows.length),
          onSelectAll: !readOnly && selectable ? handleSelectAll : undefined,
          onSort: handleSort,
        },
        tableBodyProps: {
          onCancel: handleRowFormCancel,
          onConfirm: handleRowFormConfirm,
        },
        tableRowProps: {
          onConfirmEditRow: handleRowEditConfirm,
          onSelectRow: handleSelectRow,
        },
        tableFootProps: {
          onAddRow: handleAddRow,
        },
      }}
    />
  );
};

export default DynamicTable;
