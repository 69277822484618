import { Stack, Typography } from '@mui/material';
import { usePipeline } from 'api/services/usePipeline/usePipeline';
import usePipelineTrigger from 'api/services/usePipelineTriggers/usePipelineTrigger';
import BackButton from 'components/BackButton/BackButton';
import { Layout, MuiContainer, ScrollContainer } from 'components/Layout/Layout.styles';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import useToast from 'contexts/toast/useToast';
import usePipelineId from 'hooks/usePipelineId';
import usePipelineTriggerId from 'hooks/usePipelineTriggerId';
import { useEffect, useState } from 'react';
import { useAppRoutes } from 'utils/routes';
import AboutSection from './components/AboutSection/AboutSection';
import DeleteSection from './components/DeleteSection/DeleteSection';
import InstructionsSection from './components/InstructionsSection/InstructionsSection';

export default function PipelineTriggerPage() {
  const toast = useToast();
  const routes = useAppRoutes();
  const pipelineId = usePipelineId();
  const triggerId = usePipelineTriggerId();
  const { pipeline, pipelineError } = usePipeline(pipelineId);

  const [pipelineVersionId, setPipelineVersionId] = useState<string>();
  const { isTriggerLoading, trigger, triggerError } = usePipelineTrigger(
    pipelineId,
    triggerId,
    pipelineVersionId,
  );

  useEffect(() => {
    if (pipelineError) {
      toast.error({
        message: 'Failed to load pipeline',
      });
    }
  }, [pipelineError, toast]);

  useEffect(() => {
    if (triggerError) {
      toast.error({
        message: 'Failed to load trigger',
      });
    }
  }, [toast, triggerError]);

  return (
    <Layout>
      <NavigationBar
        hideHome
        title={pipeline?.name ?? 'Pipeline'}
        firstBreadcrumb={{
          label: (
            <BackButton to={routes.settings(pipelineId)}>
              <Typography variant="titleMedium">View API Trigger</Typography>
            </BackButton>
          ),
        }}
      />

      <ScrollContainer>
        <MuiContainer
          fixed
          component={Stack}
          gap={4}
          sx={{
            justifyContent: 'flex-start',
            '&&': {
              maxWidth: 732,
            },
          }}
        >
          <AboutSection loading={isTriggerLoading} trigger={trigger} />
          <InstructionsSection
            loading={isTriggerLoading}
            trigger={trigger}
            pipelineVersionId={pipelineVersionId}
            onVersionChange={setPipelineVersionId}
          />
          {trigger && <DeleteSection pipelineId={pipelineId} trigger={trigger} />}
        </MuiContainer>
      </ScrollContainer>
    </Layout>
  );
}
