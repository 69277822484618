import { useParams } from 'react-router-dom';
import { formatUrlMissingParameterMessage } from 'utils/message';

export default function usePipelineTriggerId(): string {
  const { triggerId } = useParams();

  if (!triggerId) {
    throw new Error(formatUrlMissingParameterMessage('triggerId'));
  }

  return triggerId;
}
