import React, { useState } from 'react';
import { getFeatureFlagsFromParam } from './FeatureFlag.utils';
import { FeatureFlagContext } from './FeatureFlagContext';
import { AppPermission } from './FeatureFlag.types';
import useAuthContext from 'contexts/auth/useAuthContext';

const ADMIN_FLAG = 'admin';

export type FeatureFlags = Record<string, boolean>;

// Temporary solution to provide feature flags to the app until we have a proper solution using a server based and user permission based feature flagging tool
const FeatureFlagProvider = ({
  testFlags,
  children,
}: React.PropsWithChildren<{ testFlags?: FeatureFlags }>) => {
  const [featureFlags] = useState<FeatureFlags>(testFlags ?? getFeatureFlagsFromParam());
  const { user } = useAuthContext();
  const isEnabled = (feature: string) => {
    if (featureFlags[ADMIN_FLAG]) {
      return true;
    }

    return !!featureFlags[feature];
  };

  const isAllowed = (permissions: AppPermission[]) => {
    return permissions.every((permission) => !!user?.permissions.includes(permission));
  };

  return (
    <FeatureFlagContext.Provider
      value={{
        flags: featureFlags,
        isEnabled,
        isAllowed,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
