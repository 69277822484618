export const getExampleValue = (dataSchema) => {
    switch (dataSchema.type) {
        case 'text':
            return 'Example text';
        case 'number':
        case 'integer':
            return 42;
        case 'image-uri':
            return 'example.com/image.png';
        case 'enum':
            return ['value1', 'value2'];
        case 'object':
            return { key1: 'value1', key2: 'value2' };
        case 'array':
            switch (dataSchema.items.type) {
                case 'text':
                    return ['Example text 1', 'Example text 2'];
                case 'number':
                case 'integer':
                    return [42, 123];
                case 'image-uri':
                    return ['example.com/image1.png', 'example.com/image2.png'];
                case 'asset':
                    return ['asset id 1', 'asset id 2'];
                case 'object':
                    return [{ key1: 'value1' }, { key1: 'value1' }];
                default:
                    return [];
            }
        case 'asset':
            return {
                pathwayAssetId: 'c3RhdGljL3BpcGVsaW5lcy81MGRmMDdlOC01ZDY1LTQ4NDQtYjJiNy0wYTVhZmY2YjFkZDcvYWVkYjMyMWEtZTEyNS00NGUwLWJmZDYtMzY2MTI2ZTEzYTBk',
                mediaType: 'image/jpeg',
            };
        case 'boolean':
            return true;
        default:
            return 'default value';
    }
};
