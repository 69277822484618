import { useEventSource } from 'api/useEventSource';
import useToast from 'contexts/toast/useToast';
import { useEffect } from 'react';

export interface ExecutionLog {
  message: string;
  level: 'info' | 'error' | 'warn';
  [key: string]: unknown;
}

const usePipelineExecutionLogs = (executionId: string) => {
  const toast = useToast();
  const { data, connected, close, reconnect } = useEventSource<string, ExecutionLog>({
    url: `/pipeline-executions/${executionId}/logs`,
    onMessage: (msg) => JSON.parse(msg.data) as ExecutionLog,
    onError(err) {
      console.error(err);
      toast.error({
        message: 'Execution log stream disconnected',
      });
    },
  });

  useEffect(() => {
    if (!data.length) {
      return;
    }

    const latest = data[data.length - 1];
    if (
      latest.__logType === 'Workflow' &&
      (latest.status === 'completed' || latest.status === 'failed')
    ) {
      close();
    }
  }, [data, close]);

  return {
    pipelineExecutionLogs: data,
    connected,
    reconnect,
  };
};

export default usePipelineExecutionLogs;
