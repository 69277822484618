import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { Button, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import ActionsMenu from 'components/ActionsMenu/ActionsMenu';
import useCopyElementsToClipboard from 'features/Flow/hooks/useCopyElementsToClipboard/useCopyElementsToClipboard';
import useFlow from 'features/Flow/hooks/useFlow';
import useRemoveGroup from 'features/Flow/hooks/useRemoveGroup';
import { BatchGroupNode, isBatchGroupNode } from 'features/Flow/nodes/Batch/Batch.types';
import { GroupNode, isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import ShortcutCaption from 'components/ShortcutCaption/ShortcutCaption';
import { SHORTCUT_CONFIG } from 'config/shortcut';
import { useCallback, useEffect } from 'react';
import { useKeyPress } from 'reactflow';

interface HeaderProps {
  groupNode: GroupNode | BatchGroupNode;
  readOnly?: boolean;
}

export default function Header(props: HeaderProps) {
  const { groupNode, readOnly } = props;
  const ungroupPressed = useKeyPress(SHORTCUT_CONFIG.ungroup.keyCode, {
    target: document.body,
  });
  const removeGroup = useRemoveGroup();
  const copyElementsToClipboard = useCopyElementsToClipboard();
  const { deleteElements } = useFlow();

  const isBatchGroup = isBatchGroupNode(groupNode);

  useEffect(() => {
    if (!ungroupPressed || isBatchGroup) return;

    removeGroup(groupNode.id);
  }, [groupNode.id, removeGroup, ungroupPressed, isBatchGroup]);

  const getRenderActions = useCallback(
    (closeMenu: () => void) => {
      const actions = [
        <MenuItem
          key="copy"
          onClick={() => {
            closeMenu();
            void copyElementsToClipboard();
          }}
        >
          <ListItemText>Copy</ListItemText>
          <ShortcutCaption>{SHORTCUT_CONFIG.copy.caption}</ShortcutCaption>
        </MenuItem>,
      ];

      if (isGroupNode(groupNode)) {
        actions.push(
          <MenuItem
            key="ungroup"
            onClick={() => {
              closeMenu();
              removeGroup(groupNode.id);
            }}
          >
            <ListItemText>Ungroup</ListItemText>
            <ShortcutCaption>{SHORTCUT_CONFIG.ungroup.caption}</ShortcutCaption>
          </MenuItem>,
        );
      }

      actions.push(
        <MenuItem
          key="delete"
          onClick={() => {
            closeMenu();
            deleteElements({
              nodes: [{ id: groupNode.id }],
            });
          }}
        >
          <ListItemText
            sx={{
              color: 'error.main',
            }}
          >
            Delete
          </ListItemText>
          <ShortcutCaption>{SHORTCUT_CONFIG.delete.caption}</ShortcutCaption>
        </MenuItem>,
      );

      return actions;
    },
    [copyElementsToClipboard, deleteElements, groupNode, removeGroup],
  );

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="bodySmall">{`1 ${
        isBatchGroup ? 'batch' : 'group'
      } selected`}</Typography>

      <ActionsMenu
        renderButton={(props) => (
          <Button {...props} endIcon={<ArrowDropDownIcon />} variant="outlined" disabled={readOnly}>
            Actions
          </Button>
        )}
        renderActions={getRenderActions}
        MenuListProps={{
          sx: {
            minWidth: 220,
          },
        }}
      />
    </Stack>
  );
}
