import { InfoOutlined } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { VERSION_SEPARATOR } from '@pathways/pipeline-schema/web';
import { NodeManifest, isSubpipelineManifest } from 'features/Flow/Flow.types';
import { getValueOrDefault } from 'utils/string';
import * as Styled from './SidebarElementCard.styles';

interface SidebarElementCardTooltipProps {
  manifest: NodeManifest;
}

const SidebarElementCardTooltip = ({ manifest }: SidebarElementCardTooltipProps) => {
  const version = isSubpipelineManifest(manifest)
    ? manifest.pipeline.version
    : manifest.key?.split(VERSION_SEPARATOR)[1] ?? '';
  const maintainers = manifest.maintainers ?? [];
  const description = getValueOrDefault(manifest.description.trim(), 'No description');

  return (
    <Styled.ElementTooltip
      title={
        <Stack
          sx={{
            gap: 0.5,
          }}
        >
          {Boolean(version) && (
            <Typography
              variant="labelSmall"
              sx={{
                color: 'text.secondary',
              }}
            >
              Version {version}
            </Typography>
          )}
          <Typography variant="bodySmall">{description}</Typography>
          {maintainers.length > 0 && (
            <>
              <Typography
                variant="labelSmall"
                sx={{
                  color: 'text.secondary',
                }}
              >
                Maintainers:
              </Typography>
              <Styled.MaintainerList>
                {maintainers.map((m) => (
                  <Styled.MaintainerListItem key={m}>
                    <Typography variant="bodySmall">{m}</Typography>
                  </Styled.MaintainerListItem>
                ))}
              </Styled.MaintainerList>
            </>
          )}
        </Stack>
      }
      placement="bottom"
    >
      <IconButton
        size="small"
        sx={{
          marginRight: -0.5,
        }}
        component="span"
      >
        <InfoOutlined fontSize="small" />
      </IconButton>
    </Styled.ElementTooltip>
  );
};

export default SidebarElementCardTooltip;
