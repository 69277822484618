import { Add as AddIcon } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import usePipelineExecutions from 'api/services/usePipelineExecutions/usePipelineExecutions';
import usePipelineId from 'hooks/usePipelineId';
import { Link, useNavigate } from 'react-router-dom';
import { useAppRoutes } from 'utils/routes';
import ExecutionRunsTable from './components/ExecutionRunsTable/ExecutionRunsTable';

export default function ExecutionRunsPage() {
  const routes = useAppRoutes();
  const pipelineId = usePipelineId();
  const {
    executions,
    areExecutionsLoading,
    areExecutionsValidating,
    executionsError,
    mutateExecutions,
  } = usePipelineExecutions(pipelineId);
  const navigate = useNavigate();

  const displayLoading = areExecutionsLoading || areExecutionsValidating;
  const displayError = !displayLoading && !!executionsError;
  const displayTable = !displayError && !!executions.length;
  const displayEmptyState = !displayError && !executions.length;

  const refreshExecutions = () => {
    void mutateExecutions();
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 3,
        }}
      >
        <Typography variant="titleLarge">Runs</Typography>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'center',
          }}
        >
          <Tooltip title="Refresh data">
            <IconButton onClick={refreshExecutions}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>

          <Button
            component={Link}
            to={routes.executions.new(pipelineId)}
            startIcon={<AddIcon />}
            variant="outlined"
          >
            Start New Run
          </Button>
        </Stack>
      </Stack>

      {displayEmptyState && (
        <Stack
          sx={{
            display: 'grid',
            gap: 2,
            textAlign: 'center',
            maxWidth: 480,
            marginTop: 2,
            marginX: 'auto',
          }}
        >
          <Typography variant="titleLarge">No runs created</Typography>
          <Typography
            variant="bodyLarge"
            sx={{
              color: 'text.secondary',
            }}
          >
            No runs have been created for this pipeline yet. Create your first run by selecting the
            “Start New Run“ button above.
          </Typography>
        </Stack>
      )}

      {Boolean(displayTable) && (
        <ExecutionRunsTable
          loading={displayLoading}
          loadingFailed={displayError}
          executions={executions}
          onRowClick={(executionId) => {
            navigate(routes.executions.view(pipelineId, executionId));
          }}
        />
      )}
    </>
  );
}
