import { BoxProps, Tooltip, TooltipProps } from '@mui/material';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import { getStatusConfig } from 'utils/status';
import * as Styled from './StatusIcon.styles';

interface StatusIconProps extends Pick<BoxProps, 'className' | 'color' | 'sx'> {
  status: ExecutionStatus | undefined;
  containerProps?: BoxProps;
  tooltipTitle?: TooltipProps['title'];
}

export default function StatusIcon(props: StatusIconProps) {
  const { status, color, containerProps, tooltipTitle, ...iconProps } = props;
  const statusConfig = getStatusConfig(status).icon;

  if (!statusConfig) return null;

  const { component: Icon, color: baseColor, ...iconConfig } = statusConfig;
  const config: {
    container: BoxProps;
    icon: typeof iconConfig;
  } = {
    container: {
      display: 'inline-flex',
      color: color ?? `${baseColor}.main`,
    },
    icon: {
      ...iconConfig,
      fontSize: 'small',
    },
  };

  return (
    <Tooltip title={tooltipTitle}>
      <Styled.Container {...config.container} {...containerProps}>
        <Icon {...config.icon} {...iconProps} />
      </Styled.Container>
    </Tooltip>
  );
}
