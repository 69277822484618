import React from 'react';
import { useFeatureFlag } from './useFeatureFlag';
import { AppFeatures } from './FeatureFlag.const';
import { AppPermission } from './FeatureFlag.types';
import { usePermissions } from './usePermissions';

interface FeatureFlagProps {
  feature: AppFeatures;
  permissions?: AppPermission[];
  fallback?: React.ReactNode;
}

const FeatureFlag = ({
  feature,
  fallback,
  children,
  permissions,
}: React.PropsWithChildren<FeatureFlagProps>) => {
  const isFeatureEnabled = useFeatureFlag(feature);
  const hasPermissions = usePermissions(permissions ?? []);

  if (feature === 'none') {
    return hasPermissions ? children : fallback ?? null;
  }

  return isFeatureEnabled && hasPermissions ? children : fallback ?? null;
};

export default FeatureFlag;
