import { FormControlLabel, Switch, Typography, useTheme } from '@mui/material';
import DynamicTable from 'components/DynamicTable/DynamicTable';
import { getPageContentHeight } from 'components/DynamicTable/DynamicTable.utils';
import { objectValidation } from 'components/ui/rhf-components/utils/validationRules';
import { NodeType } from 'features/Flow/Flow.types';
import { findTableSchemaCheckpointInput } from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/tableSchemaInput';
import { NodeObjectValue } from 'features/Flow/nodes/Node/Node.types';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import JobInfoTitle from '../../../JobInfoTitle/JobInfoTitle';
import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';
import * as Styled from '../ExecutionCheckpointForm.styles';
import ExecutionCheckpointObjectListJsonEditor from './ExecutionCheckpointObjectListJsonEditor';
import ViewSwitchConfirmationModal from './ViewSwitchConfirmationModal';

const ExecutionCheckpointObjectListForm = ({
  disabled,
  input,
  pendingCheckpoint,
  pendingCheckpointHeader,
}: ExecutionCheckpointFormProps) => {
  const { metadata } = pendingCheckpoint;
  const initialValue = pendingCheckpoint.data.inputs[input.name] as NodeObjectValue[];
  const tableConfig = findTableSchemaCheckpointInput(pendingCheckpoint.data.inputs);

  const controller = useController<Record<string, NodeObjectValue[]>>({
    name: input.name,
    rules: {
      required: true,
      ...objectValidation,
    },
  });
  const { field } = controller;
  const { onChange: controllerOnChange } = field;

  const theme = useTheme();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [editAsJson, setEditAsJson] = useState(false);

  const tableMaxHeight = `calc(100vh - ${getPageContentHeight()}px)`;

  const toggleView = () => {
    setEditAsJson((prev) => !prev);
  };

  const handleViewSwitch = () => {
    setShowConfirmModal(true);
  };

  const confirmViewSwitch = () => {
    setShowConfirmModal(false);
    // Set delay so the modal has time to close then switch the view
    setTimeout(() => {
      toggleView();
    }, theme.transitions.duration.short);
  };

  return (
    <Styled.FormContainer
      sx={{
        gap: 2,
      }}
    >
      <Styled.CheckpointApprovalHeader>
        <JobInfoTitle
          displaySecondaryName
          type={NodeType.CHECKPOINT}
          name={metadata.name}
          customTitle={metadata.customTitle}
        />

        <FormControlLabel
          control={<Switch checked={editAsJson} onChange={handleViewSwitch} />}
          label={<Typography variant="bodyMedium">Edit as JSON</Typography>}
        />

        <Styled.CheckpointApprovalRightBox>
          <Styled.CheckpointApprovalHeaderChip>
            {pendingCheckpointHeader}
          </Styled.CheckpointApprovalHeaderChip>
        </Styled.CheckpointApprovalRightBox>
      </Styled.CheckpointApprovalHeader>

      {editAsJson ? (
        <ExecutionCheckpointObjectListJsonEditor
          disabled={disabled}
          controller={controller}
          initialValue={initialValue}
        />
      ) : (
        <DynamicTable
          config={tableConfig}
          maxHeight={tableMaxHeight}
          objects={initialValue}
          selectable
          onChange={controllerOnChange}
        />
      )}
      <ViewSwitchConfirmationModal
        open={showConfirmModal}
        view={editAsJson ? 'json' : 'table'}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={confirmViewSwitch}
      />
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointObjectListForm;
