import { Typography } from '@mui/material';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { getStatusConfig } from 'utils/status';
import * as Styled from './StatusChip.styles';

interface StatusChipProps {
  className?: string;
  status: ExecutionStatus | undefined;
  variant?: 'default' | 'elevated' | 'icon';
}

export default function StatusChip(props: StatusChipProps) {
  const { className, status, variant = 'default' } = props;
  const statusConfig = getStatusConfig(status);
  const config: typeof statusConfig.chip = {
    ...statusConfig.chip,
    size: 'small',
    sx: {
      '.MuiChip-label': {
        paddingX: 1.25,
      },
    },
  };

  if (variant === 'icon') {
    return (
      <Styled.MuiChip
        {...config}
        className={className}
        icon={<StatusIcon status={status} />}
        label={
          <Typography
            variant="labelMedium"
            sx={{
              color: 'text.primary',
            }}
          >
            {config.label}
          </Typography>
        }
        $variant="elevated"
      />
    );
  }

  return <Styled.MuiChip {...config} className={className} $variant={variant} />;
}
