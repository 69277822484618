import { FC } from 'react';
import { PipelineExecutionFormLoaderProps } from './PipelineExecutionFormLoader.types';
import { Grid2 } from '@mui/material';
import * as Styled from './PipelineExecutionFormLoader.styles';

const PipelineExecutionFormLoader: FC<PipelineExecutionFormLoaderProps> = (props) => {
  return (
    <Grid2 container spacing={3}>
      {[...Array(props.quantity).keys()].map((i) => (
        <Grid2 key={i} size={6}>
          <Styled.ParagraphLoader variant="rounded" width={80} height={12} />
          <Styled.ParagraphLoader variant="rounded" width={'100%'} height={29} />
        </Grid2>
      ))}
    </Grid2>
  );
};

export default PipelineExecutionFormLoader;
