import useAuthContext from 'contexts/auth/useAuthContext';
import { Outlet } from 'react-router-dom';
import Splash from 'components/Splash/Splash';

function AuthenticatedRoute() {
  const auth = useAuthContext();

  if (auth.isUserAuthenticated && auth.user) {
    return <Outlet />;
  }

  return <Splash />;
}

export default AuthenticatedRoute;
