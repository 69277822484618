import { Schedule } from '@mui/icons-material';
import { Divider, Stack, Typography, css, styled } from '@mui/material';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import StatusChip from 'components/StatusChip/StatusChip';
import Timer from 'components/Timer/Timer';
import { TimerProps } from 'components/Timer/Timer.types';
import { getStatusConfig } from 'utils/status';

interface RuntimeChipProps extends Pick<TimerProps, 'startedAt' | 'completedAt'> {
  status: ExecutionStatus | undefined;
  version: string;
}

export default function RuntimeChip(props: RuntimeChipProps) {
  const { status, startedAt, completedAt, version } = props;
  const config = getStatusConfig(status);

  const timer = <Timer status={status} startedAt={startedAt} completedAt={completedAt} />;
  const displayTimerOnly = status === ExecutionStatus.PENDING;

  if (displayTimerOnly) {
    return timer;
  }

  return (
    <RuntimeChipRoot data-testid="RuntimeChip" direction="row" gap={1.5}>
      <StatusChip status={status} variant="icon" />

      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
        }}
      >
        <Schedule fontSize="small" color={config.icon?.color} sx={{ mr: 1 }} />
        <Typography variant="labelSmall">{timer}</Typography>
      </Stack>

      <Divider
        orientation="vertical"
        sx={(theme) => ({
          height: theme.spacing(3),
        })}
      />

      <Typography variant="bodySmall" color="textPrimary">
        Version {version}
      </Typography>
    </RuntimeChipRoot>
  );
}

export const RuntimeChipRoot = styled(Stack)(
  ({ theme }) => css`
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.palette.surface.level2};
    padding: ${theme.spacing(0.75)};
    padding-right: ${theme.spacing(2)};
    border-radius: ${theme.shape.borderRadius * 5}px;
  `,
);
