import { format } from 'date-fns';

export const getValueOrDefault = <TValue>(value?: TValue, defaultValue = '-'): TValue | string => {
  if (value == null || value === '') return defaultValue;
  return value;
};

export const titleCase = (value: string) => {
  // Support title casing snake case, kebab, and white space for
  const splitRegex = /[-_.\s]/g;
  const converted = value
    .split(splitRegex)
    .map((val) => val.charAt(0).toUpperCase() + val.slice(1, val.length).toLowerCase())
    .join(' ');
  return converted;
};

export const isUrl = (value: string) => {
  try {
    new URL(value);
    return true;
  } catch (_error) {
    return false;
  }
};

/**
 * Join a list of strings with a custom final join text to support formatting such as "a, b, and c"
 * @param items Array of strings
 * @param separator The joiner between all items except the last
 * @param endJoin [Optional] Final joiner between the last two items. If the items list is empty or single, then this is ignored.
 * @returns
 */
export const formatJoin = (items: string[], separator: string, endJoin?: string): string => {
  if (items.length > 2) {
    return `${items.slice(0, -1).join(separator)}${endJoin ? `, ${endJoin} ` : separator}${
      items[items.length - 1]
    }`;
  } else if (items.length === 2) {
    return items.join(endJoin ? ` ${endJoin} ` : separator);
  }

  return items[0] ?? '';
};

export function formatDate(value?: Parameters<typeof format>[0]) {
  if (!value) return '–';

  return format(value, "MM/dd/yyyy 'at' hh:mm aaa");
}

export function replaceSpaceWithUnderscore<TValue>(str: TValue) {
  if (!str) return str;
  if (typeof str !== 'string') return str;
  return str.replaceAll(' ', '_');
}
