import { NodeInput } from '../../../Node/Node.types';
import { Button, Grid2 } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { NodeInputFormData } from '../ObjectBuilder.types';
import ObjectBuilderForm from './ObjectBuilderForm';
import { useCallback, useMemo } from 'react';
import { createHandleId } from 'features/Flow/Flow.utils';
import { useFindHandleEdge } from 'features/Flow/hooks/useFindHandleEdge';
import * as Styled from 'components/Dialog/Dialog.styles';
import { createBuilderDataSchema } from '../ObjectBuilder.utils';
import { isArrayDataSchema } from '@pathways/pipeline-schema/web';

interface ObjectBuilderEditFormProps {
  nodeId: string;
  nodeInputs: NodeInput[];
  inputToEdit: NodeInput;
  onCancelEdit: () => void;
  onConfirmEdit: (input: NodeInput) => void;
  onNameChange: (oldName: string, newName: string) => void;
}

const createDefaultValue = (edit: NodeInput): NodeInputFormData => {
  return {
    name: edit.name,
    title: edit.title,
    dataType: edit.dataSchema.type,
    arrayDataType: isArrayDataSchema(edit.dataSchema) ? edit.dataSchema.items.type : undefined,
  };
};

const ObjectBuilderEditForm: React.FC<ObjectBuilderEditFormProps> = ({
  nodeId,
  nodeInputs,
  inputToEdit,
  onCancelEdit,
  onConfirmEdit,
  onNameChange,
}) => {
  const findEdge = useFindHandleEdge({ nodeId });
  const form = useForm<NodeInputFormData>({
    defaultValues: createDefaultValue(inputToEdit),
    mode: 'onBlur',
  });

  const isConnected = !!findEdge(createHandleId('input', inputToEdit.name));

  const inputsNotInEdit = useMemo(() => {
    return nodeInputs.filter((n) => n.name !== inputToEdit.name);
  }, [nodeInputs, inputToEdit.name]);

  const submitForm: SubmitHandler<NodeInputFormData> = useCallback(
    (data) => {
      if (data.name !== inputToEdit.name) {
        onNameChange(inputToEdit.name, data.name);
      }
      onConfirmEdit({
        ...inputToEdit,
        ...data,
        id: createHandleId('input', data.name),
        dataSchema: createBuilderDataSchema(data),
      });
    },
    [onConfirmEdit, onNameChange, inputToEdit],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  return (
    <FormProvider {...form}>
      <Styled.DialogTitle $hasBorder>Edit Input</Styled.DialogTitle>
      <Styled.DialogContent>
        <ObjectBuilderForm
          otherInputs={inputsNotInEdit}
          control={form.control}
          isConnected={isConnected}
        />
      </Styled.DialogContent>
      <Styled.DialogActions $hasBorder>
        <Grid2 container>
          <Button variant="text" sx={{ color: 'common.white' }} onClick={onCancelEdit}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={!form.formState.isValid}>
            Confirm
          </Button>
        </Grid2>
      </Styled.DialogActions>
    </FormProvider>
  );
};

export default ObjectBuilderEditForm;
