import {
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  FullscreenRounded as FullscreenRoundedIcon,
} from '@mui/icons-material';
import { Box, Collapse, Stack } from '@mui/material';
import ElementLayerButton from 'features/Flow/components/ElementLayerButton/ElementLayerButton';
import useGroupChildNodes from 'features/Flow/hooks/useGroupChildNodes';
import { GroupNode } from 'features/Flow/nodes/Group/Group.types';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { useStoreApi } from 'reactflow';
import { getGroupName } from 'utils/group';
import * as Styled from './GroupElementLayerButton.styles';
import { BatchGroupNode, isBatchGroupNode } from 'features/Flow/nodes/Batch/Batch.types';
import BatchIcon from 'components/Icons/Batch';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { useGetNodeExecutionErrors } from 'features/Flow/hooks/useGetNodeExecutionErrors';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';

interface GroupElementLayerButtonProps {
  node: GroupNode | BatchGroupNode;
}

export function GroupElementLayerButton(props: GroupElementLayerButtonProps) {
  const { node } = props;
  const { errors } = useGetNodeExecutionErrors({ id: node.id });
  const { mode, unselectNodesAndEdges, addSelectedNodes } = useStoreApi().getState();
  const name = isBatchGroupNode(node)
    ? getGroupName(node.data.metadata.name)
    : getGroupName(node.data.name);
  const childNodes = useGroupChildNodes(node.id);
  const openCollapse = !!node.selected || childNodes.some((node) => node.selected);

  const IconButton = isBatchGroupNode(node) ? BatchIcon : FullscreenRoundedIcon;

  return (
    <Styled.Container $expanded={openCollapse}>
      <Styled.GroupElementLayerButton
        $color={node.data.color}
        selected={node.selected}
        onClick={() => {
          if (node.selected) {
            unselectNodesAndEdges({
              nodes: [node],
            });
          } else {
            addSelectedNodes([node.id]);
          }
        }}
      >
        {mode !== 'runtime' && <IconButton className="icon" fontSize="small" />}
        <TextEllipsis variant="labelLarge">{name}</TextEllipsis>
        {errors && (
          <Box
            sx={{
              lineHeight: 0,
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              alignItems: 'flex-end',
              flexShrink: 0,
            }}
          >
            <StatusIcon
              status={ExecutionStatus.FAILED}
              color={node.selected ? 'currentcolor' : undefined}
              tooltipTitle={errors.message as string}
            />
          </Box>
        )}
        <ExpandMoreRoundedIcon className="expand-icon" color="action" fontSize="small" />
      </Styled.GroupElementLayerButton>

      <Collapse in={openCollapse}>
        <Stack
          spacing={1}
          sx={{
            marginTop: 1,
            marginLeft: 3.5,
          }}
        >
          {childNodes.map((node) => (
            <ElementLayerButton nested key={node.id} node={node} />
          ))}
        </Stack>
      </Collapse>
    </Styled.Container>
  );
}
