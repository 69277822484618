import { createContext } from 'react';
import { FeatureFlags } from './FeatureFlagProvider';
import { AppPermission } from './FeatureFlag.types';

/**
 * @param flags Flags that are set in the context by default for testing purposes.
 * @param isEnabled Function to check if a feature is enabled.
 */
interface FeatureFlagContext {
  flags: FeatureFlags;
  isEnabled: (flag: string) => boolean;
  isAllowed: (permissions: AppPermission[]) => boolean;
}

export const FeatureFlagContext = createContext<FeatureFlagContext>({
  flags: {},
  isEnabled: (_: string) => false,
  isAllowed: (_: AppPermission[]) => false,
});
