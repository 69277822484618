import { Checkbox, CheckboxProps, FormControlLabel, FormGroup } from '@mui/material';
import DownloadButton from 'components/DownloadButton';
import OpenInNewTab from 'components/OpenInNewTab/OpenInNewTab';
import { NodeType } from 'features/Flow/Flow.types';
import { SignedAssetObject, isSignedAssetObjectArray } from 'features/Flow/nodes/Node/Node.types';
import { ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { isOpenableAsset } from 'utils/assetObjects';
import AssetLoader from '../../../AssetLoader/AssetLoader';
import JobInfoTitle from '../../../JobInfoTitle/JobInfoTitle';
import { ExecutionCheckpointErrorMessages } from '../../ExecutionCheckpoint.type';
import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';
import useExecutionCheckpointContext from '../../contexts/useExecutionCheckpointContext';
import * as Styled from '../ExecutionCheckpointForm.styles';

const ExecutionCheckpointAssetListForm = (props: ExecutionCheckpointFormProps) => {
  const { disabled, input, pendingCheckpoint, pendingCheckpointHeader } = props;
  const { metadata: checkpointMetadata } = pendingCheckpoint;
  const initialValues = pendingCheckpoint.data.inputs[input.name];
  const { contextInputValue: assets = [], setContextInputValue } = useExecutionCheckpointContext();

  if (!isSignedAssetObjectArray(assets)) {
    throw new Error('Assets must be an array');
  }

  useEffect(() => {
    setContextInputValue(initialValues);
  }, [initialValues, setContextInputValue]);

  const { register, setValue, watch } = useFormContext<Record<string, SignedAssetObject[]>>();
  const { name } = register(input.name, {
    value: [],
    validate: (values) => values.length > 0 || ExecutionCheckpointErrorMessages.NO_INPUT_SELECTED,
  });
  const selectedAssets = watch(name, []);
  const selectedAssetIds = selectedAssets.map((asset) => asset.pathwayAssetId);
  const areAllSelected = selectedAssets.length === assets.length;

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const updatedValues = checked ? assets : [];

    setValue(name, updatedValues, { shouldValidate: true });
  };

  const handleAssetChange =
    (asset: SignedAssetObject): CheckboxProps['onChange'] =>
    (_event, checked) => {
      const updatedValues = checked
        ? [...selectedAssets, asset]
        : selectedAssets.filter(
            (selectedAsset) => selectedAsset.pathwayAssetId !== asset.pathwayAssetId,
          );

      setValue(name, updatedValues, { shouldValidate: true });
    };

  return (
    <Styled.FormContainer sx={{ gap: 3 }}>
      <Styled.CheckpointApprovalHeader>
        <JobInfoTitle
          displaySecondaryName
          type={NodeType.CHECKPOINT}
          name={checkpointMetadata.name}
          customTitle={checkpointMetadata.customTitle}
        />

        <Styled.CheckpointApprovalRightBox>
          <FormControlLabel
            disabled={disabled}
            label="Select All"
            control={
              <Checkbox
                checked={areAllSelected}
                indeterminate={selectedAssets.length > 0 && !areAllSelected}
                onChange={handleSelectAllChange}
              />
            }
          />

          <Styled.CheckpointApprovalHeaderChip>
            {pendingCheckpointHeader}
          </Styled.CheckpointApprovalHeaderChip>
        </Styled.CheckpointApprovalRightBox>
      </Styled.CheckpointApprovalHeader>

      <FormGroup>
        <Styled.CheckpointApprovalCardContainer>
          {assets.map((asset) => (
            <AssetLoader
              key={asset.pathwayAssetId}
              asset={asset}
              leftAction={
                <FormControlLabel
                  disabled={disabled}
                  label="Select Asset"
                  control={
                    <Checkbox
                      checked={selectedAssetIds.includes(asset.pathwayAssetId)}
                      onChange={handleAssetChange(asset)}
                    />
                  }
                />
              }
              rightAction={
                isOpenableAsset(asset) ? (
                  <OpenInNewTab href={asset.signedUrl} linkText="Open URL" />
                ) : (
                  <DownloadButton asset={asset} />
                )
              }
            />
          ))}
        </Styled.CheckpointApprovalCardContainer>
      </FormGroup>
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointAssetListForm;
