import { Button } from '@mui/material';
import BackButton from 'components/BackButton/BackButton';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { BreadcrumbText } from 'components/NavigationBar/NavigationBar.styles';
import { Link } from 'react-router-dom';
import { FlowPipeline } from 'types/reactflow';
import { useAppRoutes } from 'utils/routes';

interface NewExecutionNavProps {
  pipeline: FlowPipeline;
}

const NewExecutionNav = ({ pipeline }: NewExecutionNavProps) => {
  const { name, id } = pipeline;
  const routes = useAppRoutes();

  return (
    <NavigationBar
      title={name}
      firstBreadcrumb={{
        label: (
          <BackButton to={routes.executions.runs(id)}>
            <BreadcrumbText variant="titleMedium">New Run</BreadcrumbText>
          </BackButton>
        ),
      }}
      hideHome={true}
    >
      <Button component={Link} color="primary" variant="contained" to={routes.pipelines(id)}>
        Edit Pipeline
      </Button>
    </NavigationBar>
  );
};
export default NewExecutionNav;
