import { Add as AddIcon } from '@mui/icons-material';
import { CircularProgress, Grid2, InputAdornment, Stack, Typography } from '@mui/material';
import useCreatePipeline from 'api/services/useCreatePipeline/useCreatePipeline';
import usePaginatedPipelines from 'api/services/usePaginatedPipelines/usePaginatedPipelines';
import useWorkspaceProject from 'api/services/useWorkspaceProject/useWorkspaceProject';
import SearchIcon from 'components/Icons/Search';
import { Layout, MuiContainer, ScrollContainer } from 'components/Layout/Layout.styles';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import SearchField from 'components/SearchField/SearchField';
import useToast from 'contexts/toast/useToast';
import FeatureFlag from 'features/FeatureFlag/FeatureFlag';
import { HandleStoreProvider } from 'features/Flow/Handles/handle.provider';
import useProjectId from 'hooks/useProjectId';
import useWorkspace from 'hooks/useWorkspace';
import ImportPipeline from 'pages/DashboardPage/components/ImportPipeline/ImportPipeline';
import { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProjectName } from 'utils/project';
import { useAppRoutes } from 'utils/routes';
import * as Styled from './DashboardPage.styles';
import NoPipelinesCreatedCard from './components/NoPipelinesCreatedCard/NoPipelinesCreatedCard';
import PipelineSearchNotFoundCard from './components/PipelineSearchNotFoundCard/PipelineSearchNotFoundCard';
import PipelineTableContainer from './components/PipelineTableContainer/PipelineTableContainer';

export default function DashboardPage() {
  const navigate = useNavigate();
  const toast = useToast();
  const routes = useAppRoutes();
  const workspace = useWorkspace();
  const projectId = useProjectId();
  const { workspaceProject } = useWorkspaceProject(workspace.id, projectId);
  const projectName = getProjectName(projectId ? workspaceProject?.name : workspace.name);
  const { createPipeline, isSaving } = useCreatePipeline();

  const {
    searchQuery,
    isLoading,
    pipelines,
    pagination,
    pipelinesError,
    mutatePipelines,
    onSearchChange,
  } = usePaginatedPipelines({
    workspaceId: workspace.id,
    projectId,
  });
  const displayPipelinesLoading = isLoading;
  const displayPipelinesError = !!pipelinesError;
  const displayPipelines = displayPipelinesLoading || pipelines.length > 0 || displayPipelinesError;
  const displayPipelinesNotFound = !displayPipelines && searchQuery.length > 0;
  const displayPipelinesNotCreated = !displayPipelines && !displayPipelinesNotFound;

  const handleSearchPipelines = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onSearchChange(event.target.value);
  };

  const handlePageChange = (pageNumber: number) => {
    pagination.updatePageNumber(pageNumber);
  };

  const handleCreatePipeline = () => {
    createPipeline({ projectId, workspaceId: workspace.id })
      .then((response) => {
        navigate(routes.pipelines(response.data.id));
      })
      .catch((error: Error) => {
        toast.error({
          message: `Something went wrong. ${error.message}`,
        });
      });
  };

  return (
    <Layout>
      <NavigationBar title="Pipelines" firstBreadcrumb={{ label: 'Pipelines' }} />

      <ScrollContainer
        component="main"
        sx={{
          paddingX: 3,
        }}
      >
        <MuiContainer maxWidth="xl">
          <Styled.PipelinesListContainer>
            <Grid2
              container
              sx={{
                width: '100%',
              }}
            >
              <Grid2
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  width: '100%',
                  gap: 2,
                }}
              >
                <Styled.PathwaysProjectIcon />
                <Typography variant="titleLarge">{projectName}</Typography>
              </Grid2>
              <Grid2
                size={4}
                sx={{
                  textAlign: 'start',
                  mt: 3,
                  mb: 3,
                }}
              >
                {!displayPipelinesNotCreated && (
                  <SearchField
                    autoComplete="off"
                    fullWidth
                    onChange={handleSearchPipelines}
                    placeholder="Search..."
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                )}
              </Grid2>
              <Grid2
                size={8}
                sx={{
                  textAlign: 'end',
                  mt: 3,
                  mb: 3,
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                >
                  <FeatureFlag feature="pipelineExport">
                    <ImportPipeline />
                  </FeatureFlag>
                  <Styled.CreatePipelineButton
                    startIcon={!isSaving ? <AddIcon /> : null}
                    variant="contained"
                    disabled={isSaving}
                    onClick={handleCreatePipeline}
                  >
                    {isSaving ? (
                      <CircularProgress
                        color="warning"
                        size={18}
                        sx={(theme) => ({
                          color: theme.palette.action.disabled,
                        })}
                      />
                    ) : (
                      'Create Pipeline'
                    )}
                  </Styled.CreatePipelineButton>
                </Stack>
              </Grid2>
            </Grid2>
            {displayPipelines && (
              <HandleStoreProvider>
                <PipelineTableContainer
                  loading={displayPipelinesLoading}
                  loadingFailed={displayPipelinesError}
                  pipelines={pipelines}
                  pagination={pagination}
                  mutatePipelines={mutatePipelines}
                  onPageChange={handlePageChange}
                />
              </HandleStoreProvider>
            )}
            {displayPipelinesNotFound && <PipelineSearchNotFoundCard searchQuery={searchQuery} />}
            {displayPipelinesNotCreated && <NoPipelinesCreatedCard />}
          </Styled.PipelinesListContainer>
        </MuiContainer>
      </ScrollContainer>
    </Layout>
  );
}
