import { Skeleton } from '@mui/material';
import { JSX, useRef, useState } from 'react';

interface DynamicImageWithLoaderProps {
  imageUrl: string;
  alt: string;
}

const DynamicImageWithLoader = ({ imageUrl, alt }: DynamicImageWithLoaderProps): JSX.Element => {
  const imgRef = useRef<HTMLImageElement>();
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <>
      {loading && (
        <Skeleton
          className="image-loader"
          animation="wave"
          variant="rectangular"
          sx={{
            aspectRatio: '1',
          }}
        />
      )}
      <img
        ref={() => imgRef}
        style={{ display: loading ? 'none' : 'initial' }}
        alt={alt}
        crossOrigin="anonymous"
        src={imageUrl}
        decoding="async"
        onLoad={() => {
          setLoading(false);
        }}
      />
    </>
  );
};

export default DynamicImageWithLoader;
