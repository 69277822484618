import { Box, Stack, Typography, TypographyProps } from '@mui/material';
import styled, { css } from 'styled-components';

export const PipelineUserInfoBox = styled(Stack)(
  ({ theme }) => css`
    background: ${theme.palette.surface.level2};
    border-radius: 0.5rem;
    padding: ${theme.spacing(1.5)};

    > hr {
      background: ${theme.palette.surface.dividerMain};
      height: 2px;
    }
  `,
);

export const UserInfoSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const UserInfoSectionSecondaryContent = ({ children }: TypographyProps) => (
  <Typography
    variant="bodySmall"
    sx={{
      color: 'text.secondary',
    }}
  >
    {children}
  </Typography>
);

export const UserInfoSectionPrimaryContent = ({ children }: TypographyProps) => (
  <Typography variant="bodyMedium">{children}</Typography>
);
