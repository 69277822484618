import { PropsWithChildren, useCallback, useState } from 'react';
import { ExecutionContext, ExecutionContextValue } from './ExecutionContext';

export function ExecutionContextProvider({ children }: PropsWithChildren) {
  const [pageFooter, setPageFooter] = useState<ExecutionContextValue['pageFooter']>();
  const [displayLogPanel, setDisplayLogPanel] = useState(false);
  const setOrToggleDisplayLogPanel = useCallback((display?: boolean) => {
    setDisplayLogPanel((prev) => display ?? !prev);
  }, []);

  return (
    <ExecutionContext.Provider
      value={{
        pageFooter,
        displayLogPanel,
        setPageFooter,
        setOrToggleDisplayLogPanel,
      }}
    >
      {children}
    </ExecutionContext.Provider>
  );
}
