import { Circle as CircleIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useCallback } from 'react';
import { ColorFieldProps } from './ColorField.types';

interface ColorIconButtonProps extends Omit<ColorFieldProps, 'color'> {
  color: string;
}

export function ColorIconButton(props: ColorIconButtonProps) {
  const { color, value, onColorClick } = props;
  const selected = value === color;

  const handleClick = useCallback(() => {
    onColorClick(color);
  }, [color, onColorClick]);

  return (
    <IconButton onClick={handleClick}>
      <CircleIcon htmlColor={color} stroke={selected ? 'white' : undefined} strokeWidth={2} />
    </IconButton>
  );
}
