import { usePipeline } from 'api/services/usePipeline/usePipeline';
import { NavTabsItems } from 'components/NavTabs/NavTabs';
import { matchesSomeNavTab } from 'components/NavTabs/NavTabs.utils';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { useMatchesViewExecutionRoute } from 'hooks/useMatchesViewExecutionRoute';
import usePipelineId from 'hooks/usePipelineId';
import ExecutionsNav from 'pages/ExecutionsPage/components/ExecutionsNav/ExecutionsNav';
import NewExecutionNav from 'pages/ExecutionsPage/pages/NewExecutionPage/components/NewExecutionNav/NewExecutionNav';
import ViewExecutionNav from 'pages/ExecutionsPage/pages/ViewExecutionPage/components/ViewExecutionNav/ViewExecutionNav';
import { matchPath, useLocation } from 'react-router-dom';
import { useAppRoutes } from 'utils/routes';

const PageNavigation = () => {
  const routes = useAppRoutes();
  const pipelineId = usePipelineId();
  const { pipeline } = usePipeline(pipelineId);
  const { pathname } = useLocation();
  const matchesViewExecutionRoute = useMatchesViewExecutionRoute();

  if (!pipeline) {
    return <NavigationBar title="Pathways" />;
  }

  const executionsNavTabs: NavTabsItems = [
    {
      key: 'runs',
      label: 'Runs',
      to: routes.executions.runs(pipelineId),
    },
    {
      key: 'versions',
      label: 'Versions',
      to: routes.versions(pipelineId),
    },
    {
      key: 'settings',
      label: 'Settings',
      to: routes.settings(pipelineId),
    },
  ];
  const displayExecutionsNav = matchesSomeNavTab(executionsNavTabs);

  if (displayExecutionsNav) {
    return <ExecutionsNav pipeline={pipeline} navTabs={executionsNavTabs} />;
  }

  if (matchPath({ path: routes.executions.new(pipelineId) }, pathname)) {
    return <NewExecutionNav pipeline={pipeline} />;
  }

  const displayViewExecutionNav = [
    matchesViewExecutionRoute,
    matchPath(routes.runtime(pipelineId, ':pipelineExecutionId'), pathname),
  ].some(Boolean);

  if (displayViewExecutionNav) {
    return <ViewExecutionNav pipeline={pipeline} />;
  }

  return <NavigationBar title="Pathways" />;
};

export default PageNavigation;
