import { SvgIcon, SvgIconProps } from '@mui/material';

const FlowChartIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <g id="flowchart">
          <mask id="mask0_2164_3409" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
            <rect id="Bounding box" x="0.166626" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2164_3409)">
            <path
              id="flowchart_2"
              d="M15.3014 19.5V17.5672H11.4166V12.75H9.02238V14.673H2.66663V9.31723H9.02238V11.25H11.4166V6.42298H15.3014V4.49048H21.6666V9.85573H15.3014V7.92298H12.9166V16.0672H15.3014V14.1442H21.6666V19.5H15.3014ZM16.8011 18H20.1666V15.6442H16.8011V18ZM4.16663 13.173H7.52238V10.8172H4.16663V13.173ZM16.8011 8.35573H20.1666V5.99048H16.8011V8.35573Z"
              fill="white"
              fillOpacity="0.56"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default FlowChartIcon;
