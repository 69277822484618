import styled from 'styled-components';
import { Accordion } from '@mui/material';

export const ConnectedApisAccordion = styled(Accordion)`
  &.MuiAccordion-root::before {
    display: none;
  }

  padding: 0;

  &.Mui-expanded {
    margin-top: 0;
    padding-top: 0;
  }

  &.Mui-disabled {
    background: transparent;
  }
`;
