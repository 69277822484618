import { Config, useRequest } from 'api/client';
import { PipelineTriggerResponse } from 'api/services/usePipelineTriggers/usePipelineTriggers.types';

const usePipelineTriggers = (pipelineId: string, config?: Config<PipelineTriggerResponse[]>) => {
  const response = useRequest<PipelineTriggerResponse[]>(
    {
      url: `/pipelines/${pipelineId}/triggers`,
    },
    config,
  );

  return {
    areTriggersLoading: response.isLoading,
    areTriggersValidating: response.isValidating,
    triggers: response.data ?? [],
    triggersError: response.error,
    mutateTriggers: response.mutate,
  };
};

export default usePipelineTriggers;
