import {
  Checkbox,
  TableHead as MuiTableHead,
  TableCell,
  TableHeadProps,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { DataSchema } from '@pathways/pipeline-schema';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { forwardRef } from 'react';
import { Order } from 'utils/order';
import * as Styled from './Table.styles';

export type SelectAllState = 'all' | 'partial' | 'none';

export interface CustomTableHeadProps extends TableHeadProps {
  disabled: boolean;
  columns: ColumnConfig[];
  order?: Order;
  orderBy?: string;
  selectAllState?: SelectAllState;
  onSort?: (columnId: string) => void;
  onSelectAll?: () => void;
}

export interface ColumnConfig<TId = string> {
  id: TId;
  label: string;
  numeric?: boolean;
  orderProperty?: string;
  align?: 'left' | 'right' | 'center';
  minWidth?: number;
  maxWidth?: number;
  valueSchema?: DataSchema;
  valueType?:
    | 'string'
    | 'number'
    | 'bigint'
    | 'boolean'
    | 'symbol'
    | 'undefined'
    | 'object'
    | 'function';
}

const TableHead = forwardRef<HTMLTableSectionElement, CustomTableHeadProps>(
  function TableHead(props, ref) {
    const {
      columns,
      disabled,
      order,
      orderBy,
      selectAllState,
      onSelectAll,
      onSort,
      ...tableHeadProps
    } = props;

    return (
      <MuiTableHead {...tableHeadProps} ref={ref}>
        <Styled.TableHeadRow>
          {columns.map((column) => {
            const canSort = !disabled && !!column.orderProperty;
            const handleSort = canSort
              ? () => {
                  onSort?.(column.id);
                }
              : undefined;

            return (
              <TableCell
                key={column.id}
                align={column.align ?? column.numeric ? 'right' : 'left'}
                sortDirection={orderBy === column.id ? order : false}
                sx={{
                  cursor: canSort ? 'pointer' : undefined,
                }}
                onClick={handleSort}
              >
                <Typography
                  className="column-label"
                  variant="labelLarge"
                  sx={{
                    color: disabled ? 'text.disabled' : 'text.secondary',
                    display: 'flex',
                    minWidth: 0,
                  }}
                >
                  {canSort ? (
                    <TableSortLabel
                      data-testid={`table-column-${column.label}`}
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : undefined}
                      IconComponent={Styled.TableSortLabelIcon}
                    >
                      <TextEllipsis
                        sx={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                        }}
                      >
                        {column.label}
                      </TextEllipsis>
                    </TableSortLabel>
                  ) : (
                    <TextEllipsis>{column.label}</TextEllipsis>
                  )}
                </Typography>
              </TableCell>
            );
          })}
          {onSelectAll && (
            <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
              <Typography
                className="column-label"
                sx={{
                  color: disabled ? 'text.disabled' : 'text.secondary',
                }}
                variant="labelLarge"
              >
                Select All
                <Checkbox
                  edge="end"
                  checked={selectAllState === 'all'}
                  indeterminate={selectAllState === 'partial'}
                  sx={{ marginY: -1.5 }}
                  onClick={onSelectAll}
                />
              </Typography>
            </TableCell>
          )}
        </Styled.TableHeadRow>
      </MuiTableHead>
    );
  },
);

export default TableHead;
