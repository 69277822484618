import { Close as CloseIcon } from '@mui/icons-material';
import {
  Alert,
  AlertProps,
  IconButton,
  Snackbar,
  SnackbarCloseReason,
  SnackbarProps,
} from '@mui/material';

type DuplicatedKeys = Extract<keyof SnackbarProps, keyof AlertProps>;
type ToastSnackbarProps = Omit<SnackbarProps, DuplicatedKeys>;
type ToastAlertProps = Pick<AlertProps, 'action'> & Omit<AlertProps, DuplicatedKeys>;
type ToastCloseReason = SnackbarCloseReason | 'alert';

export interface ToastProps extends ToastSnackbarProps, ToastAlertProps {
  message: ToastSnackbarProps['message'];
  snackbarProps?: SnackbarProps;
  alertProps?: AlertProps;
  onClose: (event: React.SyntheticEvent | Event, reason: ToastCloseReason) => void;
}

export default function Toast(props: ToastProps) {
  const { open, anchorOrigin, autoHideDuration, message, action, severity, onClose } = props;

  const snackbarProps: SnackbarProps = {
    open,
    anchorOrigin,
    autoHideDuration,
    onClose,
    ...props.snackbarProps,
  };
  const alertProps: AlertProps = {
    severity,
    sx: {
      color: 'common.white',
      width: '100%',
      ...props.alertProps?.sx,
    },
    onClose(event) {
      onClose(event, 'alert');
    },
    ...props.alertProps,
  };

  return (
    <Snackbar key={message} sx={{ whiteSpace: 'pre-wrap' }} {...snackbarProps}>
      <Alert
        icon={false}
        variant="filled"
        action={
          <>
            {action}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={alertProps.onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        {...alertProps}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
