import { LinearProgress, LinearProgressProps, css, styled } from '@mui/material';

export default function ProgressBar(props: LinearProgressProps) {
  return <ProgressBarRoot variant="determinate" {...props} />;
}

const ProgressBarRoot = styled(LinearProgress)(
  ({ theme }) => css`
    height: 8px;
    border-radius: ${theme.shape.borderRadius * 2}px;
    background-color: ${theme.palette.surface.level0};

    & > .MuiLinearProgress-bar {
      border-radius: ${theme.shape.borderRadius * 2}px;
    }
  `,
);
