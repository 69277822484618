import WarningIcon from '@mui/icons-material/Warning';
import { Button, Typography } from '@mui/material';
import * as Styled from './ExecutionCard.styles';

interface ExecutionCardErrorProps {
  onRetry?: () => void;
}

export const ExecutionCardError = ({ onRetry }: ExecutionCardErrorProps) => (
  <Styled.BasicCard>
    <WarningIcon color="error" />
    <Typography
      align="center"
      variant="labelSmall"
      sx={{
        padding: '1rem 0.75rem',
      }}
    >
      An error occurred and the Runs of this pipeline could not be loaded.
    </Typography>
    {onRetry && (
      <Button variant="text" color="inherit" size="small" onClick={onRetry}>
        Retry
      </Button>
    )}
  </Styled.BasicCard>
);

export default ExecutionCardError;
