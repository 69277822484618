import { CheckpointInputValue } from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints.types';
import { PropsWithChildren, useState } from 'react';
import { ExecutionCheckpointContext } from './ExecutionCheckpointContext';

export function ExecutionCheckpointProvider({ children }: PropsWithChildren) {
  const [contextInputValue, setContextInputValue] = useState<CheckpointInputValue>();
  const [pipelineExecutionId, setPipelineExecutionId] = useState<string>();

  return (
    <ExecutionCheckpointContext.Provider
      value={{
        contextInputValue,
        pipelineExecutionId,
        setContextInputValue,
        setPipelineExecutionId,
      }}
    >
      {children}
    </ExecutionCheckpointContext.Provider>
  );
}
