import { Backup, Clear } from '@mui/icons-material';
import { Autocomplete, Chip, CircularProgress, IconButton, TextField } from '@mui/material';
import {
  getAssetMediaTypes,
  isArrayDataSchema,
  isAssetDataSchema,
  isDynamicEditorValue,
} from '@pathways/pipeline-schema/web';
import { uploadAssets } from 'api/services/uploadAssets';
import useToast from 'contexts/toast/useToast';
import {
  NodeValue,
  SignedAssetObject,
  isSignedAssetObject,
  isSignedAssetObjectArray,
} from 'features/Flow/nodes/Node/Node.types';
import { forwardRef, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AutocompleteEndAdornmentBox } from '../Autocomplete/Autocomplete.styles';
import { AssetInputProps } from './AssetInput.types';
import { generateAssetInputHelperText } from './AssetInput.utils';

const AssetInput = forwardRef<HTMLDivElement, AssetInputProps>(
  (
    {
      assetScope,
      fileInputOnly,
      fileInputRef: externalFileInputRef,
      input,
      label,
      value,
      TextFieldProps,
      onConfirmChange,
      onAssetUpload = uploadAssets,
    },
    ref,
  ) => {
    if (!isAssetDataSchema(input.dataSchema) && !isArrayDataSchema(input.dataSchema, 'asset'))
      throw new Error('[AssetInput] Received the wrong type of input');

    const isArrayOfAssets = isArrayDataSchema(input.dataSchema, 'asset');
    const inputType = isArrayOfAssets ? 'multiple' : 'single';
    const [isLoading, setIsLoading] = useState(false);

    const { error: showErrorToast } = useToast();
    const { pipelineId = '' } = useParams();

    const internalFileInputRef = useRef<HTMLInputElement>(null);
    const fileInputRef = externalFileInputRef ?? internalFileInputRef;
    const mediaTypes = getAssetMediaTypes(input.dataSchema);
    const currentAssets = parseValue(value ?? []);

    const handleAssetUpload = async (files: File[]) => {
      setIsLoading(true);
      try {
        const uploadedAssets = await onAssetUpload(pipelineId, assetScope, files);
        const newAssets =
          inputType === 'single' ? uploadedAssets[0] : currentAssets.concat(uploadedAssets);
        onConfirmChange(newAssets);
      } catch (_) {
        showErrorToast({ message: 'Something went wrong uploading your asset' });
      } finally {
        setIsLoading(false);
      }
    };

    const handleClear = () => {
      onConfirmChange([]);
    };

    const uploadButton = isLoading ? (
      <CircularProgress size={24} color="inherit" />
    ) : (
      <IconButton
        size="small"
        title="Upload"
        aria-label="upload"
        onClick={() => {
          fileInputRef.current?.click();
        }}
      >
        {/* TODO need to change this icon for material symbols*/}
        <Backup />
      </IconButton>
    );

    const hasAssets = currentAssets.length > 0;
    const singleActionButton = hasAssets ? (
      <IconButton size="small" onClick={handleClear} title="Clear" aria-label="clear">
        <Clear />
      </IconButton>
    ) : (
      uploadButton
    );

    const renderTextField = ({
      assets,
      ...props
    }: AssetInputProps['TextFieldProps'] & { assets: SignedAssetObject[] }) => {
      const numberOfIcons = input.config.required ? 1 : 2;

      return (
        <TextField
          ref={ref}
          {...props}
          label={label}
          required={input.config.required}
          placeholder={assets.length > 0 ? '' : 'Upload or connect data...'}
          name={input.name}
          fullWidth
          aria-readonly
          helperText={generateAssetInputHelperText(getAssetMediaTypes(input.dataSchema))}
          slotProps={{
            input: {
              ...props.InputProps,
              endAdornment: (
                <AutocompleteEndAdornmentBox>
                  {props.InputProps?.endAdornment}

                  {inputType === 'multiple' ? uploadButton : singleActionButton}
                </AutocompleteEndAdornmentBox>
              ),
              sx: {
                '&.MuiAutocomplete-inputRoot.MuiInputBase-adornedEnd.MuiInput-root': {
                  // Matches the width of the icon + 3px spacing.
                  paddingRight: `${34 * numberOfIcons + 3}px`,
                },
              },
            },
          }}
        />
      );
    };

    return (
      <>
        <input
          type="file"
          data-testid="asset-upload-input"
          accept={mediaTypes.join(',')}
          ref={fileInputRef}
          style={{ display: 'none' }}
          value={[]}
          multiple={isArrayOfAssets}
          onChange={async (event) => {
            const files = [...(event.target.files ?? [])];
            await handleAssetUpload(files);
          }}
        />

        {!fileInputOnly &&
          (isArrayOfAssets ? (
            <Autocomplete
              ref={ref}
              multiple
              options={[]}
              fullWidth
              disableClearable
              freeSolo
              readOnly
              value={currentAssets}
              renderTags={(value, getTagProps) =>
                value.map(({ pathwayAssetId, filename }, index) => {
                  const props = getTagProps({ index });
                  return (
                    <Chip
                      {...props}
                      key={props.key}
                      label={filename ?? 'File'}
                      variant="filled"
                      color="primary"
                      size="small"
                      onDelete={() => {
                        onConfirmChange(
                          currentAssets.filter((asset) => asset.pathwayAssetId !== pathwayAssetId),
                        );
                      }}
                    />
                  );
                })
              }
              renderInput={(props) =>
                renderTextField({
                  ...props,
                  ...TextFieldProps,
                  assets: currentAssets,
                  InputProps: {
                    ...props.InputProps,
                    ...TextFieldProps?.InputProps,
                  },
                  inputProps: {
                    ...props.inputProps,
                    ...TextFieldProps?.inputProps,
                  },
                })
              }
            />
          ) : (
            renderTextField({
              ...TextFieldProps,
              assets: currentAssets,
              value: currentAssets.length > 0 ? currentAssets[0].filename : '',
            })
          ))}
      </>
    );
  },
);

AssetInput.displayName = 'AssetInput';

export default AssetInput;

const parseValue = (val: NodeValue): SignedAssetObject[] => {
  if (isDynamicEditorValue(val)) return [];
  const isMultiValue = isSignedAssetObjectArray(val);
  if (isMultiValue) return val;

  const isSingleValue = isSignedAssetObject(val);
  if (isSingleValue) return [val];
  throw new Error('Found incorrect value for node');
};
