import { getExampleValue } from '@pathways/pipeline-schema/web';
import { NodeInput } from 'features/Flow/nodes/Node/Node.types';
import { StartOutput } from 'features/Flow/nodes/PipelineStart/PipelineStart.types';

export const mapInputsToAPITrigger = (
  outputs?: StartOutput[],
): { inputs: Record<string, unknown> } => {
  if (!outputs || outputs.length == 0) return { inputs: {} };

  const newApiObject = outputs.reduce((acc, output) => {
    return {
      ...acc,
      [output.name]: getExampleValue(output.dataSchema),
    };
  }, {});

  return { inputs: newApiObject };
};

/**
 * Internal fields are relate to custom logic for neurons that have internally managed inputs or outputs that the end users should not be able to see.
 * It is based on the AJV extended keyword `forceHide` and mapped to `config.forceHide`
 * @param input NodeInput
 * @returns Boolean
 */
export const isForceHidden = (input: NodeInput) => {
  return !!input.config.forceHide;
};
