import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
} from '@mui/material';
import styled from 'styled-components';

export const MuiAccordion = styled(Accordion).attrs({
  elevation: 1,
})`
  border-radius: 16px;

  &:before {
    opacity: 0;
  }

  &:first-of-type,
  &:last-of-type {
    border-radius: 16px;
  }
`;

export const MuiAccordionSummary = styled(AccordionSummary).attrs<AccordionSummaryProps>({
  component: 'div',
})`
  padding-inline: 24px;
  padding-block: 16px;
  gap: 8px;
  border-radius: 16px;

  &.Mui-focusVisible {
    background: none;
  }

  > .MuiAccordionSummary-content {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const MuiAccordionDetails = styled(AccordionDetails)`
  margin-inline: 24px;
  margin-bottom: 24px;
  padding: 0;
`;
