import { Box, LinearProgress, Paper, Table, TableBody, TableCell, Typography } from '@mui/material';
import {
  ExecutionResponse,
  ExecutionTriggerType,
} from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import Loader from 'components/Loader/Loader';
import StatusChip from 'components/StatusChip/StatusChip';
import * as Styled from 'components/Table/Table.styles';
import CustomTableHead from 'components/Table/TableHead';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { formatRelative } from 'date-fns';
import ExecutionCardError from 'pages/ExecutionsPage/components/ExecutionCard/ExecutionCardError';
import { useMemo, useState } from 'react';
import { getPercentage } from 'utils/number';
import { getComparator, Order } from 'utils/order';
import {
  COLUMN_CONFIG,
  COLUMN_CONFIG_ARRAY,
  COLUMN_IDS,
  ColumnId,
} from './ExecutionRunsTable.consts';

const SKELETON_WIDTH = 120;

export interface ExecutionRunsTableProps {
  loading: boolean;
  loadingFailed: boolean;
  executions: ExecutionResponse[];
  onRowClick: (pipelineId: ExecutionResponse['id']) => void;
}

export default function ExecutionRunsTable(props: ExecutionRunsTableProps) {
  const { loading, loadingFailed, executions } = props;
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<ColumnId>(COLUMN_IDS.RUN_NUMBER);
  const canClickRow = !loading;
  const displayError = loadingFailed;
  const disableHeader = loading || displayError;
  const displayRows = !displayError;

  const rows = useMemo(() => {
    if (loading) {
      return Array<ExecutionResponse>(5)
        .fill({} as ExecutionResponse)
        .map((_, index) => ({ id: String(index) }) as ExecutionResponse);
    }

    const orderByProperty = COLUMN_CONFIG[orderBy]?.orderProperty;

    if (!orderByProperty) return executions;

    return executions.slice().sort(getComparator(order, orderByProperty));
  }, [loading, order, orderBy, executions]);

  const handleSort = (columnId: ColumnId) => {
    const isAsc = orderBy === columnId && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnId);
  };

  const handleRowClick = (executionId: ExecutionResponse['id']) => {
    if (!canClickRow) return;
    props.onRowClick(executionId);
  };

  const getVersion = (execution: ExecutionResponse) => {
    const version = `Version ${execution.version}`;
    if (execution.pipelineVersionState === 'draft') {
      return `${version} (Draft)`;
    }
    return version;
  };

  const formatTriggerType = (triggerType: ExecutionTriggerType) => {
    switch (triggerType) {
      case 'user':
        return 'User';
      case 'external':
        return 'External';
      case 'subpipeline':
        return 'Subpipeline';
      default:
        return '-';
    }
  };

  return (
    <Styled.TableContainer component={Paper} elevation={4} sx={{ maxWidth: '95vw' }}>
      <Table aria-label="Table of runs" sx={{ minWidth: 880 }}>
        <CustomTableHead
          disabled={disableHeader}
          columns={COLUMN_CONFIG_ARRAY}
          order={order}
          orderBy={orderBy}
          onSort={(columnId) => {
            handleSort(columnId as ColumnId);
          }}
        />
        <TableBody>
          {displayRows &&
            rows.map((execution) => {
              return (
                <Styled.TableBodyRow
                  hover={!loading}
                  key={execution.id}
                  onClick={() => {
                    handleRowClick(execution.id);
                  }}
                >
                  <TableCell>
                    <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                      <TextEllipsis color="text.primary" maxWidth="20vw">
                        Run {execution.executionNumber}
                      </TextEllipsis>
                    </Loader>
                  </TableCell>

                  <TableCell>
                    <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                      {getVersion(execution)}
                    </Loader>
                  </TableCell>

                  <TableCell>
                    <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                      {execution.createdAt
                        ? formatRelative(new Date(execution.createdAt), new Date())
                        : '-'}
                    </Loader>
                  </TableCell>

                  <TableCell>
                    <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                      <StatusChip status={execution.status} variant="elevated" />
                    </Loader>
                  </TableCell>

                  <TableCell>
                    <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                      {formatTriggerType(execution.triggerType)}
                    </Loader>
                  </TableCell>

                  <TableCell sx={{ minWidth: '200px' }}>
                    <Loader variant="text" loading={loading} width={SKELETON_WIDTH}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            mr: 2,
                          }}
                        >
                          <Typography variant="bodySmall">
                            {getPercentage(execution.completedJobs, execution.totalJobs)}%
                          </Typography>
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={getPercentage(execution.completedJobs, execution.totalJobs)}
                          style={{ flexGrow: 1 }}
                        />
                      </Box>
                    </Loader>
                  </TableCell>
                </Styled.TableBodyRow>
              );
            })}

          {displayError && (
            <Styled.TableBodyRow>
              <TableCell colSpan={10}>
                <ExecutionCardError />
              </TableCell>
            </Styled.TableBodyRow>
          )}
        </TableBody>
      </Table>
    </Styled.TableContainer>
  );
}
