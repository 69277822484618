import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Typography } from '@mui/material';
import { NodeInput, NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import { useState } from 'react';
import * as StyledDialog from 'components/Dialog/Dialog.styles';
import * as Styled from './SortableNodeDataList.styles';
import SortableNodeDataItem from 'features/Flow/nodes/Neuron/DummyNeuron/DummyNeuronModal/SortableNodeDataItem';

interface SortableNodeDataListProps {
  dataList: NodeInput[] | NodeOutput[];
  dataType: 'input' | 'output';
  onReorder: (from: string, to: string) => void;
  onEditItem: (item: NodeInput | NodeOutput, position: number) => void;
  onRemoveItem: (index: number) => void;
}

const SortableNodeDataList = ({
  dataList,
  dataType,
  onReorder,
  onEditItem,
  onRemoveItem,
}: SortableNodeDataListProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setIsDragging(false);
    if (event.over && event.active.id !== event.over.id) {
      onReorder(event.active.id as string, event.over.id as string);
    }
  };

  return (
    <Styled.SortableBox $isDragging={isDragging}>
      {dataList.length === 0 && (
        <StyledDialog.NoItemsBox>
          <Typography
            variant="labelSmall"
            sx={{
              color: 'text.secondary',
            }}
          >
            No {dataType}s created yet. Use the &apos;Create {dataType}&apos; button below to get
            started.
          </Typography>
        </StyledDialog.NoItemsBox>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        autoScroll={false}
      >
        <SortableContext
          items={dataList.map((dataItem) => dataItem.name)}
          strategy={verticalListSortingStrategy}
        >
          {dataList.map((dataItem, index) => (
            <SortableNodeDataItem
              key={dataItem.name}
              index={index}
              item={dataItem}
              isDragging={isDragging}
              onEditItem={onEditItem}
              onRemoveItem={onRemoveItem}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Styled.SortableBox>
  );
};

export default SortableNodeDataList;
