import { Typography } from '@mui/material';
import usePipelineVersions from 'api/services/usePipelineVersions/usePipelineVersions';
import { Layout, MuiContainer, ScrollContainer } from 'components/Layout/Layout.styles';
import { usePaginationQuery } from 'hooks/usePaginationQuery';
import usePipelineId from 'hooks/usePipelineId';
import PageNavigation from 'components/PageNavigation/PageNavigation';
import PipelineVersionsTable from 'pages/PipelineVersionsPage/components/PipelineVersionsTable/PipelineVersionsTable';
import {
  COLUMN_IDS,
  ColumnId,
} from 'pages/PipelineVersionsPage/components/PipelineVersionsTable/PipelineVersionsTable.constants';
import { useNavigate } from 'react-router-dom';
import { useAppRoutes } from 'utils/routes';

const PAGE_SIZE = 50;

export default function PipelineVersionsPage() {
  const { pageNumber, pageSize, sortBy, orderBy, updateSort, updatePageNumber } =
    usePaginationQuery<ColumnId>({
      defaultValues: { sortBy: COLUMN_IDS.VERSION, pageSize: PAGE_SIZE, pageNumber: 0 },
    });
  const pipelineId = usePipelineId();
  const { pipelineVersionsData, arePipelineVersionsLoading, pipelineVersionsError } =
    usePipelineVersions(pipelineId, { pageNumber, pageSize, sortBy, orderBy });
  const navigate = useNavigate();
  const routes = useAppRoutes();

  const handleEditDraft = () => {
    navigate(routes.pipelines(pipelineId));
  };

  const handleViewVersion = (pipelineVersionId: string) => {
    navigate(routes.pipelineVersion(pipelineId, pipelineVersionId));
  };

  return (
    <Layout>
      <PageNavigation />

      <ScrollContainer>
        <MuiContainer fixed>
          <Typography
            sx={{
              paddingBottom: 3,
            }}
            component="h1"
            variant="titleLarge"
          >
            Versions
          </Typography>
          <PipelineVersionsTable
            loading={arePipelineVersionsLoading}
            loadingFailed={!!pipelineVersionsError}
            pipelineVersions={pipelineVersionsData?.items ?? []}
            onRowClick={handleViewVersion}
            onEditDraft={handleEditDraft}
            onViewVersion={handleViewVersion}
            totalCount={pipelineVersionsData?.totalCount ?? 0}
            pageSize={PAGE_SIZE}
            pageNumber={pageNumber}
            onPageNumberChange={updatePageNumber}
            onSort={updateSort}
            orderBy={orderBy}
            sortBy={sortBy}
          />
        </MuiContainer>
      </ScrollContainer>
    </Layout>
  );
}
