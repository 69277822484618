import { Alert, Divider, Stack, TextField, Typography } from '@mui/material';
import { API_ACCEPT_HEADER } from 'api/client';
import { PipelineTriggerDetailResponse } from 'api/services/usePipelineTriggers/usePipelineTriggers.types';
import { PipelineVersionItem } from 'api/services/usePipelineVersions/usePipelineVersions.types';
import CopyButton from 'components/CopyButton/CopyButton';
import Loader from 'components/Loader/Loader';
import VersionSelect from 'components/VersionSelect/VersionSelect';
import { noop } from 'lodash';
import { PropsWithChildren } from 'react';
import Section, { SectionBaseProps } from '../Section/Section';
import CodeSnippet from './CodeSnippet';
import { usePublishedPipelineVersions } from './usePublishedPipelineVersions';

interface InstructionsSectionProps extends SectionBaseProps {
  trigger: PipelineTriggerDetailResponse | undefined;
  pipelineVersionId: CodeSamplesProps['pipelineVersionId'];
  onVersionChange: CodeSamplesProps['onVersionChange'];
}

export default function InstructionsSection(props: InstructionsSectionProps) {
  const { loading, trigger } = props;
  const { arePipelineVersionsLoading, publishedVersions } = usePublishedPipelineVersions(
    trigger?.pipelineId,
  );
  const displayLoading = loading || !trigger || arePipelineVersionsLoading || !publishedVersions;
  const displayEmptyState =
    !displayLoading && (!publishedVersions.length || !trigger.examplePayload);
  const displayCodeSamples = !displayLoading && !displayEmptyState;

  return (
    <Section title="Instructions" loading={displayLoading}>
      {displayLoading && <SectionLoader />}
      {displayEmptyState && <EmptyState />}
      {displayCodeSamples && (
        <CodeSamples
          trigger={trigger}
          publishedVersions={publishedVersions}
          pipelineVersionId={props.pipelineVersionId}
          onVersionChange={props.onVersionChange}
        />
      )}
    </Section>
  );
}

function SectionLoader() {
  return (
    <Stack gap={3} data-testid="instructions-loader">
      <div>
        <Loader loading variant="text" width={120} />
        <Loader loading variant="text" width={342} />
      </div>

      <div>
        <Loader loading variant="text" width={120} />
        <Loader loading variant="text" width={342} />
      </div>

      <div>
        <Loader
          loading
          variant="text"
          width={120}
          sx={{
            marginBottom: 0.5,
          }}
        />
        <Loader loading variant="rounded" height={80} />
      </div>
    </Stack>
  );
}

function VersionSelectionPanel(props: PropsWithChildren<{ disabled?: boolean }>) {
  const { disabled = false, children } = props;

  return (
    <Stack direction="row" gap={4} color={disabled ? 'text.disabled' : undefined}>
      <Typography variant="bodyMedium">
        Select a version of this pipeline to use for your trigger. The following URL and code
        samples will be updated based on your selection.
      </Typography>

      {children}
    </Stack>
  );
}

function EmptyState() {
  return (
    <Stack gap={2}>
      <Alert severity="warning">
        Please publish a version first to enable access, generate the endpoint URL and code samples.
      </Alert>

      <VersionSelectionPanel disabled>
        <VersionSelect versions={[]} selectedId="" onChange={noop} />
      </VersionSelectionPanel>
    </Stack>
  );
}

const REQUIRED_HEADER_VALUE = '<REQUIRED>';

interface CodeSamplesProps {
  trigger: PipelineTriggerDetailResponse;
  publishedVersions: PipelineVersionItem[];
  pipelineVersionId: string | undefined;
  onVersionChange: (versionId?: string) => void;
}

function CodeSamples(props: CodeSamplesProps) {
  const { trigger, publishedVersions, pipelineVersionId } = props;

  const payloadVersion = publishedVersions.find((version) => version.id === pipelineVersionId);
  const latestVersion = publishedVersions[0];
  const versionSelectValue = payloadVersion?.id ?? latestVersion.id;

  const handleVersionChange = (versionId: string) => {
    const isLatestVersionId = versionId === latestVersion.id;
    const nextVersionId = isLatestVersionId ? undefined : versionId;

    props.onVersionChange(nextVersionId);
  };

  const requestUrl = `${import.meta.env.API_URL}${trigger.url}`;
  const requestHeaders = {
    accept: API_ACCEPT_HEADER,
    'content-type': 'application/json',
    ...trigger.requiredHeaders.reduce<Record<string, string>>((acc, header) => {
      acc[header] = REQUIRED_HEADER_VALUE;
      return acc;
    }, {}),
  } satisfies Record<string, string>;
  const requestPayload = {
    ...trigger.examplePayload,
    pipelineVersion: payloadVersion?.version,
  };
  const curlExample = generateCurlExample({
    url: requestUrl,
    headers: requestHeaders,
    payload: requestPayload,
  });
  const fetchExample = generateFetchExample({
    url: requestUrl,
    headers: requestHeaders,
    payload: requestPayload,
  });

  return (
    <Stack gap={3}>
      <VersionSelectionPanel>
        <VersionSelect
          versions={publishedVersions}
          selectedId={versionSelectValue}
          onChange={handleVersionChange}
        />
      </VersionSelectionPanel>

      <Divider
        sx={{
          marginInline: -3,
        }}
      />

      <Alert severity="info">
        Requests using external triggers require the header {"'x-api-key'"} with the secret token
        you saved when it was created.
      </Alert>

      <TextField
        fullWidth
        label="Request URL"
        variant="outlined"
        value={requestUrl}
        margin="dense"
        slotProps={{
          input: {
            endAdornment: (
              <CopyButton
                value={requestUrl}
                size="medium"
                sx={{
                  marginRight: 0,
                }}
              />
            ),
            sx: (theme) => ({
              background: theme.palette.surface.level0,
              '&&&&': {
                border: 0,
              },
            }),
          },
          htmlInput: {
            readOnly: true,
            sx: {
              textOverflow: 'ellipsis',
            },
          },
        }}
      />

      <CodeSnippet
        label="Sample Payload"
        language="javascript"
        code={JSON.stringify(requestPayload, null, 2)}
      />
      <CodeSnippet language="bash" code={curlExample} />
      <CodeSnippet language="javascript" code={fetchExample} />
    </Stack>
  );
}

function generateCurlExample(params: { url: string; headers: object; payload: object }) {
  const { url, headers, payload } = params;

  return `curl -X 'POST' \\\r
  '${url}' \\\r
${Object.entries(headers)
  .map(([key, value]) => `  -H '${key}: ${value}' \\`)
  .join('\n')}
  -d '${JSON.stringify(payload, null, 2)}'`;
}

function generateFetchExample(params: { url: string; headers: object; payload: object }) {
  const { url, headers, payload } = params;

  return `fetch(
  "${url}",
  {
    method: "POST",
    headers: {
${Object.entries(headers)
  .map(([key, value]) => `      "${key}": "${value}"`)
  .join(',\n')}
    },
    body: ${JSON.stringify(JSON.stringify(payload, null, 2))},
  }
);`;
}
