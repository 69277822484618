import { Box, Grid2, Stack, Typography } from '@mui/material';
import { isSubpipelineJobResponse } from 'api/services/usePipelineJobs/usePipelineJobs.types';
import HeaderChip from 'components/HeaderChip/HeaderChip';
import Loader from 'components/Loader/Loader';
import OpenInNewTab from 'components/OpenInNewTab/OpenInNewTab';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import RoundedPaper from 'components/RoundedPaper/RoundedPaper';
import StatusChip from 'components/StatusChip/StatusChip';
import { useAppRoutes } from 'utils/routes';
import { useViewExecutionRequests } from '../../useViewExecutionRequests';
import JobInfoTitle from '../JobInfoTitle/JobInfoTitle';
import ProgressCard from './ProgressCard';
import { ProgressPaper, SubpipelinePaper } from './ExecutionProgress.styles';

interface ExecutionProgressProps {
  pipelineExecutionId: string;
}

export default function ExecutionProgress(props: ExecutionProgressProps) {
  const routes = useAppRoutes();

  const { pipelineExecutionId } = props;
  const { pipelineExecutionRequest, pipelineJobRequest } =
    useViewExecutionRequests(pipelineExecutionId);

  const { pipelineExecution, isPipelineExecutionLoading } = pipelineExecutionRequest;
  const { progressPercentage, completedCheckpoints, totalCheckpoints } = pipelineExecution;
  const { pipelineJobs } = pipelineJobRequest;
  const subpipelineJobs = pipelineJobs.filter(isSubpipelineJobResponse);

  return (
    <Grid2
      container
      component={RoundedPaper}
      sx={{
        gap: 2,
        padding: 3,
      }}
    >
      <Grid2 component={ProgressPaper} elevation={2} size={6}>
        <Stack
          sx={{
            gap: 2,
            alignItems: 'center',
          }}
        >
          <Loader loading={isPipelineExecutionLoading}>
            <Typography variant="headlineLarge">Pipeline is running...</Typography>
          </Loader>
          <Loader loading={isPipelineExecutionLoading}>
            <Typography
              variant="bodyLarge"
              sx={{
                color: 'text.secondary',
              }}
            >
              {progressPercentage}% Completed
            </Typography>
          </Loader>
          <Loader loading={isPipelineExecutionLoading}>
            <ProgressBar
              value={progressPercentage}
              sx={{
                width: '70%',
              }}
            />
          </Loader>
        </Stack>
      </Grid2>

      {!isPipelineExecutionLoading && (
        <>
          <Grid2 size="grow">
            <ProgressCard
              label="Elements Completed"
              completedCount={pipelineExecution.completedJobs}
              totalCount={pipelineExecution.totalJobs}
            />
          </Grid2>

          {totalCheckpoints > 0 && (
            <Grid2 size="grow">
              <ProgressCard
                label="Checkpoints Completed"
                completedCount={completedCheckpoints}
                totalCount={totalCheckpoints}
              />
            </Grid2>
          )}
        </>
      )}

      {subpipelineJobs.map((job) => (
        <Grid2 key={job.id} component={SubpipelinePaper} elevation={2} size={12}>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <JobInfoTitle displaySecondaryName {...job} />

            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
              }}
            >
              <HeaderChip $noSpacingRight elevation={4}>
                Callable Pipeline
                <Box
                  component={StatusChip}
                  status={job.status}
                  variant="icon"
                  sx={{
                    marginLeft: 1.5,
                  }}
                />
              </HeaderChip>

              <OpenInNewTab href={routes.executions.view(job.pipelineId, job.executionId)} />
            </Stack>
          </Stack>
        </Grid2>
      ))}
    </Grid2>
  );
}
