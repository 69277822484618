import { DataManipulatorFormData, DataManipulatorModalProps } from '../DataManipulator.types';
import * as Styled from 'components/Dialog/Dialog.styles';
import {
  Button,
  CircularProgress,
  Grid2,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import DataManipulatorForm from './DataManipulatorForm';
import { useCallback } from 'react';
import {
  createDynamicSchemaInput,
  createFormDefaults,
  createDataOutput,
} from '../DataManipulator.utils';
import { getSchemaByDataType } from 'utils/dataSchema';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import useDataManipulatorPlayground from 'api/services/useDataManipulatorPlayground/useDataManipulatorPlayground';
import { useJsonEditorState } from 'components/JsonEditor/useJsonEditorState';
import { InfoOutlined } from '@mui/icons-material';

const CONTAINER_HEIGHT = 480;
// This needs to be calculated by offsetting the label and section header heights
const JSON_EDITOR_HEIGHT = CONTAINER_HEIGHT - 100;

function getJsonEditorType(type?: string) {
  if (!type) {
    return 'all';
  }

  return type === 'object' ? 'object' : 'array';
}

export default function DataManipulatorModal({
  inputs,
  outputs,
  open,
  onCloseModal,
  onConfirm,
  disableInputType,
  disableOutputType,
}: DataManipulatorModalProps) {
  const theme = useTheme();
  const form = useForm<DataManipulatorFormData>({
    defaultValues: createFormDefaults({ inputs, outputs }),
  });

  const submitForm: SubmitHandler<DataManipulatorFormData> = useCallback(
    (data) => {
      const inputDataSchema =
        data.input === 'array'
          ? getSchemaByDataType('array-object')
          : getSchemaByDataType(data.input);

      const outputDataSchema =
        data.output === 'array'
          ? getSchemaByDataType(data.output, data.outputArrayType)
          : getSchemaByDataType(data.output);

      const dynamicSchemaInput = createDynamicSchemaInput(outputDataSchema);

      const updatedInputs = inputs.map((input) => {
        if (input.name === 'options') {
          return { ...input, value: { slurp: data.slurp } };
        }

        if (input.name === 'query') {
          return { ...input, value: data.query };
        }

        if (input.name === 'data') {
          return { ...input, dataSchema: inputDataSchema };
        }

        if (input.name === 'outputSchema') {
          return dynamicSchemaInput;
        }

        return input;
      });

      if (!updatedInputs.find((input) => input.name === dynamicSchemaInput.name)) {
        updatedInputs.push(dynamicSchemaInput);
      }

      const updatedOutputs = outputs.map((output) => {
        if (output.name === 'data') {
          return { ...output, dataSchema: outputDataSchema };
        }

        return output;
      });

      const input = updatedInputs.find((input) => input.name === 'data');
      const output = updatedOutputs.find((output) => output.name === 'data');

      if (!input) {
        updatedInputs.push(createDataOutput(inputDataSchema, 'Data'));
      }

      if (!output) {
        updatedOutputs.push(createDataOutput(outputDataSchema, 'Queried data'));
      }

      onConfirm(updatedInputs, updatedOutputs);
    },
    [inputs, onConfirm, outputs],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  // Data manipulator playground code
  const {
    content,
    value,
    onChange: onJsonChange,
  } = useJsonEditorState(getJsonEditorType(form.watch('input')) === 'array' ? [] : {});
  const { isMutating, trigger, data, error } = useDataManipulatorPlayground({});
  const handleRunQuery = async () => {
    const values = form.getValues();
    await trigger({
      jsonData: typeof value === 'string' ? (JSON.parse(value) as object | object[]) : value,
      query: values.query,
      options: {
        slurp: values.slurp,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Styled.Dialog
        open={open}
        onClose={onCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
      >
        <Styled.DialogTitle $hasBorder={true}>Configure Data Manipulator</Styled.DialogTitle>
        <Styled.DialogContent
          sx={{
            height: CONTAINER_HEIGHT,
            p: '0 !important',
          }}
        >
          <Grid2
            container
            sx={{
              px: 1,
            }}
          >
            <Grid2
              sx={{
                borderRight: '1px solid',
                borderColor: theme.palette.surface.level3,
                overflowY: 'auto',
                maxHeight: CONTAINER_HEIGHT,
                p: 2,
              }}
              size={4}
            >
              <DataManipulatorForm
                disableInputType={disableInputType}
                disableOutputType={disableOutputType}
              />
            </Grid2>

            <Grid2
              size="grow"
              sx={{
                p: 2,
              }}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems="center">
                  <Typography variant="titleSmall">Playground</Typography>
                  <Tooltip
                    title="Test your query by entering your 'Command' and expected input data then clicking 'Run'"
                    sx={{ ml: 0.5 }}
                  >
                    <InfoOutlined fontSize="small" color="info" />
                  </Tooltip>
                </Stack>
                <Button sx={{ mr: -2 }} onClick={handleRunQuery} disabled={isMutating}>
                  {isMutating ? <CircularProgress size={16} /> : 'Run'}
                </Button>
              </Stack>
              <Grid2 container columns={12} spacing={2}>
                <Grid2 size={6}>
                  <Typography variant="bodySmall">Input</Typography>
                  <JsonEditor
                    content={content}
                    style={{ height: JSON_EDITOR_HEIGHT }}
                    type={getJsonEditorType(form.watch('input'))}
                    onChange={onJsonChange}
                  />
                </Grid2>
                <Grid2 size={6}>
                  <Typography variant="bodySmall">Output</Typography>
                  <JsonEditor
                    content={data ?? null}
                    readOnly
                    style={{ height: JSON_EDITOR_HEIGHT }}
                    helperText={error?.response?.data.message}
                  />
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Styled.DialogContent>
        <Styled.DialogActions $hasBorder={true}>
          <Stack direction="row">
            <Button variant="text" sx={{ color: 'common.white' }} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button onClick={handleConfirm}>Confirm</Button>
          </Stack>
        </Styled.DialogActions>
      </Styled.Dialog>
    </FormProvider>
  );
}
