import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { IconButton, Stack, useTheme } from '@mui/material';
import { JobInfoResponse } from 'api/services/usePipelineExecutionOutputs/usePipelineExecutionOutputs.types';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import { isSubpipelineJobResponse } from 'api/services/usePipelineJobs/usePipelineJobs.types';
import HeaderChip from 'components/HeaderChip/HeaderChip';
import OpenInNewTab from 'components/OpenInNewTab/OpenInNewTab';
import { formatStatusDateTime } from 'utils/execution';
import { useAppRoutes } from 'utils/routes';
import JobInfoTitle from '../JobInfoTitle/JobInfoTitle';
import * as Styled from './PipelineOutputAccordion.styles';

interface PipelineOutputAccordionProps {
  children: React.ReactNode;
  job: JobInfoResponse;
  expandAccordionByDefault?: boolean;
}

export function PipelineOutputAccordion(props: PipelineOutputAccordionProps) {
  const { job } = props;
  const theme = useTheme();
  const routes = useAppRoutes();
  const jobCompletedDate = job.completedAt ? new Date(job.completedAt) : undefined;
  const completedDateTimeLabel = jobCompletedDate
    ? formatStatusDateTime(ExecutionStatus.COMPLETED, jobCompletedDate)?.fullLabel
    : undefined;

  return (
    <Styled.MuiAccordion
      data-testid={`pipeline-output-accordion-${job.name}`}
      disableGutters
      defaultExpanded={props.expandAccordionByDefault ?? true}
      slotProps={{
        transition: {
          timeout: theme.transitions.duration.standard,
        },
      }}
    >
      <Styled.MuiAccordionSummary
        expandIcon={
          <IconButton size="small">
            <ExpandMoreIcon />
          </IconButton>
        }
        tabIndex={-1}
        aria-controls={`${job.id}-content`}
        id={`${job.id}-header`}
      >
        <JobInfoTitle displaySecondaryName {...job} />
        <Stack
          direction="row"
          sx={{
            gap: 1,
          }}
        >
          <HeaderChip>
            <span title={jobCompletedDate?.toJSON()}>{completedDateTimeLabel}</span>
          </HeaderChip>
          {isSubpipelineJobResponse(job) && (
            <OpenInNewTab href={routes.executions.view(job.pipelineId, job.executionId)} />
          )}
        </Stack>
      </Styled.MuiAccordionSummary>
      <Styled.MuiAccordionDetails>{props.children}</Styled.MuiAccordionDetails>
    </Styled.MuiAccordion>
  );
}
