import { Switch } from '@mui/material';
import { usePipeline } from 'api/services/usePipeline/usePipeline';
import { useUpdatePipelineMetadata } from 'api/services/useUpdatePipelineMetadata/useUpdatePipelineMetadata';
import { AxiosError } from 'axios';
import useToast from 'contexts/toast/useToast';

interface SubpipelineSwitchProps {
  pipelineId: string;
}

export default function SubpipelineSwitch({ pipelineId }: SubpipelineSwitchProps) {
  const toast = useToast();
  const { isSaving, updatePipeline } = useUpdatePipelineMetadata({ pipelineId });
  const { pipeline } = usePipeline(pipelineId);

  const currentType = pipeline?.type ?? 'default';

  const handleClick = async () => {
    if (!pipeline?.type) return;

    const nextType: typeof currentType = currentType === 'callable' ? 'default' : 'callable';

    try {
      await updatePipeline({ type: nextType });
    } catch (err) {
      if (err instanceof AxiosError) {
        // eslint-disable-next-line
        toast.error({ message: err.response?.data?.message || 'Failed to update pipeline type' });
      }
    }
  };

  return (
    <Switch
      data-testid="SubpipelineSwitch"
      checked={currentType === 'callable'}
      disabled={isSaving}
      onClick={handleClick}
    />
  );
}
