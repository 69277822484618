import { createContext } from 'react';
import useToastState from './useToastState';

type ToastHandlers = ReturnType<typeof useToastState>['0'];

export const ToastContext = createContext<ToastHandlers>({
  success() {
    return;
  },
  warning() {
    return;
  },
  error() {
    return;
  },
  info() {
    return;
  },
  close() {
    return;
  },
});
