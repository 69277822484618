import axios, { AxiosResponse, AxiosError } from 'axios';
import useSWRMutation, { MutationFetcher, SWRMutationConfiguration } from 'swr/mutation';

type ResponseData = AxiosResponse<{ result: unknown }>;

const runDataManipulator: MutationFetcher<
  ResponseData,
  string,
  { jsonData: object | object[]; query: string; options: Record<string, boolean> }
> = (_, { arg }) => axios.post('/tools/data-manipulation', arg);

export type MutationConfig = SWRMutationConfiguration<ResponseData, AxiosError<Error>>;

const useDataManipulatorPlayground = (
  config?: SWRMutationConfiguration<ResponseData, AxiosError<Error>>,
) => {
  const { data, ...rest } = useSWRMutation('/tools/data-manipulation', runDataManipulator, config);
  return {
    ...rest,
    data: data?.data.result,
  };
};

export default useDataManipulatorPlayground;
