import { Typography, css, styled } from '@mui/material';
import { HeaderChipProps } from './HeaderChip';

export const Container = styled(Typography)<HeaderChipProps>(
  ({ theme, $noSpacingLeft, $noSpacingRight }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding-left: ${$noSpacingLeft ? '4px' : theme.spacing(2)};
    padding-right: ${$noSpacingRight ? '4px' : theme.spacing(2)};
    border-radius: ${theme.shape.borderRadius * 4}px;
  `,
);
