import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import HeaderChip from 'components/HeaderChip/HeaderChip';
import JobInfoTitle from '../JobInfoTitle/JobInfoTitle';
import { formatStatusDateTime } from 'utils/execution';
import * as Styled from '../PipelineOutputAccordion/PipelineOutputAccordion.styles';
import { NodeType } from 'features/Flow/Flow.types';

interface PipelineTriggerOutputAccordionProps {
  children: React.ReactNode;
  completedAt?: Date;
}

export function PipelineTriggerOutputAccordion(props: PipelineTriggerOutputAccordionProps) {
  const { children, completedAt } = props;
  const completedDateTimeLabel = formatStatusDateTime(ExecutionStatus.COMPLETED, completedAt)
    ?.fullLabel;

  return (
    <Styled.MuiAccordion disableGutters defaultExpanded>
      <Styled.MuiAccordionSummary
        expandIcon={
          <IconButton size="small">
            <ExpandMoreIcon />
          </IconButton>
        }
        tabIndex={-1}
        aria-controls={`pipeline-trigger-outputs-content`}
        id={`pipeline-trigger-outputs-header`}
      >
        <JobInfoTitle type={NodeType.PIPELINE_START} name="Pipeline Trigger" />
        <HeaderChip>
          <span title={completedAt?.toJSON()}>{completedDateTimeLabel}</span>
        </HeaderChip>
      </Styled.MuiAccordionSummary>
      <Styled.MuiAccordionDetails>{children}</Styled.MuiAccordionDetails>
    </Styled.MuiAccordion>
  );
}
