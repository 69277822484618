import axios, { RawAxiosRequestHeaders } from 'axios';
import { ErrorEvent, EventSource } from 'eventsource';
import { noop } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

interface UseEventSourceProps<TResponse, TData> {
  url: string;
  headers?: RawAxiosRequestHeaders;
  onMessage: (msg: MessageEvent<TResponse>) => TData;
  onError: (err: ErrorEvent) => void;
}

const pauseEventSource = new Event('PauseEventSource');

const EMPTY_FN = noop;

/**
 * Event source has a limitation for concurrent connections at a given time (~6) depending on the browser. This should have built-in logic to only connect on the active tab
 * @param {Object} Option
 * @property {string} Option.api HTTP Endpoint
 * @property {Object} Option.headers HTTP Headers
 * @property {Function} Option.onMessage function to transform stream response data into the returned hook data
 * @property {Function} Option.onError function for an error handler
 * @returns
 */
export const useEventSource = <TResponse, TData>({
  url,
  headers,
  onMessage,
  onError,
}: UseEventSourceProps<TResponse, TData>) => {
  // These offsets are used to track replays so that only ones after latest visible are appended to the data list
  // Relevant to the visibility handler's management of disconnect / connect logic
  const currentOffset = useRef(0);
  const latestOffset = useRef(0);
  const [stream, setStream] = useState<TData[]>([]);
  const [connected, setConnected] = useState<boolean>(false);

  const closeRef = useRef(EMPTY_FN);

  const pauseOffsets = () => {
    latestOffset.current =
      latestOffset.current > currentOffset.current ? latestOffset.current : currentOffset.current;
    currentOffset.current = 0;
  };

  const connectToEventSource = useRef(() => {
    const es = new EventSource(url, {
      withCredentials: true,
      fetch: async (_url, init) => {
        // Use axios for default config and request interceptors. (api/client.ts)
        const response = await axios.get<ReadableStream>(url, {
          ...init,
          adapter: 'fetch',
          responseType: 'stream',
          headers: {
            ...headers,
            ...axios.defaults.headers.common,
          },
        });
        setConnected(true);

        return {
          body: response.data,
          url,
          status: response.status,
          headers: response.headers as unknown as Headers,
          redirected: false,
        };
      },
    });
    closeRef.current = () => es.dispatchEvent(pauseEventSource);

    es.addEventListener('message', (msg: MessageEvent<TResponse>) => {
      currentOffset.current += 1;
      if (currentOffset.current > latestOffset.current) {
        setStream((prev) => [...prev, onMessage(msg)]);
      }
    });

    es.addEventListener('error', (err) => {
      es.close();
      pauseOffsets();
      setConnected(false);
      onError(err);
    });

    es.addEventListener('PauseEventSource', () => {
      pauseOffsets();
      setConnected(false);
      es.close();
    });
  }).current;

  const visibilityHandler = () => {
    if (document.hidden) {
      pauseOffsets();
      closeRef.current();
    } else {
      connectToEventSource();
    }
  };

  // This gets called twice in StrictMode. Runs correctly without it.
  useEffect(() => {
    if (!document.hidden) {
      connectToEventSource();
    }

    document.addEventListener('visibilitychange', visibilityHandler);
    return () => {
      document.removeEventListener('visibilitychange', visibilityHandler);
      closeRef.current();
    };
    // Ignoring this dependency list as we need to guarantee it only gets called once
    // eslint-disable-next-line
  }, []);

  const reset = useCallback(() => {
    setStream([]);
    closeRef.current();
    latestOffset.current = 0;
    currentOffset.current = 0;
    closeRef.current = EMPTY_FN;
  }, []);

  const reconnect = useCallback(() => {
    closeRef.current();
    closeRef.current = EMPTY_FN;
    connectToEventSource();
  }, [connectToEventSource]);

  return {
    data: stream,
    connected,
    close: closeRef.current,
    reset,
    reconnect,
  };
};
