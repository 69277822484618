import React, { useCallback, useMemo } from 'react';
import { NodeOutput } from 'features/Flow/nodes/Node/Node.types';
import PipelineStartForm from './PipelineStartForm';
import { NodeOutputFormData } from '../PipelineStartModal/PipelineStartModal.types';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useFindHandleEdge } from 'features/Flow/hooks/useFindHandleEdge';
import { createHandleId } from 'features/Flow/Flow.utils';
import { createOutputDefaultValues, mergeOutputUpdate } from '../PipelineStart.utils';
import * as Styled from 'components/Dialog/Dialog.styles';
import { Button, Grid2 } from '@mui/material';

interface PipelineStartEditFormProps {
  nodeId: string;
  outputs: NodeOutput[];
  outputToEdit: NodeOutput;
  onCancelEdit: () => void;
  onConfirmEdit: (output: NodeOutput) => void;
}

export const PipelineStartEditForm: React.FC<PipelineStartEditFormProps> = ({
  nodeId,
  outputs,
  outputToEdit,
  onCancelEdit,
  onConfirmEdit,
}) => {
  const form = useForm<NodeOutputFormData>({
    defaultValues: createOutputDefaultValues(outputToEdit),
    mode: 'onBlur',
  });

  const findEdge = useFindHandleEdge({ nodeId });

  const isConnected = !!findEdge(outputToEdit.id ?? createHandleId('output', outputToEdit.name));

  const outputsNotInEdit = useMemo(() => {
    return outputs.filter((n) => n.name !== outputToEdit.name);
  }, [outputs, outputToEdit.name]);

  const submitForm: SubmitHandler<NodeOutputFormData> = useCallback(
    (data) => {
      onConfirmEdit(mergeOutputUpdate(data, outputToEdit, isConnected));
    },
    [onConfirmEdit, isConnected, outputToEdit],
  );

  const handleConfirm = () => {
    void form.handleSubmit(submitForm)();
  };

  return (
    <FormProvider {...form}>
      <Styled.DialogTitle $hasBorder>Edit Input</Styled.DialogTitle>
      <Styled.DialogContent>
        <PipelineStartForm
          otherOutputs={outputsNotInEdit}
          control={form.control}
          isConnected={isConnected}
        />
      </Styled.DialogContent>
      <Styled.DialogActions $hasBorder>
        <Grid2 container>
          <Button variant="text" color="inherit" onClick={onCancelEdit}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={!form.formState.isValid}>
            Confirm
          </Button>
        </Grid2>
      </Styled.DialogActions>
    </FormProvider>
  );
};
