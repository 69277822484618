import axios, { AxiosError } from 'axios';
import useSWRMutation, { MutationFetcher, SWRMutationConfiguration } from 'swr/mutation';
import { AddPipelineTriggerResponse } from './useAddPipelineTrigger.types';

const MUTATION_KEY = 'addPipelineTrigger';

interface AddPipelineTriggerParams {
  pipelineId: string;
  name: string;
  notes: string;
}

type MutationConfig = SWRMutationConfiguration<
  AddPipelineTriggerResponse,
  AxiosError,
  typeof MUTATION_KEY
>;

const addPipelineTriggerFetcher: MutationFetcher<
  AddPipelineTriggerResponse,
  typeof MUTATION_KEY,
  AddPipelineTriggerParams
> = (_key, { arg }) =>
  axios.post(`/pipelines/${arg.pipelineId}/triggers`, {
    name: arg.name,
    notes: arg.notes,
  });

const useAddPipelineTrigger = (config?: MutationConfig) => {
  const { isMutating, trigger } = useSWRMutation(MUTATION_KEY, addPipelineTriggerFetcher, config);

  return {
    isAddingPipelineTrigger: isMutating,
    addPipelineTrigger: trigger,
  };
};

export default useAddPipelineTrigger;
