import axios from 'axios';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';

import { AxiosError, AxiosResponse } from 'axios';
import { SWRMutationConfiguration } from 'swr/mutation';

const MUTATION_KEY = 'deletePipelineTrigger';

interface DeletePipelineTriggerParams {
  pipelineId: string;
  triggerId: string;
}

type DeletePipelineTriggerResponse = AxiosResponse;

type MutationConfig = SWRMutationConfiguration<
  DeletePipelineTriggerResponse,
  AxiosError,
  typeof MUTATION_KEY
>;

const deletePipelineTriggerFetcher: MutationFetcher<
  DeletePipelineTriggerResponse,
  typeof MUTATION_KEY,
  DeletePipelineTriggerParams
> = (_key, { arg: { pipelineId, triggerId } }) =>
  axios.delete(`/pipelines/${pipelineId}/triggers/${triggerId}`);

const useDeletePipelineTrigger = (config?: MutationConfig) => {
  const { error, isMutating, trigger } = useSWRMutation(
    MUTATION_KEY,
    deletePipelineTriggerFetcher,
    config,
  );

  return {
    isDeletingTriggerError: error,
    isDeletingTrigger: isMutating,
    deletePipelineTrigger: trigger,
  };
};

export default useDeletePipelineTrigger;
