import { Pipeline } from './PipelineTable.types';
import { ColumnConfig as CustomColumnConfig } from 'components/Table/TableHead';

export enum ColumnId {
  TITLE = 'name',
  AUTHOR = 'last_updated_by_username',
  LAST_EDIT = 'updated_at',
  LAST_EXECUTION = 'most_recently_started_execution_date',
  INDICATORS = 'indicators',
  TOTAL = 'execution_count',
  REVIEW = 'pending_review_count',
  FAILED = 'failed_count',
  ACTIONS = 'actions',
}

interface ColumnConfig extends Omit<CustomColumnConfig, 'id'> {
  orderProperty?: keyof Pipeline;
}

export const COLUMN_CONFIG: Partial<Record<ColumnId, ColumnConfig>> = {
  [ColumnId.TITLE]: {
    label: 'Pipeline Title',
    orderProperty: 'name',
  },
  [ColumnId.AUTHOR]: {
    label: 'Author',
  },
  [ColumnId.LAST_EDIT]: {
    label: 'Last Edited',
    orderProperty: 'updatedAt',
  },
  [ColumnId.LAST_EXECUTION]: {
    label: 'Last Execution',
    orderProperty: 'mostRecentlyStartedExecutionDate',
  },
  [ColumnId.INDICATORS]: {
    label: '',
  },
  [ColumnId.TOTAL]: {
    label: 'Total',
    numeric: true,
    orderProperty: 'executionCount',
  },
  [ColumnId.REVIEW]: {
    label: 'Review',
    numeric: true,
    orderProperty: 'pendingReviewCount',
  },
  [ColumnId.FAILED]: {
    label: 'Failed',
    numeric: true,
    orderProperty: 'failedCount',
  },
  [ColumnId.ACTIONS]: {
    label: '',
  },
};

export const COLUMN_CONFIG_ARRAY = Object.entries(COLUMN_CONFIG).map(([id, config]) => ({
  id: id as ColumnId,
  ...config,
}));
