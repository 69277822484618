import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { AddCircleOutline } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Button, Paper, Typography } from '@mui/material';
import { SectionDivider, SidebarSection } from 'components/Sidebar/Sidebar.styles';
import { useNodeError } from 'features/Flow/hooks/useNodeError';
import { DynamicCheckpointNode } from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/DynamicCheckpoint.types';
import { useTableSchemaInput } from 'features/Flow/nodes/Checkpoint/DynamicCheckpoint/tableSchemaInput';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { TableSchemaFormRow } from './TableSchemaFormRow';

interface TableSchemaFormProps {
  node: DynamicCheckpointNode;
}

export function TableSchemaForm(props: TableSchemaFormProps) {
  const { node } = props;
  const { setNodeError } = useNodeError(node.id);

  const { tableSchemaInput, updateTableSchemaInput } = useTableSchemaInput({ node });
  const columnItems = tableSchemaInput?.value ?? [];

  const keyCountMap = columnItems.reduce<Record<string, number>>((keys, column) => {
    if (keys[column.id]) {
      keys[column.id]++;
    } else {
      keys[column.id] = 1;
    }

    return keys;
  }, {});
  const hasDuplicatedKeyField = Object.values(keyCountMap).some((count) => count > 1);

  useEffect(() => {
    if (hasDuplicatedKeyField) {
      setNodeError('Multiple inputs have the same Key. Assign different key names to resolve.');
    } else {
      setNodeError(undefined);
    }
  }, [hasDuplicatedKeyField, node.id, setNodeError]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id === over?.id) {
      return;
    }

    const oldIndex = columnItems.findIndex((column) => column.uniqueKey === active.id);
    const newIndex = columnItems.findIndex((column) => column.uniqueKey === over?.id);
    const nextValue = arrayMove(columnItems, oldIndex, newIndex);

    updateTableSchemaInput(nextValue);
  }

  return (
    <SidebarSection>
      <Typography
        component="p"
        variant="titleMedium"
        sx={{
          padding: 2,
        }}
      >
        Columns
      </Typography>

      <SectionDivider $addMargin={false} />

      {columnItems.length === 0 && <NoColumnsMessage />}

      {hasDuplicatedKeyField && (
        <Box
          sx={{
            padding: 2,
            paddingBottom: 0,
          }}
        >
          <Alert severity="error">
            <AlertTitle>Multiple inputs have the same Key</AlertTitle>
            Assign different key names to resolve.
          </Alert>
        </Box>
      )}

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={columnItems.map((column) => column.uniqueKey)}
          strategy={verticalListSortingStrategy}
        >
          <div data-testid="ColumnList">
            {columnItems.map((column) => {
              const hasDuplicatedKeyField = keyCountMap[column.id] > 1;

              return (
                <TableSchemaFormRow
                  key={column.uniqueKey}
                  column={column}
                  hasDuplicatedKey={hasDuplicatedKeyField}
                  node={node}
                />
              );
            })}
          </div>
        </SortableContext>
      </DndContext>

      <Box
        sx={{
          padding: 2,
        }}
      >
        <Button
          startIcon={<AddCircleOutline />}
          onClick={() => {
            const uniqueKey = nanoid(4);

            columnItems.push({
              uniqueKey,
              id: '',
              label: '',
              valueSchema: {
                type: 'text',
              },
            });
            const nextValue = columnItems.slice();

            updateTableSchemaInput(nextValue);
          }}
        >
          Add Column
        </Button>
      </Box>

      <SectionDivider $addMargin={false} />
    </SidebarSection>
  );
}

function NoColumnsMessage() {
  return (
    <>
      <Typography
        variant="labelSmall"
        align="center"
        component={Paper}
        elevation={2}
        sx={{
          padding: 2,
          margin: 2,
          borderRadius: (theme) => `${theme.shape.borderRadius * 2}px`, // 8px,
        }}
      >
        No column properties configured.
        <br />
        <br />
        The order you set here will determine the order of the columns shown at the checkpoint.
        <br />
        If you prefer not to add column properties, default columns will appear in no particular
        order.
      </Typography>

      <SectionDivider $addMargin={false} />
    </>
  );
}
