import { Stack, Typography } from '@mui/material';
import { PipelineExecution } from 'api/services/usePipelineExecution/usePipelineExecution.types';
import Loader from 'components/Loader/Loader';
import { ExecutionStatusReadable } from 'config/status';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  isPipelineExecutionFinished,
  isPipelineExecutionReview,
  isPipelineExecutionScheduled,
  isPipelineExecutionStarted,
} from 'utils/execution';
import * as Styled from './ViewExecutionPage.styles';
import ExecutionCheckpoint from './components/ExecutionCheckpoint/ExecutionCheckpoint';
import ExecutionCompleted from './components/ExecutionCompleted/ExecutionCompleted';
import ExecutionProgress from './components/ExecutionProgress/ExecutionProgress';
import { useViewExecutionRequests } from './useViewExecutionRequests';

const createReviewRenderKey = (execution: PipelineExecution) => {
  return `${execution.id}:${execution.completedJobs}:${execution.status}`;
};

const ViewExecutionPage = () => {
  const [view, setView] = useState<'all' | 'output'>('output');
  const { pipelineExecutionId = '' } = useParams();

  const { pipelineExecutionRequest, pipelineOutputRequest, pipelineJobRequest } =
    useViewExecutionRequests(pipelineExecutionId);

  const { queryPipelineJobs } = pipelineJobRequest;
  const { pipelineExecution, isPipelineExecutionLoading } = pipelineExecutionRequest;
  const { status } = pipelineExecution;
  const statusLabel = ExecutionStatusReadable[status];
  const isFinished = isPipelineExecutionFinished(status);
  const inReview = isPipelineExecutionReview(status);

  useEffect(() => {
    setView(isFinished ? 'all' : 'output');
  }, [pipelineExecutionId, setView, isFinished]);

  useEffect(() => {
    if (isFinished) {
      void queryPipelineJobs();
    }
  }, [isFinished, queryPipelineJobs]);

  return (
    <Stack
      sx={{
        gap: 3,
      }}
    >
      {isFinished && (
        <ExecutionCompleted
          view={view}
          pipelineExecution={pipelineExecution}
          pipelineJobs={pipelineJobRequest}
          pipelineExecutionOutputs={pipelineOutputRequest}
          onViewChange={setView}
        />
      )}
      {inReview &&
        (pipelineExecution.hasPendingCheckpoints ? (
          <ExecutionCheckpoint
            key={createReviewRenderKey(pipelineExecution)}
            pipelineExecutionId={pipelineExecutionId}
            totalCheckpoints={pipelineExecution.totalCheckpoints}
            completedCheckpoints={pipelineExecution.completedCheckpoints}
          />
        ) : (
          <ExecutionProgress
            key={pipelineExecution.updatedAt.toJSON()}
            pipelineExecutionId={pipelineExecutionId}
          />
        ))}

      {isPipelineExecutionStarted(status) && (
        <ExecutionProgress
          key={pipelineExecution.updatedAt.toJSON()}
          pipelineExecutionId={pipelineExecutionId}
        />
      )}

      {isPipelineExecutionScheduled(status) && (
        <Styled.StatusPaper>
          <Stack
            sx={{
              alignItems: 'center',
            }}
          >
            <Loader loading={isPipelineExecutionLoading}>
              <Typography variant="headlineLarge">{`Pipeline status: ${statusLabel}`}</Typography>
            </Loader>
          </Stack>
        </Styled.StatusPaper>
      )}
    </Stack>
  );
};

export default ViewExecutionPage;
