import { Paper, styled } from '@mui/material';
import { ignoreTransientProps } from 'utils/styles';

const RoundedPaper = styled(Paper, {
  // override `shouldForwardProp` to allow `sx` prop.
  shouldForwardProp: ignoreTransientProps,
})`
  &.MuiPaper-rounded {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
  }
`;

export default RoundedPaper;
