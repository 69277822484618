import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Content,
  JSONEditorPropsOptional,
  Mode,
  OnChangeStatus,
  ValidationError,
  ValidationSeverity,
  JsonEditor as VanillaJsonEditor,
  createJSONEditor,
  isContent,
} from 'vanilla-jsoneditor';
import { Container } from './JsonEditor.styles';

interface JsonEditorProps {
  content: unknown;
  readOnly?: boolean;
  type?: 'object' | 'array' | 'all';
  style?: React.CSSProperties;
  helperText?: string;
  onChange?: (
    updatedContent: Content,
    previousContent: Content,
    { contentErrors, patchResult }: OnChangeStatus,
  ) => void;
}

export default function JsonEditor(props: JsonEditorProps) {
  const { readOnly = false, type = 'all', helperText, onChange } = props;

  const refContainer = useRef<HTMLElement>(null);
  const refEditor = useRef<VanillaJsonEditor | null>(null);

  const customValidator = useCallback(
    (json: unknown) => {
      const errors: ValidationError[] = helperText
        ? [
            {
              path: [],
              message: helperText,
              severity: ValidationSeverity.warning,
            },
          ]
        : [];

      if (type === 'object' && Array.isArray(json)) {
        errors.push({
          path: [],
          message: 'Arrays are not allowed at the top level.',
          severity: ValidationSeverity.error,
        });
      }

      if (type === 'array' && !Array.isArray(json)) {
        errors.push({
          path: [],
          message: 'Top level must be an array.',
          severity: ValidationSeverity.error,
        });
      }

      return errors;
    },
    [type, helperText],
  );

  const editorProps: JSONEditorPropsOptional = useMemo(() => {
    const content = isContent(props.content) ? props.content : { json: props.content };

    return {
      content,
      mainMenuBar: false,
      mode: Mode.text,
      readOnly,
      statusBar: false,
      validator: customValidator,
      onChange,
    } satisfies JSONEditorPropsOptional;
  }, [customValidator, onChange, props.content, readOnly]);

  useEffect(() => {
    if (!refContainer.current) return;

    refEditor.current = createJSONEditor({
      target: refContainer.current,
      props: {},
    });

    return () => {
      if (refEditor.current) {
        void refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (refEditor.current) {
      void refEditor.current.updateProps(editorProps);
    }
  }, [editorProps]);

  return (
    <Container
      key={String(editorProps.content)}
      ref={refContainer}
      data-testid="JsonEditor"
      style={props.style}
    />
  );
}
