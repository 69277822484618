import { useRequest } from 'api/client';
import {
  ListPipelineVersionsResponse,
  PipelineVersionsPagination,
} from 'api/services/usePipelineVersions/usePipelineVersions.types';

const defaultPagination: PipelineVersionsPagination = {
  pageSize: 2,
  pageNumber: 0,
  sortBy: 'version',
  orderBy: 'desc',
};

const usePipelineVersions = (
  pipelineId: string | undefined,
  pagination?: Partial<PipelineVersionsPagination>,
) => {
  const mergedPagination = { ...defaultPagination, ...pagination };
  const queryParams = new URLSearchParams({
    pageSize: mergedPagination.pageSize.toString(),
    pageNumber: mergedPagination.pageNumber.toString(),
    sortBy: mergedPagination.sortBy,
    orderBy: mergedPagination.orderBy.toUpperCase(),
  });
  const {
    isLoading: isFetchingVersions,
    isValidating,
    data,
    error,
    mutate,
  } = useRequest<ListPipelineVersionsResponse>({
    url: pipelineId ? `/pipelines/${pipelineId}/versions?${queryParams.toString()}` : undefined,
    method: 'GET',
  });

  return {
    arePipelineVersionsLoading: isFetchingVersions || isValidating,
    pipelineVersionsData: data,
    pipelineVersionsError: error,
    getPipelineVersions: mutate,
  };
};

export default usePipelineVersions;
