import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo } from 'react';
import { useStore, useUpdateNodeInternals } from 'reactflow';
import { isHandleConnection, NodeType } from 'features/Flow/Flow.types';
import { getNodeOutput, useSelectNodeOnClick } from 'features/Flow/Flow.utils';
import { NodeOutput, PathwayNode, PathwayNodeProps } from '../../Node/Node.types';
import { NodeHeader } from '../../Node/components/NodeHeader';
import NodeInputHandle from '../../Node/NodeInputHandle';
import NodeOutputHandle from '../../Node/NodeOutputHandle';
import * as Styled from '../../Node/Node.styles';
import { useEditorContext } from 'features/Flow/hooks/useEditorContext';
import { useGetNodeExecution } from 'features/Flow/hooks/useGetNodeExecution';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { FlowState } from 'types/reactflow';
import { useObjectExtractorModal } from './ObjectExtractorModal/useObjectExtractorModal';
import ObjectExtractorModal from './ObjectExtractorModal/ObjectExtractorModal';
import { canExtractorCustomHandleConnect, getExtractionInputValue } from './ObjectExtractor.utils';
import CustomHandle from 'features/Flow/components/NeuronHandle/CustomHandle';
import { createCustomHandle, useGetTargetHandle } from 'features/Flow/Handles/handles';
import { HandleKey } from 'features/Flow/Handles/handle.store';
import { useDeferredConnectionState } from 'features/Flow/hooks/useDeferredConnectionState';
import ConfigureSchemaButton from 'features/Flow/components/ConfigureSchemaButton/ConfigureSchemaButton';

// This input is hidden and only updated as part as outputs are updated.
export const EXTRACT_INPUT_NAME = 'extractions';

const setInitialInputConfig = function (data: PathwayNode['data']): PathwayNode['data'] {
  return {
    ...data,
    inputs: data.inputs.map((input) => {
      if (input.name === EXTRACT_INPUT_NAME) {
        return {
          ...input,
          config: {
            forceHide: true,
          },
        };
      }
      return input;
    }),
  };
};

const selector = ({ mode }: FlowState) => ({
  mode,
});

const ObjectExtractor: React.FC<PathwayNodeProps> = (props) => {
  const {
    id: nodeId,
    data: { metadata, inputs, outputs },
  } = props;
  const { execution } = useGetNodeExecution({ id: nodeId });
  const { mode } = useStore(selector);
  const { addConnectionHandler, removeConnectionHandler } = useEditorContext();
  const { setDeferredConnectionState } = useDeferredConnectionState({
    outputs,
  });
  const handleSelectNodeOnClick = useSelectNodeOnClick(nodeId);
  const updateNodeInternals = useUpdateNodeInternals();
  const updateNodeData = useUpdateNodeData();
  const objectExtractorModalState = useObjectExtractorModal({ nodeId });

  const addOutput = useCallback(
    (output: NodeOutput, originalName: string, nodeId: string) => {
      updateNodeData<PathwayNode['data']>(nodeId, function (data) {
        const newOutputs = [...data.outputs, output];
        const updatedInputs = data.inputs.map((input) => {
          const { name, value } = input;
          if (name === EXTRACT_INPUT_NAME) {
            const extractionValue = value ? (value as object) : {};
            return {
              ...input,
              value: {
                ...extractionValue,
                [output.name]: originalName,
              },
            };
          }

          return input;
        });

        return { ...data, inputs: updatedInputs, outputs: newOutputs };
      });
    },
    [updateNodeData],
  );

  const addOutputHandle = useMemo(
    () => createCustomHandle(nodeId, 'output', canExtractorCustomHandleConnect),
    [nodeId],
  );
  const getTargetHandle = useGetTargetHandle();

  useEffect(() => {
    const connectionHandlerKey: HandleKey = {
      id: addOutputHandle.id,
      nodeId,
    };
    addConnectionHandler?.(connectionHandlerKey, (conn, actionCallback) => {
      if (!isHandleConnection(conn)) return conn;

      const handle = getTargetHandle(conn);
      const newOutput = getNodeOutput({
        config: { required: true },
        dataSchema: handle.schema,
        name: `output_${nanoid(4)}`,
        title: handle.title,
        description: handle.description,
      });
      addOutput(newOutput, handle.name, nodeId);

      setDeferredConnectionState({
        action: actionCallback,
        connection: {
          ...conn,
          sourceHandle: newOutput.id,
        },
        outputName: newOutput.name,
      });

      return 'defer';
    });
    return () => {
      removeConnectionHandler?.(connectionHandlerKey);
    };
  }, [
    addConnectionHandler,
    addOutput,
    addOutputHandle.id,
    getTargetHandle,
    nodeId,
    removeConnectionHandler,
    setDeferredConnectionState,
  ]);

  // Sets force hide config for the extraction input
  // TODO: This needs to be refactored once we have a better way to control which fields should be fully hidden from user view on the right hand sidebar.
  // Related to custom node extensibility refactors
  useEffect(() => {
    updateNodeData<PathwayNode['data']>(nodeId, setInitialInputConfig);
  }, [nodeId, updateNodeData]);

  useEffect(() => {
    updateNodeInternals(nodeId);
    // 'outputs' is a dependency here because we need to update the node internals when it change.
    outputs;
  }, [nodeId, outputs, updateNodeInternals]);

  return (
    <Styled.NodeContainer
      data-node-id={nodeId}
      $isSelected={props.selected}
      $nodeType={NodeType.FUNCTION}
    >
      <NodeHeader
        metadata={metadata}
        execution={execution}
        icon={
          mode === 'editor' ? (
            <ConfigureSchemaButton onClick={objectExtractorModalState.openModal} />
          ) : undefined
        }
      />
      <Styled.NodeDataRow className="nodrag" onClick={handleSelectNodeOnClick}>
        <Styled.NodeDataColumn>
          {inputs
            .filter((input) => input.name !== EXTRACT_INPUT_NAME)
            .map((input) => {
              return <NodeInputHandle key={input.name} input={input} nodeId={nodeId} />;
            })}
        </Styled.NodeDataColumn>
        <Styled.NodeDataColumn>
          {outputs.map((output) => (
            <NodeOutputHandle key={output.name} output={output} nodeId={nodeId} />
          ))}
          {mode === 'editor' && <CustomHandle title="Add Outputs" handle={addOutputHandle} />}
        </Styled.NodeDataColumn>
      </Styled.NodeDataRow>
      {mode === 'editor' && objectExtractorModalState.open && (
        <ObjectExtractorModal
          outputs={outputs}
          extractions={getExtractionInputValue(inputs)}
          {...objectExtractorModalState}
        />
      )}
    </Styled.NodeContainer>
  );
};

export default ObjectExtractor;
