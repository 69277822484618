import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
} from '@mui/material';
import RHFTextField from 'components/ui/rhf-components/RHFTextField/RHFTextField';
import { requiredValidation } from 'components/ui/rhf-components/utils/validationRules';
import useToast from 'contexts/toast/useToast';
import { Form, useForm } from 'react-hook-form';

interface CreateTriggerValues {
  name: string;
  notes: string;
}

export interface CreateTriggerDialogProps {
  DialogProps?: DialogProps;
  closeDialog: () => void;
  onCreate: (values: CreateTriggerValues) => Promise<void>;
}

export default function CreateTriggerDialog(props: CreateTriggerDialogProps) {
  const toast = useToast();
  const form = useForm<CreateTriggerValues>();
  const { isSubmitting } = form.formState;
  const { closeDialog } = props;

  const submitForm = form.handleSubmit(async (values) => {
    try {
      await props.onCreate(values);
      closeDialog();
    } catch {
      toast.error({
        message: `Failed to create trigger "${values.name}".`,
      });
    }
  });

  return (
    <Dialog
      open
      fullWidth
      slotProps={{
        paper: {
          sx: {
            maxWidth: 480,
          },
        },
      }}
      {...props.DialogProps}
      onClose={isSubmitting ? undefined : closeDialog}
    >
      <DialogTitle>Create new trigger</DialogTitle>
      <DialogContent>
        <Form control={form.control}>
          <Stack spacing={3.5}>
            <RHFTextField
              control={form.control}
              name="name"
              label="Name"
              placeholder="Enter a name..."
              fullWidth
              required
              rules={requiredValidation}
            />

            <RHFTextField
              control={form.control}
              name="notes"
              label="Notes"
              placeholder="Add context or information about trigger..."
              fullWidth
              multiline
              rows={4}
            />
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions
        sx={{
          paddingX: 3,
          paddingY: 2,
        }}
      >
        <Button
          variant="text"
          disabled={isSubmitting}
          sx={{ color: 'text.primary' }}
          onClick={closeDialog}
        >
          Cancel
        </Button>
        <Button variant="contained" loading={isSubmitting} onClick={submitForm}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
