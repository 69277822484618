import { Stack, Typography, TypographyProps } from '@mui/material';
import RoundedPaper from 'components/RoundedPaper/RoundedPaper';
import { PropsWithChildren } from 'react';

export interface SectionBaseProps {
  loading: boolean;
}

interface SectionOwnProps {
  title: TypographyProps['children'];
}

type SectionProps = PropsWithChildren<SectionBaseProps & SectionOwnProps>;

export default function Section(props: SectionProps) {
  return (
    <Stack gap={2}>
      <Typography
        component="h2"
        variant="titleMedium"
        color={props.loading ? 'text.disabled' : 'text.primary'}
      >
        {props.title}
      </Typography>

      <RoundedPaper
        sx={{
          padding: 3,
        }}
      >
        {props.children}
      </RoundedPaper>
    </Stack>
  );
}
