import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { TableContainer as MuiTableContainer, TableRow, Paper } from '@mui/material';
import styled, { css } from 'styled-components';

export const TableToolbar = styled(Paper).attrs({
  square: true,
  elevation: 1,
})(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing(2)};
    border-top-left-radius: ${theme.spacing(1)};
    border-top-right-radius: ${theme.spacing(1)};
  `,
);

export const TableContainer = styled(MuiTableContainer)(
  ({ theme }) => css`
    border-radius: ${theme.spacing(1)};
    background-color: ${theme.palette.surface.level1};

    ${TableToolbar} + & {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  `,
) as typeof MuiTableContainer;

export const TableHeadRow = styled(TableRow)(
  ({ theme }) => css`
    .MuiTableCell-head {
      border: 0;
      background: ${theme.palette.surface.level2};

      &:hover {
        .MuiTableSortLabel-root:not(.Mui-active) {
          & .MuiTableSortLabel-icon {
            opacity: 0.5;
          }
        }
      }
    }
  `,
);

export const TableSortLabelIcon = styled(ArrowDownwardIcon)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(-1)};
  translate: 100%;
`;

export const TableBodyRow = styled(TableRow)(
  ({ theme }) => css`
    &.MuiTableRow-hover {
      cursor: pointer;
    }

    &:last-child td,
    &:last-child th {
      border: 0;
    }

    .MuiTableCell-body {
      border-color: ${theme.palette.divider};
      color: ${theme.palette.text.secondary};
    }
  `,
);

export const TableFooterRow = styled(TableRow)(
  ({ theme }) => css`
    .MuiTableCell-footer {
      border-top: 1px solid ${theme.palette.divider};
      border-bottom: 0;
    }
  `,
);

export const TableMessage = styled(Paper).attrs({
  square: true,
  elevation: 1,
})(
  ({ theme }) => css`
    display: grid;
    place-items: center;
    padding: ${theme.spacing(5, 3)};
    border-bottom-left-radius: ${theme.spacing(1)};
    border-bottom-right-radius: ${theme.spacing(1)};
  `,
);
