import { DataMenuItem } from 'utils/dataSchema';

export const DATA_MENU_ITEMS: DataMenuItem[] = [
  { schema: { type: 'integer' }, title: 'Integer' },
  { schema: { type: 'number' }, title: 'Number' },
  { schema: { type: 'text' }, title: 'Text' },
  { schema: { type: 'boolean' }, title: 'Boolean' },
  { schema: { type: 'object' }, title: 'Object' },
  {
    schema: {
      type: 'array',
      items: {
        type: null,
      },
    },
    title: 'Array',
  },
];

export const ARRAY_TYPE_MENU_ITEMS: DataMenuItem[] = [
  {
    schema: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    title: 'Integer',
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'number',
      },
    },
    title: 'Number',
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'text',
      },
    },
    title: 'Text',
  },
  {
    schema: {
      type: 'array',
      items: {
        type: 'object',
      },
    },
    title: 'Object',
  },
];

export const OBJECT_DATA_MENU_ITEMS: DataMenuItem[] = [
  { schema: { type: 'object' }, title: 'Object' },
  {
    schema: {
      type: 'array',
      items: {
        type: 'object',
      },
    },
    title: 'Array of objects',
  },
];
