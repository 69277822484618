import { FlowState } from 'types/reactflow';
import { useStore } from 'reactflow';
import { shallow } from 'zustand/shallow';
import { ProviderType } from '@monksflow/plugin-providers/web';
import { CredentialProviderData } from 'api/services/useWorkspaceConnectedCredentials/useWorkspaceConnectedCredentials.types';

const selector = (state: FlowState) => state.connectedCredentialProviders;

export default function useGetConnectedProvider(
  providerType?: ProviderType,
): CredentialProviderData | undefined {
  const connectedProviders = useStore(selector, shallow);

  if (!providerType || !connectedProviders) {
    return undefined;
  }

  return connectedProviders.find(
    (connectedProvider) => (connectedProvider.providerType as string) === providerType,
  );
}
